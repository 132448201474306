import axios from "axios";
import React, { useEffect, useState } from "react";
import { api } from "../utils/Api";
import { Typography, Flex, Tag, Button, Tooltip } from "antd";
import Layout from "../layout/Layout";
import {
	CustomSearch,
	CustomSpinner,
	CustomTable,
} from "../utils/CustomComponents";
import { EditFilled, PlusCircleFilled } from "@ant-design/icons";
import { statusAllOptions } from "../../constants/Inventory";
const { Text } = Typography;

const InventoryList = ({ history }) => {
	const [loading, setLoading] = useState(true);
	const [status, setStatus] = useState("All");
	const [openOHWO, setOpenOHWO] = useState(false);
	const [openQOH, setOpenQOH] = useState(false);
	const [inventoryList, setInventoryList] = useState([]);

	function handleOnHeaderCell() {
		return {
			onMouseEnter: () => {
				setOpenOHWO(() => true);
			},
			onMouseLeave: () => {
				setOpenOHWO(() => false);
			},
		};
	}

	function handleOnHeaderCell2() {
		return {
			onMouseEnter: () => {
				setOpenQOH(() => true);
			},
			onMouseLeave: () => {
				setOpenQOH(() => false);
			},
		};
	}

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "",
			dataIndex: "edit",
			render: (_, record) => {
				return localStorage.getItem("email") === "eliud@gmail.com" ||
					localStorage.getItem("email") === "ec@57concrete.com" ||
					localStorage.getItem("email") === "bp@57concrete.com" ||
					localStorage.getItem("email") === "bpa@57concrete.com" ||
					localStorage.getItem("email") === "jp@57concrete.com" ||
					localStorage.getItem("email") === "fc@57concrete.com" ||
					localStorage.getItem("email") === "ji@57concrete.com" ||
					localStorage.getItem("email") === "mgr@57concrete.com" ||
					localStorage.getItem("role") === "AP" ||
					localStorage.getItem("manager") === true ||
					localStorage.getItem("director") === true ? (
					<Button
						type="primary"
						icon={<EditFilled twoToneColor={"white"} />}
						onClick={(e) => history.push(`/inventory/${record.id}`)}
					/>
				) : (
					""
				);
			},
		},
		{
			title: "ID #",
			dataIndex: "number",
			key: "number",
			render: (value) => {
				return <Text strong>{value}</Text>;
			},
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (value) => {
				return <Text strong>{value}</Text>;
			},
		},
		{
			title: "Part #",
			dataIndex: "part",
			key: "part",
			render: (value) => {
				return <Text strong>{value}</Text>;
			},
		},
		{
			title: "Manufacturer #",
			dataIndex: "manufacturerNumber",
			key: "manufacturerNumber",
			render: (value) => {
				return <Text strong>{value}</Text>;
			},
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (value) => {
				switch (value) {
					case "Active":
						return <Tag color="green">{value}</Tag>;
					case "In Storage":
						return <Tag color="blue">{value}</Tag>;
					case "Disposed":
						return <Tag color="yellow">{value}</Tag>;
					case "Delivery":
						return <Tag color="cyan">{value}</Tag>;
					case "Sold Out":
						return <Tag color="red">{value}</Tag>;
					default:
						return <Tag color="default">None</Tag>;
				}
			},
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			render: (value) => {
				switch (value) {
					case "Initial":
						return <Tag color="blue">{value}</Tag>;
					case "In Stock":
						return <Tag color="green">{value}</Tag>;
					case "Out Of Stock":
						return <Tag color="red">{value}</Tag>;
					default:
						return <Tag color="default">None</Tag>;
				}
			},
		},
		{
			title: "Container",
			dataIndex: "container",
			key: "container",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: (
				<Tooltip
					open={openOHWO}
					title="Inventory in stock + assigned in work orders"
				>
					OHWO
				</Tooltip>
			),
			onHeaderCell: handleOnHeaderCell,
			dataIndex: "ohwo",
			key: "ohwo",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: (
				<Tooltip
					open={openQOH}
					title="Total quantity in stock + quantity in container"
				>
					QOH
				</Tooltip>
			),
			onHeaderCell: handleOnHeaderCell2,
			dataIndex: "qoh",
			key: "qoh",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Cost",
			dataIndex: "cost",
			key: "cost",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Sale Price",
			dataIndex: "salePrice",
			key: "salePrice",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Average Price",
			dataIndex: "averagePrice",
			key: "averagePrice",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Min",
			dataIndex: "min",
			key: "min",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Max",
			dataIndex: "max",
			key: "max",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
	];

	useEffect(() => {
		getInventoryList("");
	}, []);

	const getInventoryList = async (term) => {
		setLoading(true);

		const url = api + `/inventory/search?status=${status}&term=${term}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];

			data.map((item, index) => {
				let ohwo = 0;
				ohwo +=
					(item.quantityAssigned ? item.quantityAssigned : 0) +
					(item.quantity ? item.quantity : 0);

				let cost = 0;
				if (item.costPart.length > 0) {
					cost = item.costPart[0].cost;
				}

				let averagePrice = 0;
				if (item.costPart.length > 0) {
					let sum = 0;
					item.costPart.map((costPart) => {
						sum += costPart.cost;
					});

					if (sum !== 0) {
						averagePrice = sum / item.costPart.length;
					}
				}

				list.push({
					key: index,
					id: item._id,
					number: item.number,
					name: item.name,
					part: item.partNumber,
					manufacturerNumber: item.manufacturerNumber
						? item.manufacturerNumber
						: "-",
					status: item.status,
					type: item.type,
					container: item.container ? item.container : "-",
					ohwo: ohwo,
					qoh: item.quantity ? item.quantity : 0,
					cost: `$${cost.toFixed(2)}`,
					salePrice: `$${item.salePrice.toFixed(2)}`,
					min: item.min ? item.min : 0,
					max: item.max ? item.max : 0,
					averagePrice: `$${averagePrice.toFixed(2)}`,
				});
			});
			setInventoryList(list);
			setLoading(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const onSearch = (value, _e, info) => {
		if (info?.source === "input") {
			getInventoryList(value);
		}
	};

	const onChangePicker = (value) => {
		setStatus(value);
	};

	const RenderSearch = () => {
		return (
			<CustomSearch
				onSearch={onSearch}
				labelPicker={"status"}
				optionsPicker={statusAllOptions}
				onChangePicker={onChangePicker}
				defaultValuePicker={status}
				pickerWidth={"200px"}
				loading={loading}
			/>
		);
	};

	const addNewInventory = () => {
		history.push("/newinventory");
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				{(localStorage.getItem("email") === "eliud@gmail.com" ||
					localStorage.getItem("email") === "ec@57concrete.com" ||
					localStorage.getItem("email") === "bp@57concrete.com" ||
					localStorage.getItem("email") === "bpa@57concrete.com" ||
					localStorage.getItem("email") === "jp@57concrete.com" ||
					localStorage.getItem("email") === "fc@57concrete.com" ||
					localStorage.getItem("email") === "ji@57concrete.com" ||
					localStorage.getItem("email") === "mgr@57concrete.com" ||
					localStorage.getItem("role") === "AP" ||
					localStorage.getItem("manager") === true ||
					localStorage.getItem("director") === true) && (
					<Button
						onClick={addNewInventory}
						type="primary"
						style={{
							width: 150,
						}}
						icon={<PlusCircleFilled />}
					>
						New Inventory
					</Button>
				)}
				<CustomTable columns={columns} data={inventoryList} />
			</Flex>
		);
	};

	return (
		<Layout title="Inventory List" description="Inventory">
			<Flex vertical gap={"large"}>
				<RenderSearch />
				{loading ? <CustomSpinner /> : <RenderTable />}
			</Flex>
		</Layout>
	);
};

export default InventoryList;

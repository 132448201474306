import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { api } from "../utils/Api";
import {
	Typography,
	Flex,
	notification,
	Statistic,
	Button,
	Checkbox,
} from "antd";
import Layout from "../layout/Layout";
import {
	CustomSearch,
	CustomSpinner,
	CustomTable,
} from "../utils/CustomComponents";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";

const { Text } = Typography;

const columnsCSV = [
	{
		label: "Job ID",
		key: "number",
	},
	{
		label: "Date",
		key: "date",
	},
	{
		label: "Customer",
		key: "customer",
	},
	{
		label: "Seller",
		key: "seller",
	},
	{
		label: "Measurement",
		key: "measurement",
	},
	{
		label: "Yards",
		key: "measurementYards",
	},
	{
		label: "Ready For Truck",
		key: "readyForTruck",
	},
	{
		label: "Comments",
		key: "readyForTruckComments",
	},
	{
		label: "Light Tower Positions",
		key: "lightTowerPositions",
	},
	{
		label: "Number Tag",
		key: "lightTowerPositionsNumberTag",
	},
	{
		label: "Brand",
		key: "lightTowerPositionsBrand",
	},
	{
		label: "Pour",
		key: "pour",
	},
];

const JobCheckInReport = () => {
	const [loading, setLoading] = useState(false);
	const [jobList, setJobList] = useState([]);
	const [apiNotification, contextHolder] = notification.useNotification();
	const [plants, setPlants] = useState([]);
	const [plant, setPlant] = useState("All");
	const [startDate, setStartDate] = useState(dayjs());
	const [endDate, setEndDate] = useState(dayjs());

	const refDownload = useRef();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "Job ID",
			dataIndex: "number",
			key: "number",
			render: (value) => {
				return <Text strong>{value}</Text>;
			},
		},
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Customer",
			dataIndex: "customer",
			key: "customer",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Seller",
			dataIndex: "seller",
			key: "seller",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Measurement",
			dataIndex: "measurement",
			key: "measurement",
			render: (value) => {
				return <Checkbox checked={value} />;
			},
		},
		{
			title: "Yards",
			dataIndex: "measurementYards",
			key: "measurementYards",
			render: (value) => {
				return (
					<Statistic title="" value={value} valueStyle={{ fontSize: 14 }} />
				);
			},
		},
		{
			title: "Ready For Truck",
			dataIndex: "readyForTruck",
			key: "readyForTruck",
			render: (value) => {
				return <Checkbox checked={value} />;
			},
		},
		{
			title: "Comments",
			dataIndex: "readyForTruckComments",
			key: "readyForTruckComments",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Light Tower Positions",
			dataIndex: "lightTowerPositions",
			key: "lightTowerPositions",
			render: (value) => {
				return <Checkbox checked={value} />;
			},
		},
		{
			title: "Number Tag",
			dataIndex: "lightTowerPositionsNumberTag",
			key: "lightTowerPositionsNumberTag",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Brand",
			dataIndex: "lightTowerPositionsBrand",
			key: "lightTowerPositionsBrand",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Pour",
			dataIndex: "pour",
			key: "pour",
			render: (value) => {
				return <Checkbox checked={value} />;
			},
		},
	];

	useEffect(() => {
		getPlants();
	}, []);

	const getJobs = async (startDate, endDate, plant) => {
		setLoading(true);

		const start = `${startDate.year()}-${
			startDate.month() + 1
		}-${startDate.date()}`;
		const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`;

		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const url =
			api +
			`/job/list/checkin?startDate=${start}&endDate=${end}&plant=${plant}`;

		try {
			const { data } = await axios.get(url);
			let list = [];

			if (data.success) {
				data.payload.map((item, index) => {
					list.push({
						key: index,
						id: item._id,
						seller: item.seller.name,
						customer: item.customer.name,
						number: item.number,
						date: Intl.DateTimeFormat("en-US").format(
							new Date(item.scheduledDate)
						),
						measurement: item.measurement || false,
						measurementYards: item.measurementYards || 0,
						readyForTruck: item.readyForTruck || false,
						readyForTruckComments: item.readyForTruckComments,
						lightTowerPositions: item.lightTowerPositions || false,
						lightTowerPositionsNumberTag: item.lightTowerPositionsNumberTag,
						lightTowerPositionsBrand: item.lightTowerPositionsBrand,
						pour: item.pour || false,
					});
				});
				setJobList(list);
			} else {
				openNotification("error", "Error", data.message);
			}

			setLoading(false);
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				<CustomTable columns={columns} data={jobList} isScroll={true} />
			</Flex>
		);
	};

	const getPlants = async () => {
		setLoading(true);
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const url = api + `/plant/list/options`;

		try {
			const { data } = await axios.get(url);
			let list = [
				{
					value: "All",
					label: "All",
				},
			];

			if (data.success) {
				data.payload.map((item) => {
					list.push({
						value: item._id,
						label: item.name,
					});
				});
				setPlants(list);
				getJobs(startDate, endDate, "All");
			} else {
				openNotification("error", "Error", data.message);
				setLoading(false);
			}
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const onChangePlant = (value) => {
		if (value) {
			getJobs(startDate, endDate, value);
			setPlant(value);
		}
	};

	const onRangeChange = (dates) => {
		if (dates) {
			setStartDate(dates[0]);
			setEndDate(dates[1]);
			getJobs(dates[0], dates[1], plant);
		}
	};

	const RenderSearch = () => {
		return (
			<Flex gap={"middle"}>
				<CustomSearch
					loading={loading}
					startDate={startDate}
					endDate={endDate}
					onRangeChange={onRangeChange}
					dateFormat={"MM/DD/YYYY"}
					labelPicker={"Select plant"}
					pickerWidth={"200px"}
					optionsPicker={plants}
					onChangePicker={onChangePlant}
					pickerValue={plant}
				/>
				<Button
					type="primary"
					style={{ width: "200px" }}
					onClick={(e) => refDownload.current.link.click()}
					loading={loading}
					size="large"
				>
					Download Report
				</Button>
				<CSVLink
					data={jobList}
					headers={columnsCSV}
					filename={"57concrete-job-checkin.csv"}
					hidden
					ref={refDownload}
					style={{ width: 200 }}
				>
					Download report
				</CSVLink>
			</Flex>
		);
	};

	return (
		<Layout title="Job Check In Report" description="Report">
			{contextHolder}
			<Flex vertical gap={"large"}>
				<RenderSearch />
				{loading ? <CustomSpinner /> : <RenderTable />}
			</Flex>
		</Layout>
	);
};

export default JobCheckInReport;

import React, { Component } from "react";
import axios from "axios";
import Layout from "../layout/Layout";
import SellerPicker from "../seller/SellerPicker";
import DatePicker from "react-datepicker";
import SlumpPicker from "../slump/SlumpPicker";
import JobTypePicker from "../job/JobTypePicker";
import JobPoorPicker from "../job/JobPoorPicker";
import { toCurrency } from "../utils/Convert";
import PsiPicker from "../psi/PsiPicker";
import "react-datepicker/dist/react-datepicker.css";
import AddressGeolocation from "../map/AddressGeolocation";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import PaymentTypePicker from "../paymenttype/PaymentTypePicker";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { api } from "../utils/Api";
import ForemanPicker from "../foreman/ForemanPicker";
import ForemanNew from "../foreman/ForemanNuevo";
import ProspectionPicker from "../prospection/ProspectionPicker";
import Select from "react-select";
import { cityList } from "../utils/CityList";
import "../job/styles.css";
import ProspectionModal from "../prospection/ProspectionModal";
import CommentsModal from "./CommentsModal";

export default class NewProposal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			prospectionName: "",
			prospectionNumber: "",
			prospection: props.location.state.prospection,
			name: "",
			loadYD3: 0,
			targetYD3: "",
			shipAddress: "",
			shipCity: "",
			shipState: "TX",
			shipZip: "",
			proposalForeman: "",
			foremanPhone: "",
			apContact: "",
			apPhone: "",
			contact: "",
			date: new Date(),
			scheduledDate: new Date(),
			time: "08:00 AM",
			destinationMinutes: "30",
			taxExemptProposal: false,
			comments: [],
			po: "",
			proposalDuration: "",
			unitPrice: "",
			taxPrice: 0,
			slump: "",
			cliente: "",
			proposalPoor: "",
			proposalType: "",
			number: 0,
			seller: "",
			psi: "",
			balance: "",
			destinationTime: "",
			distance: "",
			latitude: "",
			longitude: "",
			searchAddress: false,
			paid: false,
			paymentType: "",
			approved: false,
			projectList: [],
			project: "",
			projectData: null,
			inspectionTimeStamp: new Date(),
			hotShot: false,
			supervisorPhone: "",
			supervisorName: "",
			pmPhone: "",
			projectManager: "",
			requireInspection: false,
			foreman: "",
			foremanModal: false,
			prospectionModal: false,
			autorized: true,
			fuelSurcharge: 25,
			showErrorModal: false,
			messageModal: "",
			titleModal: "",
			currentPrice: "",
			disabledSearch: false,
			selectedOption: "",
			deliveryFee: 300,
			fuelSurchargeGlobal: false,
			shippingGlobal: true,
			showComments: false,
			seller: props.location.state.seller,
			sellerName: "",
			prospectionId: props.location.state.prospection,
			appointmentDate: null,
		};

		this.autorizedPaymentUsers = ["ec@57concrete.com", "mv@57concrete.com"];

		this.onChangeProposalName = this.onChangeProposalName.bind(this);
		this.onChangeTargetYD3 = this.onChangeTargetYD3.bind(this);
		this.onChangeAddress = this.onChangeAddress.bind(this);
		this.onChangeContact = this.onChangeContact.bind(this);
		this.onChangeTime = this.onChangeTime.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onChangeProposalName(e) {
		this.setState({
			name: e.target.value,
		});
	}

	onChangeTargetYD3(e) {
		this.setState({
			targetYD3: e.target.value,
		});
	}

	onChangeAddress(e) {
		this.setState({
			address: e.target.value,
		});
	}

	onChangeContact(e) {
		this.setState({
			contact: e.target.value,
		});
	}

	onChangeDate = (date) => {
		this.setState({
			scheduledDate: date,
		});
	};

	onChangeInspectionDate = (date) => {
		this.setState({
			inspectionTimeStamp: date,
		});
	};

	onHandleColorTime = (date) => {
		if (this.state.scheduledDate.getDay() === date.getDay()) {
			if (date.getHours() < this.state.scheduledDate.getHours()) {
				return "";
			} else {
				return "text-error";
			}
		} else {
			return "";
		}
	};

	inputChangeHandler = (e) => {
		this.setState({ [e.target.name]: e.target.value });
		if (e.target.name === "unitPrice") {
			this.setState({
				taxPrice: e.target.value * 1.0825,
			});
		}
	};

	unitPriceInput = (e) => {
		let value = e.target.value;
		if (value >= 0 && value <= 2000) {
			this.setState({ [e.target.name]: value });
			this.setState({
				taxPrice: value * 1.0825,
			});
		}
	};

	onChangeTime(e) {
		this.setState({
			time: e.target.value,
		});
	}

	onChangeSeller = (selectedOption) => {
		if (selectedOption) {
			this.setState({
				seller: selectedOption.value,
			});
		}
	};

	onChangeSlump = (selectedSlump) => {
		if (selectedSlump) {
			this.setState({
				slump: selectedSlump.value,
			});
		}
	};

	// proposal poor option
	onChangeProposalPoor = (selectedPoor) => {
		if (selectedPoor) {
			this.setState({
				proposalPoor: selectedPoor.label,
			});
		}
	};

	// proposal type option
	onChangeProposalType = (selectedType) => {
		if (selectedType) {
			this.setState({
				proposalType: selectedType.label,
			});
		}
	};

	onChangeProspection = (selectedProspection) => {
		if (selectedProspection) {
			const values = selectedProspection.value.split(",");
			const number = selectedProspection.label.split(" ", 1);
			let prospectionName = selectedProspection.label;
			prospectionName = prospectionName.replace(`${number} `, "");

			this.setState({
				prospection: values[0],
				paymentType: values[1],
				prospectionName: prospectionName,
				prospectionNumber: number[0],
			});
		}
	};

	// change PSI
	onChangePsi = (selectedPsi) => {
		if (selectedPsi) {
			this.setState({
				psi: selectedPsi.label,
				unitPrice: selectedPsi.value,
				currentPrice: selectedPsi.value,
			});
			this.setPsiContractPrice(selectedPsi);
		}
	};

	// payment type
	onChangePaymentType = (selectedPayment) => {
		if (selectedPayment) {
			this.setState({
				paymentType: selectedPayment.label,
				approved: true,
			});
		}
	};

	// foreman phone
	onChangeForemanPhone = (e) => {
		this.setState({
			foremanPhone: e,
		});
	};

	// Foreman picker on change
	onChangeForeman = (selectedForeman) => {
		if (selectedForeman) {
			let option = selectedForeman.value.split(",");
			let foremanID = option[0];
			let foremanName = option[1];
			let foremanPhone = option[2];
			this.setState({
				foremanPhone: foremanPhone,
				proposalForeman: foremanName,
				foreman: foremanID,
			});
		}
	};

	// project option
	onChangeProject = (selectedProject) => {
		if (selectedProject) {
			this.setState({
				project: selectedProject.value,
			});

			this.getProjectInfo(selectedProject.value, this.state.projectList);
		}
	};

	onChangeHotShot = (e) => {
		this.setState({
			hotShot: !this.state.hotShot,
		});
	};

	onChangeSupervisorPhone = (e) => {
		this.setState({
			supervisorPhone: e,
		});
	};

	onChangePMPhone = (e) => {
		this.setState({
			pmPhone: e,
		});
	};

	onChangeRequireInspection = (e) => {
		this.setState({
			requireInspection: !this.state.requireInspection,
		});
	};

	validatePhone = (phone) => {
		const phoneno = /^\d{10}$/;
		const expresion =
			/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
		if (phone.match(expresion)) {
			return true;
		} else {
			alert("Invalid phone number");
			return false;
		}
	};

	// show/hide search modal
	onShowModal = (e) => {
		this.setState({
			searchAddress: !this.state.searchAddress,
		});
	};

	// address from modal
	handleCallback = (childData) => {
		this.setState({
			shipAddress: childData.address,
			destinationTime: childData.duration,
			distance: childData.distance,
			latitude: childData.destLat,
			longitude: childData.destLng,
			searchAddress: false,
			shipCity: childData.locality,
		});
		let cityFound = cityList.find((city) => city.label === childData.locality);
		if (typeof cityFound !== "undefined") {
			this.setSelected({
				value: childData.locality.toLowerCase(),
				label: childData.locality,
			});
		} else {
			alert(
				`The city ${childData.locality} is not defined in list, please select it from city options to save it.`
			);
		}
	};

	// show/hide foreman modal
	onShowNewForeman = (e) => {
		this.setState({
			foremanModal: !this.state.foremanModal,
		});
	};

	// new foreman modal
	onNewFormanNew = (nuevo) => {
		this.setState({
			foremanModal: false,
			proposalForeman: nuevo.firstName + " " + nuevo.lastName,
			foremanPhone: nuevo.phone,
		});
	};

	//Show/hide client modal
	onShowNewClient = (e) => {
		this.setState({
			prospectionModal: !this.state.prospectionModal,
		});
	};

	onNewClientNew = (nuevo) => {
		this.setState({
			//Cambios a datos para mostrar
			prospectionModal: false,
			prospectionName: nuevo.name,
			prospectionNumber: nuevo.prospectionNumber,
			prospection: nuevo._id,
		});
	};

	setSelected = (selected) => {
		this.setState({
			shipCity: selected.label,
			selectedOption: selected,
		});
	};

	//Toggle error modal
	toggleErrorModal = () => {
		this.setState({
			showErrorModal: !this.state.showErrorModal,
		});
	};

	onSubmit(e) {
		e.preventDefault();
		if (
			!(
				this.state.searchAddress ||
				this.state.prospectionModal ||
				this.state.foremanModal ||
				this.state.showComments
			)
		) {
			if (this.state.inspectionTimeStamp <= this.state.scheduledDate) {
				this.setState({
					messageModal: "Inspection and Schedule dates must be different",
					titleModal: "Check dates",
				});
				this.toggleErrorModal();
			} else {
				if (
					this.state.prospection &&
					this.state.seller &&
					this.state.psi &&
					this.state.shipAddress &&
					this.state.shipCity &&
					this.state.proposalForeman &&
					this.state.targetYD3 &&
					this.state.slump &&
					this.state.paymentType &&
					this.state.proposalType &&
					this.state.proposalPoor &&
					this.state.unitPrice
				) {
					this.createNewProposal();
					this.updateProspection();
				} else {
					let missingFields = [];
					if (!this.state.seller) missingFields.push("Seller");
					if (!this.state.prospection) missingFields.push("Prospection");
					if (!this.state.proposalType) missingFields.push("Proposal Type");
					if (!this.state.proposalPoor) missingFields.push("Proposal Pour");
					if (!this.state.psi) missingFields.push("Psi");
					if (!this.state.shipAddress) missingFields.push("Shipping Address");
					if (!this.state.shipCity) missingFields.push("Shipping City");
					if (!this.state.proposalForeman) missingFields.push("Foreman");
					if (!this.state.paymentType) missingFields.push("Payment Type");
					if (!this.state.targetYD3) missingFields.push("Target YD3");
					if (!this.state.unitPrice) missingFields.push("Unit Price");
					if (!this.state.slump) missingFields.push("Slump");

					let customeMessage = "";
					for (let i = 0; i < missingFields.length; i++) {
						if (i !== missingFields.length - 1) {
							customeMessage += missingFields[i] + ", ";
						} else {
							customeMessage += missingFields[i];
						}
					}

					this.setState({
						messageModal: `Missing fields: ${customeMessage}`,
						titleModal: "Missing fields to fill",
					});
					this.toggleErrorModal();
				}
			}
		}
	}

	// POST
	createNewProposal = async () => {
		const user = localStorage.getItem("userId");
		const proposal = {
			...this.state,
			firstShipementDate: this.state.date,
			scheduledTime: this.state.time,
			taxExemptProposal: false,
			seller: this.state.seller,
			shipAddress: this.state.shipAddress,
			destinationTime: this.state.destinationTime,
			distance: this.state.distance,
			latitude: this.state.latitude,
			longitude: this.state.longitude,
			paymentType: this.state.paymentType,
			approved: this.state.approved,
			createdBy: user,
			updatedBy: user,
			inspectionTimeStamp: this.state.requireInspection
				? this.state.inspectionTimeStamp
				: null,
			hotShot: this.state.hotShot,
			requireInspection: this.state.requireInspection,
			shipCity: this.state.shipCity,
			fuelSurchargeGlobal: this.state.fuelSurchargeGlobal,
			shippingGlobal: this.state.shippingGlobal,
		};

		try {
			const url = api + "/proposal";
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

			await axios.post(url, proposal);
			this.props.history.push("/opportunitystatus");
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	updateProspection = async () => {
		const url = api + "/prospection/" + this.state.prospectionId;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		const prospection = {
			prospectionStatus: "Proposal",
		};

		if (this.state.appointmentDate !== null)
			prospection["appointmentDate"] = new Date();

		try {
			await axios.patch(url, prospection);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	getProposalId = async () => {
		const url = api + "/jobid";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			this.setState({
				number: data.jobId,
				//seller: localStorage.getItem('userId')
			});
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	getProjectInfo = (projectID, projectList) => {
		const projSelected = projectList.filter((proj) => proj._id === projectID);
		this.setState({
			projectData: projSelected,
		});

		if (
			projSelected[0].shipAddress !== undefined &&
			projSelected[0].shipCity !== undefined &&
			projSelected[0].destinationTime !== undefined &&
			projSelected[0].distance !== undefined &&
			projSelected[0].latitude !== undefined &&
			projSelected[0].longitude !== undefined
		) {
			this.setState({
				shipAddress: projSelected[0].shipAddress,
				shipCity: projSelected[0].shipAddress,
				destinationTime: projSelected[0].destinationTime,
				distance: projSelected[0].distance,
				latitude: projSelected[0].latitude,
				longitude: projSelected[0].longitude,
				disabledSearch: true,
			});
		} else {
			this.setState({
				shipAddress: "",
				shipCity: "",
				destinationTime: "",
				distance: "",
				latitude: "",
				longitude: "",
				disabledSearch: false,
			});
		}

		if (this.state.psi) {
			const psiUsed = projSelected[0].productPrice.filter(
				(proj) => proj.psi === this.state.psi
			);
			// Psi selected is used in the Project
			if (psiUsed.length > 0) {
				if (projSelected[0].start && projSelected[0].end) {
					const valid = this.projectValid(
						projSelected[0].start,
						projSelected[0].end
					);

					if (valid) {
						this.setState({
							unitPrice: psiUsed[0].specialPrice ? psiUsed[0].specialPrice : 0,
							taxPrice: psiUsed[0].specialPrice
								? psiUsed[0].specialPrice * 1.0825
								: 0,
						});
					} else {
						this.setState({
							unitPrice: psiUsed[0].listPrice ? psiUsed[0].listPrice : 0,
							taxPrice: psiUsed[0].listPrice
								? psiUsed[0].listPrice * 1.0825
								: 0,
						});
					}
				}
			} else {
				this.setState({
					unitPrice: this.state.currentPrice,
					taxPrice: this.state.currentPrice * 1.0825,
				});
			}
		}
	};

	projectValid = (start, end) => {
		const todayDate = new Date();

		// FORMAT: MM/DD/YYYY
		start = `${start.substring(5, 7)}/${start.substring(
			8,
			10
		)}/${start.substring(0, 4)}`;
		end = `${end.substring(5, 7)}/${end.substring(8, 10)}/${end.substring(
			0,
			4
		)}`;

		let currentDate = new Date().toJSON().slice(0, 8);
		currentDate +=
			todayDate.getDate() > 9
				? `${todayDate.getDate()}`
				: `0${todayDate.getDate()}`;

		const from = new Date(start);
		const to = new Date(end);
		const check = new Date(currentDate);

		return check > from && check < to;
	};

	setPsiContractPrice = (selectedPsi) => {
		// Project Data is already selected
		if (this.state.projectData) {
			const psiUsed = this.state.projectData[0].productPrice.filter(
				(proj) => proj.psi === selectedPsi.label
			);

			// Psi selected is used in the Project
			if (psiUsed.length > 0) {
				if (this.state.projectData[0].start && this.state.projectData[0].end) {
					const valid = this.projectValid(
						this.state.projectData[0].start,
						this.state.projectData[0].end
					);

					if (valid) {
						this.setState({
							unitPrice: psiUsed[0].specialPrice,
							taxPrice: psiUsed[0].specialPrice * 1.0825,
						});
					} else {
						this.setState({
							unitPrice: psiUsed[0].listPrice,
							taxPrice: psiUsed[0].listPrice * 1.0825,
						});
					}
				}
			} else {
				this.setState({
					unitPrice: selectedPsi.value,
					taxPrice: selectedPsi.value * 1.0825,
				});
			}
		} else {
			this.setState({
				unitPrice: selectedPsi.value,
				taxPrice: selectedPsi.value * 1.0825,
			});
		}
	};

	setProposalComments = (newComments) => {
		this.setState({
			comments: newComments,
		});
	};

	showCommentsModal = () => {
		this.setState({
			showComments: !this.state.showComments,
		});
	};

	getSeller = async () => {
		const url = api + `/users/${this.state.seller}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			this.setState({
				sellerName: data.name,
			});
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	getProspection = async () => {
		const url = api + `/prospection/${this.state.prospection}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			this.setState({
				paymentType: data.paymentType,
				prospectionName: data.name,
				prospectionNumber: data.prospectionNumber,
				appointmentDate: data.appointmentDate ? data.appointmentDate : null,
			});
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	componentDidMount() {
		this.getProposalId();
		this.getSeller();
		this.getProspection();
		const yo = localStorage.getItem("email");
		const eliud = "ec@57concrete.com";
		const encontrado = this.autorizedPaymentUsers.find(
			(esta) => esta === eliud
		);
		if (encontrado) {
			this.setState({
				authorized: true,
			});
		}
	}

	ProposalForm = () => (
		<form className="form" onSubmit={this.onSubmit}>
			<div className="form-group">
				<input
					type="text"
					className="form-control"
					readOnly
					value={`Proposal ID:  ${this.state.number}`}
				/>
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<div className="form-group">
						<label className="text-muted">Prospection</label>
						<input
							type="text"
							readOnly
							value={`${this.state.prospectionNumber} ${this.state.prospectionName}`}
							className="form-control"
						/>
					</div>
				</div>
				<div className="col-sm-6">
					<div className="form-group">
						<label className="text-muted">Seller</label>
						<input
							type="text"
							readOnly
							value={`${this.state.sellerName}`}
							className="form-control"
						/>
					</div>
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">Proposal Type</label>
					<JobTypePicker
						action={this.onChangeProposalType}
						placeholder={"Proposal Type..."}
					/>
				</div>
				<div className="col-sm-6">
					<label className="text-muted">Proposal Pour</label>
					<JobPoorPicker
						action={this.onChangeProposalPoor}
						placeholder={"Proposal Pour..."}
					/>
				</div>
			</div>
			<div className="form-group">
				<label className="text-muted">PSI</label>
				<PsiPicker action={this.onChangePsi} />
			</div>
			<div>
				<label className="text-muted">Ship to Address</label>
			</div>
			<div className="form-group row">
				<div className="col-sm-4">
					<button
						className="btn btn-primary"
						onClick={this.onShowModal}
						disabled={this.state.disabledSearch}
					>
						Search address <FontAwesomeIcon icon={faSearch} />
					</button>
				</div>
				<div className="col-sm-8">
					<input
						type="text"
						name="shipAddress"
						className="form-control"
						readOnly
						value={this.state.shipAddress}
					/>
				</div>
			</div>
			<br></br>
			<div className="form-row d-flex justify-content-end">
				<div className="col-sm-8">
					<Select
						options={cityList}
						onChange={this.setSelected}
						value={this.state.selectedOption}
						placeholder="City..."
					/>
				</div>
			</div>
			<div>
				{this.state.searchAddress && (
					<Modal
						show={this.state.searchAddress}
						animation={false}
						onHide={this.onShowModal}
					>
						<Modal.Header closeButton>
							<Modal.Title>Address</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<AddressGeolocation
								search={this.state.searchAddress}
								parentCallback={this.handleCallback}
								single={false}
							/>
						</Modal.Body>
					</Modal>
				)}
			</div>
			<div className="form-group">
				<label className="text-muted">Time to destination</label>
				<input
					type="text"
					name="destinationTime"
					className="form-control"
					readOnly
					value={this.state.destinationTime}
				/>
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">Foreman:&nbsp;</label>
					<label>{this.state.proposalForeman || " Not defined yet"}</label>
					<ForemanPicker
						action={this.onChangeForeman}
						selectedOption={this.state.proposalForeman}
					/>
				</div>
				<div className="col-sm-6">
					<br />
					<button className="btn btn-primary" onClick={this.onShowNewForeman}>
						New foreman +
					</button>
					<Modal
						show={this.state.foremanModal}
						animation={false}
						onHide={this.onShowNewForeman}
					>
						<Modal.Header closeButton>
							<Modal.Title>New Foreman</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<ForemanNew parentCallback={this.onNewFormanNew} />
						</Modal.Body>
					</Modal>
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">Supervisor</label>
					<input
						type="text"
						name="supervisorName"
						className="form-control"
						placeholder="Supervisor name"
						value={this.state.supervisorName}
						onChange={this.inputChangeHandler}
					/>
				</div>
				<div className="col-sm-6">
					<label className="text-muted">Supervisor Phone</label>
					<PhoneInput
						className="form-control"
						defaultCountry="US"
						country="US"
						name="supervisorPhone"
						placeholder="Phone number"
						value={this.state.supervisorPhone}
						onChange={this.onChangeSupervisorPhone}
					/>
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">Project Manager</label>
					<input
						type="text"
						name="projectManager"
						className="form-control"
						placeholder="PM Name"
						value={this.state.projectManager}
						onChange={this.inputChangeHandler}
					/>
				</div>
				<div className="col-sm-6">
					<label className="text-muted">Project Manager Phone</label>
					<PhoneInput
						className="form-control"
						defaultCountry="US"
						country="US"
						name="pmPhone"
						placeholder="PM Phone"
						value={this.state.pmPhone}
						onChange={this.onChangePMPhone}
					/>
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">AP Contact</label>
					<input
						type="text"
						name="apContact"
						className="form-control"
						placeholder="Name"
						value={this.state.apContact}
						onChange={this.inputChangeHandler}
					/>
				</div>
				<div className="col-sm-6">
					<label className="text-muted"> </label>
					<input
						type="text"
						name="apPhone"
						className="form-control"
						placeholder="Email"
						value={this.state.apPhone}
						onChange={this.inputChangeHandler}
					/>
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">Tax Exempt Proposal</label>
					<div className="radio">
						<label>
							<input
								type="radio"
								name="taxExemptProposal"
								value="Yes"
								onChange={this.inputChangeHandler}
							/>
							Yes
						</label>{" "}
						<label>
							<input
								type="radio"
								name="taxExemptProposal"
								value="No"
								checked
								onChange={this.inputChangeHandler}
							/>
						</label>
						No
					</div>
				</div>
				{localStorage.getItem("role") === "Finance" && (
					<div className="col-sm-6">
						<label className="text-muted">{`Payment type: ${this.state.paymentType}`}</label>
						<PaymentTypePicker action={this.onChangePaymentType} />
					</div>
				)}
			</div>
			<hr />
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">Target YD3</label>
					<input
						type="number"
						name="targetYD3"
						className="form-control"
						onChange={this.onChangeTargetYD3}
						placeholder="?"
					/>
				</div>
				<div className="col-sm-6">
					<label className="text-muted">Proposal Duration</label>
					<input
						type="text"
						name="proposalDuration"
						className="form-control"
						onChange={this.inputChangeHandler}
					/>
				</div>
			</div>
			<hr />
			<div className="form-group row">
				<div className="col-sm-3">
					<div>
						<label className="text-muted">Proposal date/time</label>
					</div>
					<DatePicker
						selected={this.state.scheduledDate}
						showTimeSelect
						timeFormat="HH:mm"
						dateFormat="MM/dd/yyyy h:mm aa"
						onChange={this.onChangeDate}
					/>
				</div>
				<div className="col-sm-3">
					<div>
						<label className="text-muted">Inspection date/time</label>
					</div>
					<DatePicker
						selected={this.state.inspectionTimeStamp}
						showTimeSelect
						timeFormat="HH:mm"
						dateFormat="MM/dd/yyyy h:mm aa"
						onChange={this.onChangeInspectionDate}
						minDate={this.state.scheduledDate}
						//timeClassName={this.onHandleColorTime}
					/>
				</div>
			</div>
			<div className="form-group row">
				<div className="my-2">
					<input
						name="accept"
						type="checkbox"
						checked={this.state.hotShot}
						onChange={this.onChangeHotShot}
					/>
					<label>&nbsp;Hot Shot</label>
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-6">
					<label className="text-muted">Unit price</label>
					<input
						type="text"
						name="unitPrice"
						className="form-control"
						onChange={this.unitPriceInput}
						value={this.state.unitPrice}
						placeholder="$"
					/>
				</div>
				<div className="col-sm-6">
					<label className="text-muted">Price with Tax</label>
					<input
						type="text"
						name="taxPrice"
						readOnly
						className="form-control"
						value={toCurrency(this.state.taxPrice, 2)}
					/>
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-2 d-flex justify-content-center">
					<input
						name="fuelSurchargeGlobal"
						type="checkbox"
						checked={this.state.fuelSurchargeGlobal}
						onChange={(e) =>
							this.setState({
								fuelSurchargeGlobal: !this.state.fuelSurchargeGlobal,
							})
						}
					/>
					<label className="mt-auto mb-auto">&nbsp;Apply To Global</label>
				</div>
				<div className="col-sm-10">
					<label className="text-muted">Fuel Surcharge</label>
					<input
						type="text"
						name="fuelSurcharge"
						className="form-control"
						value={this.state.fuelSurcharge}
						onChange={this.inputChangeHandler}
					/>
				</div>
			</div>
			<div className="form-group row">
				<div className="col-sm-2 d-flex justify-content-center">
					<input
						name="shippingGlobal"
						type="checkbox"
						checked={this.state.shippingGlobal}
						onChange={(e) =>
							this.setState({
								shippingGlobal: !this.state.shippingGlobal,
							})
						}
					/>
					<label className="mt-auto mb-auto">&nbsp;Apply To Global</label>
				</div>
				<div className="col-sm-10">
					<label className="text-muted">Shipping Fee</label>
					<input
						type="text"
						name="deliveryFee"
						className="form-control"
						value={this.state.deliveryFee}
						onChange={this.inputChangeHandler}
					/>
				</div>
			</div>
			<div className="form-group">
				<div>
					<label className="text-muted">Comments</label>
				</div>
				<div className="form-group row">
					<div className="col-sm-4">
						<button
							className="btn btn-primary"
							onClick={this.showCommentsModal}
						>
							Add Comments +
						</button>
					</div>
				</div>
				<Modal
					show={this.state.showComments}
					animation={false}
					onHide={this.showCommentsModal}
				>
					<Modal.Header closeButton>
						<Modal.Title>{`New comments`}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<CommentsModal
							comments={this.state.comments}
							pcb={this.setProposalComments}
						/>
					</Modal.Body>
				</Modal>
			</div>
			<div className="form-group">
				<label className="text-muted">Slump</label>
				<SlumpPicker action={this.onChangeSlump} />
			</div>
			<div className="my-2">
				<input className="btn btn-primary" type="submit" />
			</div>
		</form>
	);

	render() {
		return (
			<Layout
				title="Proposal"
				description="Create New Proposal"
				className="container col-md-8 offset-md-2"
			>
				{this.ProposalForm()}
				<Modal
					show={this.state.showErrorModal}
					animation={false}
					onHide={this.toggleErrorModal}
					size="lg"
					centered
					dialogClassName="modal-width"
				>
					<Modal.Header closeButton>
						<Modal.Title>{this.state.titleModal}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<p>{this.state.messageModal}</p>
					</Modal.Body>
				</Modal>
			</Layout>
		);
	}
}

import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import {
	Button,
	Col,
	Divider,
	Flex,
	Form,
	Input,
	Modal,
	Row,
	Select,
	notification,
	Typography,
	Radio,
	InputNumber,
	DatePicker,
	Checkbox,
} from "antd";
import { api } from "../utils/Api";
import axios from "axios";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import ClientModal2 from "../client/ClientModal2";
import {
	jobPourOptions,
	jobTypeOptions,
	slumpOptions,
} from "../../constants/Job";
import { cityList } from "../utils/CityList";
import AddressGeolocation2 from "../map/AddressGeolocation2";
import ForemanModal from "../foreman/ForemanModal";
import { paymentTypeOptions } from "../../constants/Customer";
import dayjs from "dayjs";
const { Title } = Typography;
const { Option } = Select;

const NewJob = ({ history }) => {
	const [loadingComponents, setLoadingComponents] = useState(false);
	const [openNewCustomerModal, setOpenNewCustomerModal] = useState(false);
	const [openSearchAddressModal, setOpenSearchAddressModal] = useState(false);
	const [openNewForemanModal, setOpenNewForemanModal] = useState(false);
	const [allowSearchAddress, setAllowSearchAddress] = useState(true);
	const [validPaymentType, setValidPaymentType] = useState(false);
	const [disabledSearch, setDisabledSearch] = useState(false);
	const [disabledAddress, setDisabledAddress] = useState(true);
	const [optionsCustomers, setOptionsCustomers] = useState([]);
	const [optionsSellers, setOptionsSellers] = useState([]);
	const [optionsProjects, setOptionsProjects] = useState([]);
	const [optionsPsi, setOptionsPsi] = useState([]);
	const [optionsPlant, setOptionsPlant] = useState([]);
	const [optionsForemans, setOptionsForemans] = useState([]);
	const [projectList, setProjectList] = useState([]);
	const [projectSelected, setProjectSelected] = useState(null);
	const [form] = Form.useForm();
	const [initialValues, setInitialValues] = useState({});
	const [dateMax, setDateMax] = useState(dayjs());

	const [loadingCustomer, setLoadingCustomer] = useState(true);
	const [loadingPsi, setLoadingPsi] = useState(true);
	const [loadingPlant, setLoadingPlant] = useState(true);
	const [loadingSeller, setLoadingSeller] = useState(true);
	const [loadingForeman, setLoadingForeman] = useState(true);
	const [loadingProject, setLoadingProject] = useState(false);

	const [apiNotification, contextHolder] = notification.useNotification();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	useEffect(() => {
		getValues();
	}, []);

	const getValues = () => {
		Promise.all([
			getJobId(),
			getCustomers(),
			getSellers(),
			getPsi(),
			getPlants(),
			getForeman(),
		]);
	};

	const getJobId = async () => {
		const url = api + "/jobid";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			setInitialValues({
				jobId: data.jobId,
				seller:
					localStorage.getItem("corporateSales") === "true"
						? "6256e9ac31537d786236a6bf"
						: localStorage.getItem("userId"),
				currentSeller: localStorage.getItem("userName"),
				prefix: "+1",
				taxExemptJob: false,
				approved: false,
				scheduledDate: dayjs(),
				inspectionTimeStamp: dayjs(),
				fuelSurchargeGlobal: false,
				fuelSurcharge: 0,
				shippingGlobal: true,
				deliveryFee: 300,
				qualityControl: false,
				shipCity: undefined,
				latitude: undefined,
				longitude: undefined,
				foremanPhone: undefined,
				jobForeman: undefined,
				foreman: undefined,
				psiPrice: undefined,
				psiId: undefined,
				customerId: undefined,
				hotShot: false,
			});
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const getCustomers = async () => {
		const url = api + "/allcustomers";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);

			const list = [];

			data.forEach((customer) => {
				let value = `${customer._id},${customer.paymentType}`; // CustomerID,PaymentType
				let aux = `${customer.customerNumber} ${customer.name}`;
				let label = aux.toUpperCase();

				let obj = {
					value: value,
					label: label,
				};

				list.push(obj);
			});

			setOptionsCustomers(list);
			setLoadingCustomer(false);
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	const getSellers = async () => {
		const url = api + "/userlist/Seller";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			data.map((item) => {
				list.push({
					value: item._id,
					label: item.name,
				});
			});
			setOptionsSellers(list);
			setLoadingSeller(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const getPlants = async () => {
		const url = api + "/plant";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			data.map((item) => {
				list.push({
					value: item._id,
					label: item.name,
				});
			});
			setOptionsPlant(list);
			setLoadingPlant(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const getPsi = async () => {
		const url = api + "/psi";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			data.map((item) => {
				list.push({
					value: `${item._id},${item.price}`,
					label: item.psi,
				});
			});
			setOptionsPsi(list);
			setLoadingPsi(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const getForeman = async () => {
		const url = api + `/foreman`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			const optionsAux = [];
			data.forEach((foreman) => {
				let value = `${foreman._id},${foreman.firstName} ${foreman.lastName},${foreman.phone}`;
				let aux = `${foreman.firstName} ${foreman.lastName}`;
				let label = aux.toUpperCase();
				let obj = {
					value: value,
					label: label,
				};
				if (foreman.active) optionsAux.push(obj);
			});
			setOptionsForemans(optionsAux);
			setLoadingForeman(false);
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	const getProjects = async (customerID) => {
		const url = api + "/projectcustomer/" + customerID;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			data.map((item) => {
				list.push({
					value: item._id,
					label: item.name,
				});
			});
			setProjectList(data);
			setOptionsProjects(list);
			setLoadingProject(false);
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	const onChangeCustomer = (selectedCustomer) => {
		if (selectedCustomer) {
			const values = selectedCustomer.value.split(",");
			setLoadingProject(true);
			getProjects(values[0]);
			form.setFieldValue("paymentType", values[1]);
			form.setFieldValue("customerId", values[0]);
			setValidPaymentType(true);
		}
	};

	const projectValid = (start, end) => {
		const todayDate = new Date();

		// FORMAT: MM/DD/YYYY
		start = `${start.substring(5, 7)}/${start.substring(
			8,
			10
		)}/${start.substring(0, 4)}`;
		end = `${end.substring(5, 7)}/${end.substring(8, 10)}/${end.substring(
			0,
			4
		)}`;

		let currentDate = new Date().toJSON().slice(0, 8);
		currentDate +=
			todayDate.getDate() > 9
				? `${todayDate.getDate()}`
				: `0${todayDate.getDate()}`;

		const from = new Date(start);
		const to = new Date(end);
		const check = new Date(currentDate);

		return check > from && check < to;
	};

	const getProjectInfo = (projectID) => {
		const project = projectList.filter((proj) => proj._id === projectID);
		setProjectSelected(project);

		if (
			project[0].shipAddress !== undefined &&
			project[0].shipCity !== undefined &&
			project[0].destinationTime !== undefined &&
			project[0].distance !== undefined &&
			project[0].latitude !== undefined &&
			project[0].longitude !== undefined
		) {
			form.setFieldsValue({
				shipAddress: project[0].shipAddress,
				shipCity: project[0].shipCity,
				city: project[0].shipCity.toLowerCase(),
				destinationTime: project[0].destinationTime,
				distance: project[0].distance,
				latitude: project[0].latitude,
				longitude: project[0].longitude,
			});
			setDisabledSearch(true);
		} else {
			setDisabledSearch(false);
		}
		const psi = form.getFieldValue("psi");
		if (psi) {
			const psiUsed = project[0].productPrice.filter(
				(proj) => proj.psi === psi
			);
			// Psi selected is used in the Project
			if (psiUsed.length > 0) {
				if (project[0].start && project[0].end) {
					const valid = projectValid(project[0].start, project[0].end);

					if (valid) {
						form.setFieldsValue({
							unitPrice: psiUsed[0].specialPrice ? psiUsed[0].specialPrice : 0,
							taxPrice: psiUsed[0].specialPrice
								? psiUsed[0].specialPrice * 1.0825
								: 0,
						});
					} else {
						form.setFieldsValue({
							unitPrice: psiUsed[0].listPrice ? psiUsed[0].listPrice : 0,
							taxPrice: psiUsed[0].listPrice
								? psiUsed[0].listPrice * 1.0825
								: 0,
						});
					}
				}
			} else {
				form.setFieldsValue({
					unitPrice: form.getFieldValue("psiPrice"),
					taxPrice: form.getFieldValue("psiPrice") * 1.0825,
				});
			}
		} else {
			form.setFieldsValue({
				unitPrice: 0,
				taxPrice: 0,
			});
		}
	};

	const setPsiContractPrice = (selectedPsi) => {
		// Project Data is already selected
		if (projectSelected) {
			const psiUsed = projectSelected[0].productPrice.filter(
				(proj) => proj.psi === selectedPsi.label
			);

			// Psi selected is used in the Project
			if (psiUsed.length > 0) {
				if (projectSelected[0].start && projectSelected[0].end) {
					const valid = this.projectValid(
						projectSelected[0].start,
						projectSelected[0].end
					);

					if (valid) {
						form.setFieldsValue({
							unitPrice: psiUsed[0].specialPrice,
							taxPrice: psiUsed[0].specialPrice * 1.0825,
						});
					} else {
						form.setFieldsValue({
							unitPrice: psiUsed[0].listPrice,
							taxPrice: psiUsed[0].listPrice * 1.0825,
						});
					}
				}
			} else {
				let values = selectedPsi.value.split(",");
				form.setFieldsValue({
					unitPrice: values[1],
					taxPrice: (values[1] * 1.0825).toFixed(3),
				});
			}
		} else {
			let values = selectedPsi.value.split(",");
			form.setFieldsValue({
				unitPrice: values[1],
				taxPrice: (values[1] * 1.0825).toFixed(3),
			});
		}
	};

	const onChangeProject = (selectedProject) => {
		if (selectedProject) {
			getProjectInfo(selectedProject);
		}
	};

	const onChangePsi = (selectedPsi) => {
		if (selectedPsi) {
			let values = selectedPsi.value.split(",");
			form.setFieldsValue({
				psiPrice: values[1],
				unitPrice: values[1],
				psi: selectedPsi.label,
				psiId: values[0],
			});
			setPsiContractPrice(selectedPsi);
		}
	};

	const onChangePlant = (selectedPlant) => {
		if (selectedPlant) setAllowSearchAddress(false);
	};

	const onChangeCity = (selectedCity) => {
		if (selectedCity) {
			form.setFieldValue("shipCity", selectedCity.label);
		}
	};

	const onChangeForeman = (selectedForeman) => {
		if (selectedForeman) {
			let option = selectedForeman.split(",");
			let foremanID = option[0];
			let foremanName = option[1];
			let foremanPhone = option[2];
			form.setFieldsValue({
				foremanPhone: foremanPhone.slice(2),
				jobForeman: foremanName,
				foreman: foremanID,
			});
		}
	};

	const onChangePaymentType = (selectedPaymentType) => {
		if (selectedPaymentType) {
			form.setFieldValue("approved", true);
		}
	};

	const onChangeUnitPrice = (value) => {
		if (value >= 0 && value <= 2000) {
			form.setFieldValue("taxPrice", (value * 1.0825).toFixed(3));
		}
	};

	const onNewClientNew = async (newClient) => {
		setLoadingCustomer(true);
		await getCustomers();
		form.setFieldValue("customer", newClient);
		setOpenNewCustomerModal(false);
	};

	const handleNewCustomerCancel = () => {
		setOpenNewCustomerModal(false);
	};

	const handleNewCustomerOpen = () => {
		setOpenNewCustomerModal(true);
	};

	const RenderNewCustomerModal = () => {
		return (
			<Modal
				title="New Customer"
				open={openNewCustomerModal}
				onCancel={handleNewCustomerCancel}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				width={600}
			>
				<ClientModal2 pcb={onNewClientNew} />
			</Modal>
		);
	};

	const handleSearchAddressCancel = () => {
		setOpenSearchAddressModal(false);
	};

	const handleSearchAddressOpen = () => {
		setOpenSearchAddressModal(true);
	};

	// address from modal
	const handleCallback = (childData) => {
		form.setFieldsValue({
			shipAddress: childData.address,
			destinationTime: childData.duration,
			distance: childData.distance,
			latitude: childData.destLat,
			longitude: childData.destLng,
			shipCity: childData.locality,
		});
		handleSearchAddressCancel();
		setDisabledAddress(false);

		let cityFound = cityList.find((city) => city.label === childData.locality);
		if (typeof cityFound !== "undefined") {
			form.setFieldValue("city", childData.locality.toLowerCase());
		} else {
			openNotification(
				"warning",
				"Warning",
				`The city ${childData.locality} is not defined in list, please select it from city options to save it.`
			);
		}
	};

	const RenderSearchAddressModal = () => {
		return (
			<Modal
				title="Address"
				open={openSearchAddressModal}
				onCancel={handleSearchAddressCancel}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				width={600}
			>
				<AddressGeolocation2
					search={!allowSearchAddress}
					parentCallback={handleCallback}
					single={false}
					plant={form.getFieldValue("plant")}
					currentLocation={false}
				/>
			</Modal>
		);
	};

	const handleNewForemanCancel = () => {
		setOpenNewForemanModal(false);
	};

	const handleNewForemanOpen = () => {
		setOpenNewForemanModal(true);
	};

	const onNewFormanNew = async (newForeman) => {
		setLoadingForeman(true);
		await getForeman();
		let selected = `${newForeman.foreman},${
			newForeman.firstName + " " + newForeman.lastName
		},${newForeman.phone}`;
		form.setFieldsValue({
			jobForeman: newForeman.firstName + " " + newForeman.lastName,
			foremanPhone: newForeman.phone.slice(2),
			foreman: newForeman.foreman,
			foremanItem: selected,
		});
		handleNewForemanCancel();
	};

	const RenderNewForemanModal = () => {
		return (
			<Modal
				title="New Foreman"
				open={openNewForemanModal}
				onCancel={handleNewForemanCancel}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				width={600}
			>
				<ForemanModal parentCallback={onNewFormanNew} />
			</Modal>
		);
	};

	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const prefixSelector = (
		<Form.Item name="prefix" noStyle>
			<Select
				style={{
					width: 70,
				}}
			>
				<Option value="+1">+1</Option>
			</Select>
		</Form.Item>
	);

	const range = (start, end) => {
		const result = [];
		for (let i = start; i < end; i++) {
			result.push(i);
		}
		return result;
	};

	const disabledDateTime = () => {
		const scheduledDateMinute = dayjs(dateMax).minute() + 1;
		const scheduledDateHour = dayjs(dateMax).hour() + 1;
		return {
			disabledMinutes: () => range(scheduledDateMinute, 60),
			disabledHours: () => range(scheduledDateHour, 24),
		};
	};

	const onChangeDate = (value) => {
		setDateMax(value);
		form.setFieldValue("inspectionTimeStamp", value);
	};

	const validateForm = (values) => {
		/*! If is true dates are equal */
		if (dayjs(values.inspectionTimeStamp).isSame(dayjs(values.scheduledDate))) {
			openNotification(
				"warning",
				"Warning",
				"Inspection and Scheduled date must be different"
			);
			setLoadingComponents(false);
		} else {
			createNewJob(values);
		}
	};

	const createNewJob = async (values) => {
		let job = {
			number: values.jobId,
			seller: values.seller,
			shipAddress: values.shipAddress,
			shipCity: values.shipCity,
			destinationTime: values.destinationTime,
			distance: values.distance,
			latitude: values.latitude,
			longitude: values.longitude,
			approved: values.approved,
			createdBy: localStorage.getItem("userId"),
			updatedBy: localStorage.getItem("userId"),
			inspectionTimeStamp: values.inspectionTimeStamp.toDate(),
			scheduledDate: values.scheduledDate.toDate(),
			hotShot: values.hotShot,
			fuelSurchargeGlobal: values.fuelSurchargeGlobal,
			shippingGlobal: values.shippingGlobal,
			deliveryFee: values.deliveryFee,
			fuelSurcharge: values.fuelSurcharge,
			qualityControl: values.qualityControl,
			slump: values.slump,
			taxPrice: values.taxPrice,
			unitPrice: values.unitPrice,
			targetYD3: values.targetYD3,
			paymentType: values.paymentType,
			taxExemptJob: values.taxExemptJob,
			foremanPhone: "+1" + values.foremanPhone,
			jobForeman: values.jobForeman,
			foreman: values.foreman,
			plant: values.plant,
			psi: values.psi,
			psiId: values.psiId,
			jobPoor: values.jobPour,
			jobType: values.jobType,
			customer: values.customerId,
			firstShipmentDate: dayjs().toDate(),
			loadYD3: 0,
			shipState: "TX",
		};

		if (values.apPhone) job.apPhone = "+1" + values.apPhone;
		if (values.pmPhone) job.pmPhone = "+1" + values.pmPhone;
		if (values.supervisorPhone)
			job.supervisorPhone = "+1" + values.supervisorPhone;
		if (values.apContact) job.apContact = values.apContact;
		if (values.projectManager) job.projectManager = values.projectManager;
		if (values.supervisorName) job.supervisorName = values.supervisorName;
		if (values.balance) job.balance = values.balance;
		if (values.jobDuration) job.jobDuration = values.jobDuration;
		if (values.project) job.project = values.project;
		if (values.comments) job.comments = values.comments;
		if (values.fiberQuantity) job.fiberQuantity = values.fiberQuantity;

		job.jobStatusHistory = [
			{
				user: localStorage.getItem("userId"),
				jobStatus: "New Order",
			},
		];

		try {
			const url = api + "/job";
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
			await axios.post(url, job);
			openNotification("success", "Success", "Job created succesfully");
			setTimeout(() => {
				setLoadingComponents(false);
				history.push("/dailyappointment");
			}, 1000);
		} catch (err) {
			setLoadingComponents(false);
			openNotification(
				"error",
				"Error",
				"Error while trying to create new job"
			);
			console.error(err.message);
			return null;
		}
	};

	const onFinish = () => {
		setLoadingComponents(true);
		const values = form.getFieldsValue(true);
		validateForm(values);
	};

	const RenderForm = () => {
		return (
			<Form
				form={form}
				layout="vertical"
				initialValues={initialValues}
				onFinish={onFinish}
			>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item label="Job ID" name="jobId">
							<Input size="large" disabled />
						</Form.Item>
					</Col>
					{localStorage.getItem("role") === "Seller" && (
						<Col span={12}>
							<Form.Item label="Seller" name="currentSeller">
								<Input size="large" disabled />
							</Form.Item>
						</Col>
					)}
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							label="Customer"
							name="customer"
							rules={[
								{
									required: true,
									message: "Please select a customer",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select customer"
								options={optionsCustomers}
								disabled={loadingComponents}
								loading={loadingCustomer}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
								labelInValue
								onChange={onChangeCustomer}
							/>
						</Form.Item>
					</Col>
					<Col span={12} style={{ alignSelf: "center" }}>
						<Button
							size="large"
							type="primary"
							htmlType="button"
							icon={<PlusOutlined />}
							onClick={handleNewCustomerOpen}
						>
							New Customer
						</Button>
					</Col>
				</Row>
				{localStorage.getItem("role") === "Admin" && (
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								label="Seller"
								name="seller"
								rules={[
									{
										required: true,
										message: "Please select a seller",
									},
								]}
							>
								<Select
									style={{
										width: "100%",
									}}
									size="large"
									placeholder="Please select seller"
									options={optionsSellers}
									disabled={loadingComponents}
									loading={loadingSeller}
									optionFilterProp="children"
									filterOption={filterOption}
									showSearch
								/>
							</Form.Item>
						</Col>
					</Row>
				)}
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							label="Job Type"
							name="jobType"
							rules={[
								{
									required: true,
									message: "Please select a job type",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select job type"
								options={jobTypeOptions}
								disabled={loadingComponents}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Job Pour"
							name="jobPour"
							rules={[
								{
									required: true,
									message: "Please select a job pour",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select job pour"
								options={jobPourOptions}
								disabled={loadingComponents}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item label="Project (Optional)" name="project">
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select a project"
								options={optionsProjects}
								disabled={loadingComponents}
								loading={loadingProject}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
								onChange={onChangeProject}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							label="Psi"
							name="psi"
							rules={[
								{
									required: true,
									message: "Please select a psi",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select a psi"
								options={optionsPsi}
								disabled={loadingComponents}
								loading={loadingPsi}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
								labelInValue
								onChange={onChangePsi}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Plant"
							name="plant"
							rules={[
								{
									required: true,
									message: "Please select a plant",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select a plant"
								options={optionsPlant}
								disabled={loadingComponents}
								loading={loadingPlant}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
								onChange={onChangePlant}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Divider />
				<Title level={3}>Ship to Address</Title>
				<Row gutter={16}>
					<Col span={4} style={{ alignSelf: "center" }}>
						<Button
							size="large"
							type="primary"
							htmlType="button"
							icon={<SearchOutlined />}
							onClick={handleSearchAddressOpen}
							disabled={allowSearchAddress || disabledSearch}
						>
							Search Address
						</Button>
					</Col>
					<Col span={10}>
						<Form.Item
							label="Ship Address"
							name="shipAddress"
							rules={[
								{
									required: true,
									message: "Fill ship address",
								},
							]}
						>
							<Input size="large" disabled={disabledAddress} />
						</Form.Item>
					</Col>
					<Col span={10}>
						<Form.Item
							label="City"
							name="city"
							rules={[
								{
									required: true,
									message: "Please select a city",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select a city"
								options={cityList}
								disabled={loadingComponents}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
								onChange={onChangeCity}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Time to Destination" name="destinationTime">
							<Input size="large" disabled />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Distance" name="distance">
							<Input size="large" disabled />
						</Form.Item>
					</Col>
				</Row>
				<Divider />
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							label="Foreman"
							name="foremanItem"
							rules={[
								{
									required: true,
									message: "Please select a foreman",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select foreman"
								options={optionsForemans}
								disabled={loadingComponents}
								loading={loadingForeman}
								optionFilterProp="children"
								filterOption={filterOption}
								showSearch
								onChange={onChangeForeman}
							/>
						</Form.Item>
					</Col>
					<Col span={12} style={{ alignSelf: "center" }}>
						<Button
							size="large"
							type="primary"
							htmlType="button"
							icon={<PlusOutlined />}
							onClick={handleNewForemanOpen}
						>
							New Foreman
						</Button>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item label="Foreman Phone" name="foremanPhone">
							<Input size="large" addonBefore={prefixSelector} disabled />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item label="Supervisor Name" name="supervisorName">
							<Input size="large" disabled={loadingComponents} />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Supervisor Phone"
							name="supervisorPhone"
							rules={[
								{
									len: 10,
									max: 10,
									message: "Phone number must have 10 numbers",
								},
							]}
						>
							<Input
								size="large"
								addonBefore={prefixSelector}
								disabled={loadingComponents}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item label="Project Manager Name" name="projectManager">
							<Input size="large" disabled={loadingComponents} />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Project Manager Phone"
							name="pmPhone"
							rules={[
								{
									len: 10,
									max: 10,
									message: "Phone number must have 10 numbers",
								},
							]}
						>
							<Input
								size="large"
								addonBefore={prefixSelector}
								disabled={loadingComponents}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item label="AP Contact Name" name="apContact">
							<Input size="large" disabled={loadingComponents} />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="AP Contact Phone"
							name="apPhone"
							rules={[
								{
									len: 10,
									max: 10,
									message: "Phone number must have 10 numbers",
								},
							]}
						>
							<Input
								size="large"
								addonBefore={prefixSelector}
								disabled={loadingComponents}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item name="taxExemptJob" label="Tax Exempt Job">
							<Radio.Group
								size="large"
								disabled={loadingComponents}
								buttonStyle="solid"
							>
								<Radio.Button value={true}>Yes</Radio.Button>
								<Radio.Button value={false}>No</Radio.Button>
							</Radio.Group>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Payment Type"
							name="paymentType"
							rules={[
								{
									required: true,
									message: "Please select a payment type",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select payment type"
								options={paymentTypeOptions}
								disabled={loadingComponents || validPaymentType}
								optionFilterProp="children"
								onChange={onChangePaymentType}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={8}>
						<Form.Item
							label="Target YD3"
							name="targetYD3"
							rules={[
								{
									required: true,
									message: "Please fill target",
								},
							]}
						>
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								formatter={(value) =>
									`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
								disabled={loadingComponents}
							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label="Balance" name="balance">
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								formatter={(value) =>
									`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
								disabled={loadingComponents}
							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label="Job Duration" name="jobDuration">
							<Input size="large" disabled={loadingComponents} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={8}>
						<Form.Item name="scheduledDate" label="Job date/time">
							<DatePicker
								size="large"
								showTime={{ format: "HH:mm" }}
								format="MM/DD/YYYY HH:mm A"
								allowClear={false}
								onChange={onChangeDate}
								disabled={loadingComponents}
							/>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item name="inspectionTimeStamp" label="Inspection date/time">
							<DatePicker
								size="large"
								showTime={{ format: "HH:mm" }}
								format="MM/DD/YYYY HH:mm A"
								allowClear={false}
								maxDate={dateMax}
								disabledTime={disabledDateTime}
								disabled={loadingComponents}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item name="hotShot" valuePropName="checked">
							<Checkbox disabled={loadingComponents}>Hot Shot</Checkbox>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item label="Fiber Quantity" name="fiberQuantity">
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								formatter={(value) =>
									`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
								precision={3}
								disabled={loadingComponents}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							label="Unit Price"
							name="unitPrice"
							rules={[
								{
									required: true,
									message: "Please fill unit price",
								},
							]}
						>
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
								onChange={onChangeUnitPrice}
								precision={3}
								disabled={loadingComponents}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item label="Price with Tax" name="taxPrice">
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
								disabled
								precision={3}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={4}>
						<Form.Item name="fuelSurchargeGlobal" valuePropName="checked">
							<Checkbox disabled={loadingComponents}>Apply To Global</Checkbox>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label="Fuel Surcharge" name="fuelSurcharge">
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
								disabled={loadingComponents}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={4}>
						<Form.Item name="shippingGlobal" valuePropName="checked">
							<Checkbox disabled={loadingComponents}>Apply To Global</Checkbox>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item label="Shipping Fee" name="deliveryFee">
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
								disabled={loadingComponents}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item label="Comments" name="comments">
							<Input size="large" disabled={loadingComponents} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							label="Slump"
							name="slump"
							rules={[
								{
									required: true,
									message: "Please select a slump",
								},
							]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select slump"
								options={slumpOptions}
								optionFilterProp="children"
								disabled={loadingComponents}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item name="qualityControl" valuePropName="checked">
							<Checkbox disabled={loadingComponents}>QC Inspection</Checkbox>
						</Form.Item>
					</Col>
				</Row>
				<Button type="primary" htmlType="submit" loading={loadingComponents}>
					Create
				</Button>
			</Form>
		);
	};

	return (
		<Layout title="Job" description="Create New Job">
			<Flex vertical gap={"large"}>
				{contextHolder}
				<RenderForm />
				<RenderNewCustomerModal />
				<RenderSearchAddressModal />
				<RenderNewForemanModal />
			</Flex>
		</Layout>
	);
};

export default NewJob;

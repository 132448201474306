import React, { useEffect, useState } from "react";
import axios from "axios";
import { api } from "../utils/Api";
import {
	Typography,
	Flex,
	Button,
	notification,
	Row,
	Statistic,
	Col,
	Table,
} from "antd";
import Layout from "../layout/Layout";
import {
	CustomSearch,
	CustomSpinner,
	CustomTable,
} from "../utils/CustomComponents";
import { EditFilled, FilePdfFilled } from "@ant-design/icons";
import dayjs from "dayjs";

const { Text } = Typography;

const LoadsTimeSurge = ({ history }) => {
	const [loading, setLoading] = useState(false);
	const [disabledJobs, setDisabledJobs] = useState(true);
	const [loadList, setLoadList] = useState([]);
	const [startDate, setStartDate] = useState(dayjs());
	const [endDate, setEndDate] = useState(dayjs());
	const [job, setJob] = useState(undefined);
	const [jobOptions, setJobOptions] = useState([]);
	const [apiNotification, contextHolder] = notification.useNotification();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "Job ID",
			dataIndex: "jobId",
			key: "jobId",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Delivery Ticket",
			dataIndex: "deliveryTicket",
			key: "deliveryTicket",
			render: (value) => {
				return <Text strong>{value}</Text>;
			},
		},
		{
			title: "Arrive Date",
			dataIndex: "toArriveDate",
			key: "toArriveDate",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Finish Unload",
			dataIndex: "finishUnloadDate",
			key: "finishUnloadDate",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Estimated Time",
			dataIndex: "estimatedTime",
			key: "estimatedTime",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Difference Time",
			dataIndex: "differenceTime",
			key: "differenceTime",
			render: (value, values) => {
				return (
					<Text
						style={{ color: value <= values.estimatedTime ? "green" : "red" }}
					>
						{value}
					</Text>
				);
			},
		},
		{
			title: "Surge Time",
			dataIndex: "surgeTime",
			key: "surgeTime",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "",
			dataIndex: "edit",
			width: 100,
			render: (_, record) => {
				const hyperlink = `http://ec2-3-85-198-38.compute-1.amazonaws.com/${record.id}`;
				return (
					<Row gutter={16}>
						<Button
							type="primary"
							href={hyperlink}
							target="_blank"
							danger
							icon={<FilePdfFilled twoToneColor={"white"} />}
							// onClick={() => history.push(`/e-ticket/${record.id}`)}
						/>
					</Row>
				);
			},
		},
	];

	useEffect(() => {
		getJobs(startDate, endDate);
	}, [startDate, endDate]);

	const getJobs = async (startDate, endDate) => {
		try {
			setDisabledJobs(true);
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
			const start = `${startDate.year()}-${
				startDate.month() + 1
			}-${startDate.date()}`;
			const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`;
			const url = api + `/job/list/date?startDate=${start}&endDate=${end}`;

			const { data } = await axios.get(url);
			let list = [];

			if (data.success) {
				data.payload.map((item) => {
					list.push({
						value: item._id,
						label: item.number.toString(),
					});
				});
				setJobOptions(list);
				setLoadList([]);
				setJob(undefined);
				setDisabledJobs(false);
			} else {
				openNotification("error", "Error", data.message);
				setDisabledJobs(false);
			}
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const getLoanList = async (jobId) => {
		setLoading(true);
		try {
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
			const url = api + `/load/job/search/${jobId}`;
			const { data } = await axios.get(url);
			let list = [];

			if (data.success) {
				data.payload.map((item, index) => {
					let difference = 0;

					if (item.finishUnloadDate && item.toArriveDate) {
						const date1 = new Date(item.toArriveDate);
						const date2 = new Date(item.finishUnloadDate);

						const diffInMilliseconds = date2 - date1;

						difference = Math.round(diffInMilliseconds / (1000 * 60));
					}

					const estimatedTime = item.job.estimatedTime || 60;

					list.push({
						key: index,
						id: item._id,
						jobId: item.job.number,
						deliveryTicket: item.deliveryTicket,
						finishUnloadDate: item.finishUnloadDate
							? Intl.DateTimeFormat("en-US", {
									month: "numeric",
									year: "numeric",
									day: "numeric",
									hour: "2-digit",
									minute: "2-digit",
									second: "2-digit",
							  }).format(
									new Date(item.finishUnloadDate).setHours(
										new Date(item.finishUnloadDate).getHours() - 19
									)
							  )
							: "-",
						toArriveDate: item.toArriveDate
							? Intl.DateTimeFormat("en-US", {
									month: "numeric",
									year: "numeric",
									day: "numeric",
									hour: "2-digit",
									minute: "2-digit",
									second: "2-digit",
							  }).format(
									new Date(item.toArriveDate).setHours(
										new Date(item.toArriveDate).getHours() - 19
									)
							  )
							: "-",
						differenceTime: difference,
						estimatedTime: estimatedTime,
						surgeTime:
							difference <= estimatedTime ? 0 : estimatedTime - difference,
					});
				});
				setLoadList(list);
			} else {
				openNotification("error", "Error", data.message);
			}

			setLoading(false);
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const onRangeChange = (dates) => {
		if (dates) {
			setStartDate(dates[0]);
			setEndDate(dates[1]);
		}
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				<Table
					scroll={false}
					columns={columns}
					dataSource={loadList}
					pagination={{
						defaultPageSize: 100,
						showSizeChanger: true,
						pageSizeOptions: ["10", "30", "50", "100"],
					}}
					summary={(pageData) => {
						const total = Math.abs(
							pageData.reduce((accum, val) => {
								return accum + val.surgeTime;
							}, 0)
						);
						return (
							<Table.Summary fixed>
								<Table.Summary.Row>
									<Table.Summary.Cell colSpan={5} />
									<Table.Summary.Cell align="right">
										<Text style={{ fontSize: 14 }} strong>
											{"Total"}
										</Text>
									</Table.Summary.Cell>
									<Table.Summary.Cell>
										<Statistic
											value={total}
											suffix={"min"}
											precision={0}
											valueStyle={{ fontSize: 14 }}
										/>
									</Table.Summary.Cell>
								</Table.Summary.Row>
							</Table.Summary>
						);
					}}
				/>
			</Flex>
		);
	};

	const onChangeJob = (value) => {
		if (value) {
			setJob(value);
			getLoanList(value);
		}
	};

	const RenderSearch = () => {
		return (
			<CustomSearch
				loading={loading || disabledJobs}
				startDate={startDate}
				endDate={endDate}
				onRangeChange={onRangeChange}
				dateFormat={"MM/DD/YYYY"}
				labelPicker={"Job"}
				pickerWidth={"200px"}
				optionsPicker={jobOptions}
				onChangePicker={onChangeJob}
				pickerValue={job}
			/>
		);
	};

	return (
		<Layout title="Loads Time Surge Report" description="Report">
			{contextHolder}
			<Flex vertical gap={"large"}>
				<RenderSearch />
				{loading ? <CustomSpinner /> : <RenderTable />}
			</Flex>
		</Layout>
	);
};

export default LoadsTimeSurge;

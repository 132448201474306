import axios from "axios";
import React, { useEffect, useState } from "react";
import { api } from "../utils/Api";
import {
	Typography,
	Flex,
	Button,
	notification,
	Row,
	Col,
	Statistic,
} from "antd";
import Layout from "../layout/Layout";
import { CustomSpinner, CustomTable } from "../utils/CustomComponents";
import { EditFilled } from "@ant-design/icons";
const { Text } = Typography;

const MixDesignCalculatorList = ({ history }) => {
	const [loading, setLoading] = useState(true);
	const [mixDesignList, setMixDesignList] = useState([]);
	const [apiNotification, contextHolder] = notification.useNotification();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "",
			dataIndex: "edit",
			width: 100,
			render: (_, record) => {
				return (
					<Button
						type="primary"
						icon={<EditFilled twoToneColor={"white"} />}
						onClick={(e) => history.push(`/mixdesign/cost/${record.id}`)}
						size="large"
					/>
				);
			},
		},
		{
			title: "ID",
			dataIndex: "number",
			key: "number",
			render: (value) => {
				return <Text strong>{value}</Text>;
			},
		},
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Mix",
			dataIndex: "psi",
			key: "psi",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Mission TC",
			dataIndex: "missionTC",
			key: "missionTC",
			render: (value, values) => {
				const isMinValue = value === values.minValue;
				return (
					<Statistic
						valueStyle={{
							fontSize: 14,
							color: isMinValue ? "green" : "red",
						}}
						value={value}
						prefix={"$"}
						precision={4}
					/>
				);
			},
		},
		{
			title: "Mission STC",
			dataIndex: "missionSTC",
			key: "missionSTC",
			render: (value, values) => {
				const isMinValue = value === values.minValue;
				return (
					<Statistic
						valueStyle={{
							fontSize: 14,
							color: isMinValue ? "green" : "red",
						}}
						value={value}
						prefix={"$"}
						precision={4}
					/>
				);
			},
		},
		{
			title: "San Benito TC",
			dataIndex: "sanbenitoTC",
			key: "sanbenitoTC",
			render: (value, values) => {
				const isMinValue = value === values.minValue;
				return (
					<Statistic
						valueStyle={{
							fontSize: 14,
							color: isMinValue ? "green" : "red",
						}}
						value={value}
						prefix={"$"}
						precision={4}
					/>
				);
			},
		},
		{
			title: "San Benito STC",
			dataIndex: "sanbenitoSTC",
			key: "sanbenitoSTC",
			render: (value, values) => {
				const isMinValue = value === values.minValue;
				return (
					<Statistic
						valueStyle={{
							fontSize: 14,
							color: isMinValue ? "green" : "red",
						}}
						value={value}
						prefix={"$"}
						precision={4}
					/>
				);
			},
		},
		{
			title: "User",
			dataIndex: "user",
			key: "user",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
	];

	useEffect(() => {
		getMixDesignList();
	}, []);

	const getMixDesignList = async () => {
		setLoading(true);

		const url = api + "/mixdesigncost/list";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			if (data.success) {
				data.payload.map((item, index) => {
					const missionTC = item.missionTCQuantity.reduce(
						(accum, item) => accum + item.total,
						0
					);
					const missionSTC = item.missionSTCQuantity.reduce(
						(accum, item) => accum + item.total,
						0
					);
					const sanbenitoTC = item.sanBenitoTCQuantity.reduce(
						(accum, item) => accum + item.total,
						0
					);
					const sanbenitoSTC = item.sanBenitoSTCQuantity.reduce(
						(accum, item) => accum + item.total,
						0
					);

					const minValue = Math.min(
						missionTC,
						missionSTC,
						sanbenitoTC,
						sanbenitoSTC
					);

					list.push({
						key: index,
						id: item._id,
						number: item.number,
						date: Intl.DateTimeFormat("en-US").format(new Date(item.createdAt)),
						missionTC: missionTC,
						missionSTC: missionSTC,
						sanbenitoTC: sanbenitoTC,
						sanbenitoSTC: sanbenitoSTC,
						minValue: minValue,
						user: item.user ? item.user.name : "Not assigned",
						psi: item.psi ? item.psi.psi : "Not assigned",
					});
				});
				setMixDesignList(list);
			} else {
				openNotification("error", "Error", data.message);
			}

			setLoading(false);
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const onClickNewMixDesign = () => {
		history.push("/mixdesign/cost/new");
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				<Row gutter={[16, 8]}>
					<Col span={24} xs={12} md={8} xl={4} xxl={3}>
						<Button
							type="primary"
							style={{ width: "100%" }}
							onClick={onClickNewMixDesign}
							size="large"
							loading={loading}
						>
							New Mix Design Cost
						</Button>
					</Col>
				</Row>
				&nbsp;
				<CustomTable columns={columns} data={mixDesignList} />
			</Flex>
		);
	};

	return (
		<Layout title="Mix Design Cost Calculator" description="Calculator">
			{contextHolder}
			<Flex vertical gap={"large"}>
				{loading ? <CustomSpinner /> : <RenderTable />}
			</Flex>
		</Layout>
	);
};

export default MixDesignCalculatorList;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { api } from "../utils/Api";
import { Typography, Flex, Button, Checkbox, Tag } from "antd";
import Layout from "../layout/Layout";
import { CustomSpinner, CustomTable } from "../utils/CustomComponents";
import { EditFilled, PlusCircleFilled } from "@ant-design/icons";
const { Text } = Typography;

const ProviderList = ({ history }) => {
	const [loading, setLoading] = useState(true);
	const [providerList, setProviderList] = useState([]);

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "",
			dataIndex: "edit",
			width: 100,
			render: (_, record) => {
				return localStorage.getItem("email") === "eliud@gmail.com" ||
					localStorage.getItem("email") === "ec@57concrete.com" ||
					localStorage.getItem("email") === "bp@57concrete.com" ||
					localStorage.getItem("email") === "bpa@57concrete.com" ||
					localStorage.getItem("email") === "jp@57concrete.com" ||
					localStorage.getItem("email") === "fc@57concrete.com" ||
					localStorage.getItem("email") === "ji@57concrete.com" ||
					localStorage.getItem("role") === "AP" ||
					localStorage.getItem("manager") === true ||
					localStorage.getItem("director") === true ? (
					<Button
						type="primary"
						icon={<EditFilled twoToneColor={"white"} />}
						onClick={(e) => history.push(`/provider/${record.id}`)}
					/>
				) : (
					""
				);
			},
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Phone",
			dataIndex: "phone",
			key: "phone",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Sites",
			dataIndex: "sites",
			key: "sites",
			render: (_, { sites }) => (
				<>
					{sites.length > 0 ? (
						sites.map((site, key) => {
							return (
								<Tag color={"blue"} key={key}>
									{site.toUpperCase()}
								</Tag>
							);
						})
					) : (
						<Tag color={"default"}>{"None"}</Tag>
					)}
				</>
			),
		},
	];

	useEffect(() => {
		getProviderList();
	}, []);

	const getProviderList = async () => {
		setLoading(true);

		const url = api + "/providerlist";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			data.map((item, index) => {
				list.push({
					key: index,
					id: item._id,
					name: item.name,
					phone: item.phone,
					email: item.email,
					sites: item.sites,
				});
			});
			setProviderList(list);
			setLoading(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const addNewProvider = () => {
		history.push("/newprovider");
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				{(localStorage.getItem("email") === "eliud@gmail.com" ||
					localStorage.getItem("email") === "ec@57concrete.com" ||
					localStorage.getItem("email") === "bp@57concrete.com" ||
					localStorage.getItem("email") === "bpa@57concrete.com" ||
					localStorage.getItem("email") === "jp@57concrete.com" ||
					localStorage.getItem("email") === "fc@57concrete.com" ||
					localStorage.getItem("email") === "ji@57concrete.com" ||
					localStorage.getItem("role") === "AP" ||
					localStorage.getItem("manager") === true ||
					localStorage.getItem("director") === true) && (
					<Button
						onClick={addNewProvider}
						type="primary"
						style={{
							width: 150,
						}}
						icon={<PlusCircleFilled />}
					>
						New Provider
					</Button>
				)}
				<CustomTable columns={columns} data={providerList} />
			</Flex>
		);
	};

	return (
		<Layout title="Provider" description="Provider List">
			<Flex vertical gap={"large"}>
				{loading ? <CustomSpinner /> : <RenderTable />}
			</Flex>
		</Layout>
	);
};

export default ProviderList;

import React, { Component } from "react";
import Select from "react-select";
import axios from "axios";
import { api } from "../utils/Api";

export default class PsiPicker extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedOption: "",
			isClearable: true,
			isSearchable: true,
			psi: [],
		};
	}

	getPsi = async () => {
		const url = api + "/psi";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			this.setState({
				psi: data.filter((i) => i.active),
			});
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	componentDidMount() {
		this.getPsi();
	}

	render() {
		const options = this.state.psi.map((pressure) => {
			return {
				value: pressure.price,
				label: pressure.psi,
			};
		});

		return (
			<div>
				<Select
					name="form-field-name"
					placeholder="Psi..."
					value={this.state.value}
					onChange={this.props.action}
					isClearable={this.state.isClearable}
					isSearchable={this.state.isSearchable}
					options={options}
				/>
			</div>
		);
	}
}

import {
	Button,
	Col,
	Form,
	Input,
	InputNumber,
	Row,
	notification,
	Typography,
} from "antd";
import React, { useState } from "react";
import { api } from "../utils/Api";
import axios from "axios";
const { Title } = Typography;

const ModalCreateNewPsi = ({ pcb }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [apiNotification, contextHolder] = notification.useNotification();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	const createProduct = async (datas) => {
		setLoading(true);
		const product = {
			psi: datas.psi,
			description: datas.description,
			price: datas.price,
		};

		try {
			let url = api + "/psi";
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

			const { data } = await axios.post(url, product);
			openNotification("success", "Success", "Product created");
			pcb(data._id);
		} catch (err) {
			setLoading(false);
			openNotification(
				"error",
				"Error",
				"Error while trying to create product"
			);
			console.error(err.message);
			return null;
		}
	};

	const onFinish = (values) => {
		createProduct(values);
	};

	const CustomForm = () => {
		return (
			<Form
				form={form}
				layout="vertical"
				onFinish={onFinish}
				initialValues={{ price: 0 }}
			>
				<Row gutter={16}>
					<Col span={24}>
						<Title level={3}>Product Details</Title>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Product"
							name="psi"
							rules={[
								{
									required: true,
									message: "Please fill psi",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							label="Description"
							name="description"
							rules={[
								{
									required: true,
									message: "Please fill description",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Price"
							name="price"
							rules={[
								{
									required: true,
									message: "Please fill price",
								},
							]}
						>
							<InputNumber
								size="large"
								style={{ width: "100%" }}
								formatter={(value) =>
									`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Button type="primary" htmlType="submit" loading={loading}>
					Create
				</Button>
			</Form>
		);
	};

	return (
		<>
			{contextHolder}
			<CustomForm />
		</>
	);
};

export default ModalCreateNewPsi;

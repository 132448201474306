import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { api } from "../utils/Api";
import {
	Typography,
	Flex,
	Table,
	Statistic,
	Row,
	Col,
	Button,
	notification,
	Select,
	DatePicker,
} from "antd";
import Layout from "../layout/Layout";
import { CustomSpinner } from "../utils/CustomComponents";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
// import { SearchOutlined } from "@ant-design/icons";
const { Text } = Typography;
const { RangePicker } = DatePicker;

const columnsCSV = [
	{ key: "owner", label: "Owner" },
	{ key: "project", label: "Project" },
	{ key: "lender", label: "Lender" },
	{ key: "paymentDate", label: "Payment Date" },
	{ key: "begginingBalance", label: "Beggining Balance" },
	{ key: "payment", label: "Payment" },
	{ key: "principal", label: "Principal" },
	{ key: "interest", label: "Interest" },
	{ key: "endingBalance", label: "Ending Balance" },
];

const LoanEndingBalanceReport = () => {
	const [loading, setLoading] = useState(true);
	const [startDate, setStartDate] = useState(dayjs());
	const [endDate, setEndDate] = useState(dayjs());
	const [list, setList] = useState([]);
	const [ownerList, setOnwerList] = useState([]);
	const [projectList, setProjectList] = useState([]);
	const [projects, setProjects] = useState([]);
	const [owner, setOwner] = useState("All");
	const refDownload = useRef();
	const [apiNotification, contextHolder] = notification.useNotification();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "Owner/Lessor",
			dataIndex: "owner",
			key: "owner",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Project",
			dataIndex: "project",
			key: "project",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Lender",
			dataIndex: "lender",
			key: "lender",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Collateral",
			dataIndex: "collateral",
			key: "collateral",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Payment Date",
			dataIndex: "paymentDate",
			key: "paymentDate",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Beggining Balance",
			dataIndex: "begginingBalance",
			key: "begginingBalance",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Payment",
			dataIndex: "payment",
			key: "payment",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Principal",
			dataIndex: "principal",
			key: "principal",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Interest",
			dataIndex: "interest",
			key: "interest",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Ending Balance",
			dataIndex: "endingBalance",
			key: "endingBalance",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
	];

	useEffect(() => {
		getOwners();
	}, []);

	useEffect(() => {
		if (owner !== "All") {
			getOwnerProjects();
		}
	}, [owner]);

	useEffect(() => {
		getPayments();
	}, [startDate, endDate, owner, projects]);

	const getOwners = async () => {
		const url = api + `/loan/get/list/owner`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [
				{
					value: "All",
					label: "All",
				},
			];
			if (data.success) {
				data.payload.map((owner) => {
					list.push({
						value: owner,
						label: owner,
					});
				});
				setOnwerList(list);
			} else {
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			openNotification("error", "Error", err.message);
			console.error(err.message);
			return null;
		}
	};

	const getOwnerProjects = async () => {
		const url = api + `/loan/get/list/owner/projects/${owner}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			if (data.success) {
				data.payload.map((project) => {
					list.push({
						value: project,
						label: project,
					});
				});
				setProjectList(list);
			} else {
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			openNotification("error", "Error", err.message);
			console.error(err.message);
			return null;
		}
	};

	const getPayments = async () => {
		setLoading(true);

		const start = `${startDate.year()}-${
			startDate.month() + 1
		}-${startDate.date()}`;
		const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`;

		const url =
			api + `/loan/get/payments/filtered2?startDate=${start}&endDate=${end}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.post(url, { owner, projects });
			let list = [];

			if (data.success) {
				data.payload.map((item) => {
					item.payments.map((payment) => {
						list.push({
							index: payment._id,
							key: payment._id,
							owner: item.owner,
							project: item.project,
							lender: item.lender,
							collateral: item.collateral,
							paymentDate: Intl.DateTimeFormat("en-US").format(
								new Date(payment.paymentDate)
							),
							begginingBalance: payment.begginingBalance,
							endingBalance: payment.endingBalance,
							interest: payment.interest,
							payment: payment.payment,
							principal: payment.principal,
						});
					});
				});
				list = list.sort((a, b) => new Date(a.date) - new Date(b.date));
				setList(list);
				setLoading(false);
			} else {
				setLoading(false);
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			openNotification("error", "Error", err.message);
			console.error(err.message);
			return null;
		}
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				<Table
					columns={columns}
					dataSource={list}
					pagination={{
						position: ["bottomRight"],
						pageSizeOptions: [10, 20, 50, 100],
						defaultPageSize: 100,
						showSizeChanger: true,
					}}
					scroll={{
						x: true,
					}}
					style={{
						minWidth: "100%",
					}}
					// summary={(pageData) => {
					// 	const total = pageData.reduce((accum, val) => {
					// 		return accum + val.endingBalance;
					// 	}, 0);
					// 	return (
					// 		<Table.Summary fixed>
					// 			<Table.Summary.Row>
					// 				<Table.Summary.Cell colSpan={8}></Table.Summary.Cell>
					// 				<Table.Summary.Cell align="right">
					// 					<Text style={{ fontSize: 14 }} strong>
					// 						{"Total"}
					// 					</Text>
					// 				</Table.Summary.Cell>
					// 				<Table.Summary.Cell>
					// 					<Statistic
					// 						value={total}
					// 						prefix={"$"}
					// 						precision={2}
					// 						valueStyle={{ fontSize: 14 }}
					// 					/>
					// 				</Table.Summary.Cell>
					// 			</Table.Summary.Row>
					// 		</Table.Summary>
					// 	);
					// }}
				/>
			</Flex>
		);
	};

	const onRangeChange = (dates) => {
		if (dates) {
			setStartDate(dates[0]);
			setEndDate(dates[1]);
		}
	};

	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const RenderDates = () => {
		return (
			<Row gutter={16}>
				<Col span={6}>
					<RangePicker
						defaultValue={[
							dayjs(startDate, "MM/DD/YYYY"),
							dayjs(endDate, "MM/DD/YYYY"),
						]}
						onChange={onRangeChange}
						format={"MM/DD/YYYY"}
						size="large"
						disabled={loading}
						allowClear={false}
						style={{ width: "100%" }}
					/>
				</Col>
				<Col span={6}>
					<Select
						showSearch
						placeholder={`Select Owner`}
						optionFilterProp="children"
						size="large"
						onChange={(value) => {
							setOwner(value);
							setProjectList([]);
							setProjects([]);
						}}
						filterOption={filterOption}
						options={ownerList}
						disabled={loading}
						value={owner}
						style={{
							width: "100%",
						}}
					/>
				</Col>
				<Col span={6}>
					<Select
						showSearch
						placeholder={`Select projects`}
						optionFilterProp="children"
						size="large"
						onChange={setProjects}
						filterOption={filterOption}
						options={projectList}
						disabled={loading || owner === "All"}
						value={projects}
						mode={"multiple"}
						style={{
							width: "100%",
						}}
					/>
				</Col>
			</Row>
		);
	};

	const RenderDownloadReport = () => {
		return (
			<div>
				<CSVLink
					data={list}
					headers={columnsCSV}
					filename={"57concrete-loan-ending-balance.csv"}
					hidden
					ref={refDownload}
				>
					Download report
				</CSVLink>
				<Row gutter={16}>
					<Col span={24}>
						<Button
							type="primary"
							style={{ width: "100%" }}
							onClick={(e) => refDownload.current.link.click()}
							loading={loading}
							size="large"
						>
							Download Report
						</Button>
					</Col>
				</Row>
			</div>
		);
	};

	return (
		<Layout
			title="Loan Ending Balance Report"
			description="Loan Report"
			type="large"
		>
			<Flex vertical gap={"large"}>
				<RenderDates />
				<RenderDownloadReport />
				{loading ? <CustomSpinner /> : <RenderTable />}
				{contextHolder}
			</Flex>
		</Layout>
	);
};

export default LoanEndingBalanceReport;

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { api } from "../utils/Api";
import {
	Typography,
	Flex,
	DatePicker,
	Table,
	Button,
	Modal,
	Image,
	Statistic,
	Row,
	Col,
	Select,
	notification,
} from "antd";
import Layout from "../layout/Layout";
import { CustomSearch, CustomSpinner } from "../utils/CustomComponents";
import dayjs from "dayjs";
import {
	FileImageOutlined,
	CheckCircleOutlined,
	FilePdfOutlined,
	DeleteFilled,
	EditFilled,
} from "@ant-design/icons";
import { CSVLink } from "react-csv";
const { Text, Title } = Typography;

const jobStatusOptions = [
	{
		value: "Pouring",
		label: "Pouring",
	},
	{
		value: "A/R",
		label: "A/R",
	},
];

const mineOptions = [
	{
		value: "All",
		label: "All",
	},
	{
		value: "Mile 4",
		label: "Mile 4",
	},
	{
		value: "Texan",
		label: "Texan",
	},
];

const WebLogisticsDashboard = ({ history }) => {
	const [loading, setLoading] = useState(true);
	const [loadingTable, setLoadingTable] = useState(false);
	const [loadingButton, setLoadingButton] = useState(false);
	const [sandList, setSandList] = useState([]);
	const [gravelList, setGravelList] = useState([]);
	const [peaGravelList, setPeaGravelList] = useState([]);
	const [date, setDate] = useState(dayjs());
	const [evidence, setEvidence] = useState(null);
	const [openEvidenceModal, setOpenEvidenceModal] = useState(false);
	const [detailedData, setDetailedData] = useState([]);
	const [plants, setPlants] = useState([]);
	const [dataExport, setDataExport] = useState([]);
	const [plant, setPlant] = useState("All");
	const [mine, setMine] = useState("All");
	const refDownload = useRef();
	const [apiNotification, contextHolder] = notification.useNotification();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "Mine",
			dataIndex: "mine",
			key: "mine",
			render: (value) => {
				return <Text strong>{value}</Text>;
			},
		},
		{
			title: "Truck",
			dataIndex: "truck",
			key: "truck",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Driver",
			dataIndex: "driver",
			key: "driver",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Quantity (ton)",
			dataIndex: "quantity",
			key: "quantity",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Ticket",
			dataIndex: "ticket",
			key: "ticket",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Material",
			dataIndex: "material",
			key: "material",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Plant",
			dataIndex: "plant",
			key: "plant",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Started Date",
			dataIndex: "startedDate",
			key: "startedDate",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Received Date",
			dataIndex: "receivedDate",
			key: "receivedDate",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Duration",
			dataIndex: "duration",
			key: "duration",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "",
			dataIndex: "actions",
			width: 100,
			render: (_, record) => {
				return (
					<Flex gap={"small"}>
						{!record.received && (
							<Button
								type="primary"
								style={{ backgroundColor: "green" }}
								icon={<CheckCircleOutlined twoToneColor={"white"} />}
								onClick={(e) => updateRecord(record.id)}
								loading={loadingButton}
								disabled={loadingButton}
							>
								Received
							</Button>
						)}

						{record.evidence && (
							<Button
								type="primary"
								icon={<FileImageOutlined twoToneColor={"white"} />}
								onClick={(e) => handleEvidenceOpen(record.evidence)}
								loading={loadingButton}
								disabled={loadingButton}
							>
								Evidence
							</Button>
						)}
						{record.receivedDate !== "-" && (
							<Button
								type="primary"
								style={{ backgroundColor: "red" }}
								icon={<FilePdfOutlined twoToneColor={"white"} />}
								onClick={(e) => history.push(`/logisticspdf/${record.id}`)}
								loading={loadingButton}
								disabled={loadingButton}
							>
								PDF
							</Button>
						)}
						{(localStorage.getItem("email") === "eliud@gmail.com" ||
							localStorage.getItem("email") === "ec@57concrete.com" ||
							localStorage.getItem("email") === "jip@57concrete.com" ||
							localStorage.getItem("email") === "ac@57concrete.com" ||
							localStorage.getItem("email") === "vm@57concrete.com") && (
							<Button
								type="primary"
								style={{ backgroundColor: "red" }}
								icon={<DeleteFilled twoToneColor={"white"} />}
								onClick={(e) => deleteRecord(record.id)}
								loading={loadingButton}
								disabled={loadingButton}
							>
								Delete
							</Button>
						)}
						{(localStorage.getItem("email") === "eliud@gmail.com" ||
							localStorage.getItem("email") === "ec@57concrete.com" ||
							localStorage.getItem("email") === "vm@57concrete.com" ||
							localStorage.getItem("email") === "ac@57concrete.com" ||
							(localStorage.getItem("email") === "jip@57concrete.com" &&
								record.plant === "San Benito")) && (
							<Button
								type="primary"
								// style={{backgroundColor: 'blue'}}
								icon={<EditFilled twoToneColor={"white"} />}
								onClick={(e) => history.push(`/logisticstrip/${record.id}`)}
								loading={loadingButton}
								disabled={loadingButton}
							>
								Edit
							</Button>
						)}
					</Flex>
				);
			},
		},
	];

	const columnsCSV = [
		{
			label: "ID",
			key: "id",
		},
		{
			label: "Quantity",
			key: "quantity",
		},
		{
			label: "Material",
			key: "material",
		},
		{
			label: "Plant",
			key: "plant",
		},
		{
			label: "Mine",
			key: "mine",
		},
		{
			label: "Status",
			key: "status",
		},
	];

	useEffect(() => {
		// let dateString = `${date.year()}-${date.month() + 1}-${date.date()}`;
		// getTripList(dateString);
		getPlants();
	}, []);

	const getPlants = async () => {
		setLoading(true);
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const url = api + `/plant/list/options`;

		try {
			const { data } = await axios.get(url);
			let list = [
				{
					value: "All",
					label: "All",
				},
			];

			if (data.success) {
				data.payload.map((item) => {
					list.push({
						value: item._id,
						label: item.name,
					});
				});
				setPlants(list);
				let dateString = `${date.year()}-${date.month() + 1}-${date.date()}`;
				getTripList(dateString, plant, mine);
			} else {
				openNotification("error", "Error", data.message);
				setLoading(false);
			}
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const getTripList = async (date, plant, mine) => {
		setLoading(true);
		const url =
			api + `/logisticstrip/list?date=${date}&plant=${plant}&mine=${mine}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);

			let list = [];
			data.map((item, index) => {
				let duration = "0";
				if (item.startedDate && item.receivedDate) {
					var diffMs = new Date(item.receivedDate) - new Date(item.startedDate);
					var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
					var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
					duration = `${diffHrs} hours, ${diffMins} minutes`;
				}

				list.push({
					key: index,
					id: item._id,
					mine: item.mine,
					material: item.material,
					quantity: item.quantity,
					ticket: item.ticket,
					plant: item.plant.name,
					driver: item.driver.name,
					truck: item.truck.number,
					evidence: item.evidence || null,
					startedDate: item.startedDate
						? `${dayjs(item.startedDate)
								.toDate()
								.toLocaleDateString()} - ${dayjs(item.startedDate)
								.toDate()
								.toLocaleTimeString([], { timeStyle: "short" })}`
						: "-",
					receivedDate: item.receivedDate
						? `${dayjs(item.receivedDate)
								.toDate()
								.toLocaleDateString()} - ${dayjs(item.receivedDate)
								.toDate()
								.toLocaleTimeString([], { timeStyle: "short" })}`
						: "-",
					duration: duration,
					received: item.received,
					jobStatus: item.jobStatus || "Pouring",
				});
			});

			let dupes = {};
			list.forEach((item, index) => {
				dupes[item.ticket] = dupes[item.ticket] || [];
				dupes[item.ticket].push(index);
			});

			Object.entries(dupes).forEach((item, index) => {
				if (item[1].length > 1) {
					item[1].map((val) => (list[val].duplicated = true));
				}
			});

			// const accumByDate = list.reduce((accumulator, currentObject) => {
			// 	const cDate = dayjs(currentObject.createdAt);

			// 	const formattedDate = `${cDate.get("M") + 1}${cDate.get(
			// 		"date"
			// 	)}${cDate.get("y")}`;

			// 	let material = "";
			// 	switch (currentObject.material) {
			// 		case "Sand":
			// 			material = "S";
			// 			break;
			// 		case "Gravel":
			// 			material = "G";

			// 			break;
			// 		case "Pea Gravel":
			// 			material = "PG";

			// 			break;

			// 		default:
			// 			break;
			// 	}

			// 	const plant = currentObject.plant === "Mission" ? "M" : "SB";

			// 	const id = `${formattedDate}-${material}-${plant}`;

			// 	if (!accumulator[id]) {
			// 		accumulator[id] = [];
			// 	}

			// 	accumulator[id].push(currentObject);

			// 	return accumulator;
			// }, {});

			// const newListGrouped = [];

			// Object.keys(accumByDate).map((id) => {
			// 	newListGrouped.push({
			// 		key: id,
			// 		id: id,
			// 		quantity: accumByDate[id].reduce(
			// 			(accum, current) => accum + current.quantity,
			// 			0
			// 		),
			// 		material: accumByDate[id][0].material,
			// 		plant: accumByDate[id][0].plant,
			// 		status: accumByDate[id][0].status,
			// 	});
			// });

			// console.log(newListGrouped);

			let sandList1 = list.filter(
				(trip) =>
					trip.material === "Sand" &&
					trip.plant === "Mission" &&
					trip.mine === "Mile 4"
			);
			let sandList11 = list.filter(
				(trip) =>
					trip.material === "Sand" &&
					trip.plant === "Mission" &&
					trip.mine === "Texan"
			);
			let sandList2 = list.filter(
				(trip) =>
					trip.material === "Sand" &&
					trip.plant === "San Benito" &&
					trip.mine === "Mile 4"
			);
			let sandList22 = list.filter(
				(trip) =>
					trip.material === "Sand" &&
					trip.plant === "San Benito" &&
					trip.mine === "Texan"
			);
			let sandList3 = list.filter(
				(trip) =>
					trip.material === "Sand" &&
					trip.plant === "Laredo" &&
					trip.mine === "Mile 4"
			);
			let sandList33 = list.filter(
				(trip) =>
					trip.material === "Sand" &&
					trip.plant === "Laredo" &&
					trip.mine === "Texan"
			);

			let gravelList1 = list.filter(
				(trip) =>
					trip.material === "Gravel" &&
					trip.plant === "Mission" &&
					trip.mine === "Mile 4"
			);
			let gravelList11 = list.filter(
				(trip) =>
					trip.material === "Gravel" &&
					trip.plant === "Mission" &&
					trip.mine === "Texan"
			);
			let gravelList2 = list.filter(
				(trip) =>
					trip.material === "Gravel" &&
					trip.plant === "San Benito" &&
					trip.mine === "Mile 4"
			);
			let gravelList22 = list.filter(
				(trip) =>
					trip.material === "Gravel" &&
					trip.plant === "San Benito" &&
					trip.mine === "Texan"
			);
			let gravelList3 = list.filter(
				(trip) =>
					trip.material === "Gravel" &&
					trip.plant === "Laredo" &&
					trip.mine === "Mile 4"
			);
			let gravelList33 = list.filter(
				(trip) =>
					trip.material === "Gravel" &&
					trip.plant === "Laredo" &&
					trip.mine === "Texan"
			);

			let peaGravelList1 = list.filter(
				(trip) =>
					trip.material === "Pea Gravel" &&
					trip.plant === "Mission" &&
					trip.mine === "Mile 4"
			);
			let peaGravelList11 = list.filter(
				(trip) =>
					trip.material === "Pea Gravel" &&
					trip.plant === "Mission" &&
					trip.mine === "Texan"
			);
			let peaGravelList2 = list.filter(
				(trip) =>
					trip.material === "Pea Gravel" &&
					trip.plant === "San Benito" &&
					trip.mine === "Mile 4"
			);
			let peaGravelList22 = list.filter(
				(trip) =>
					trip.material === "Pea Gravel" &&
					trip.plant === "San Benito" &&
					trip.mine === "Texan"
			);
			let peaGravelList3 = list.filter(
				(trip) =>
					trip.material === "Pea Gravel" &&
					trip.plant === "Laredo" &&
					trip.mine === "Mile 4"
			);
			let peaGravelList33 = list.filter(
				(trip) =>
					trip.material === "Pea Gravel" &&
					trip.plant === "Laredo" &&
					trip.mine === "Texan"
			);

			const cDate = dayjs(date);

			const formattedDate = `${cDate.get("M") + 1}/${cDate.get(
				"date"
			)}/${cDate.get("y")}`;

			let detailedRowsSand = [];
			if (sandList1.length > 0) {
				let sumQuantity = 0;

				sandList1.map((item) => {
					sumQuantity += item.quantity;
				});

				detailedRowsSand.push({
					id: `${formattedDate.replaceAll("/", "")}-S-M-M`,
					quantity: sumQuantity,
					material: "Sand",
					status: sandList1.length > 0 ? sandList1[0].jobStatus : "Pouring",
					plant: "Mission",
					mine: "Mile 4",
				});
			}
			if (sandList11.length > 0) {
				let sumQuantity = 0;

				sandList11.map((item) => {
					sumQuantity += item.quantity;
				});

				detailedRowsSand.push({
					id: `${formattedDate.replaceAll("/", "")}-S-M-T`,
					quantity: sumQuantity,
					material: "Sand",
					status: sandList11.length > 0 ? sandList11[0].jobStatus : "Pouring",
					plant: "Mission",
					mine: "Texan",
				});
			}
			if (sandList2.length > 0) {
				let sumQuantity = 0;

				sandList2.map((item) => {
					sumQuantity += item.quantity;
				});

				detailedRowsSand.push({
					id: `${formattedDate.replaceAll("/", "")}-S-SB-M`,
					quantity: sumQuantity,
					material: "Sand",
					status: sandList2.length > 0 ? sandList2[0].jobStatus : "Pouring",
					plant: "San Benito",
					mine: "Mile 4",
				});
			}
			if (sandList22.length > 0) {
				let sumQuantity = 0;

				sandList22.map((item) => {
					sumQuantity += item.quantity;
				});

				detailedRowsSand.push({
					id: `${formattedDate.replaceAll("/", "")}-S-SB-T`,
					quantity: sumQuantity,
					material: "Sand",
					status: sandList22.length > 0 ? sandList22[0].jobStatus : "Pouring",
					plant: "San Benito",
					mine: "Texan",
				});
			}
			if (sandList3.length > 0) {
				let sumQuantity = 0;

				sandList3.map((item) => {
					sumQuantity += item.quantity;
				});

				detailedRowsSand.push({
					id: `${formattedDate.replaceAll("/", "")}-S-L-M`,
					quantity: sumQuantity,
					material: "Sand",
					status: sandList3.length > 0 ? sandList3[0].jobStatus : "Pouring",
					plant: "Laredo",
					mine: "Mile 4",
				});
			}
			if (sandList33.length > 0) {
				let sumQuantity = 0;

				sandList33.map((item) => {
					sumQuantity += item.quantity;
				});

				detailedRowsSand.push({
					id: `${formattedDate.replaceAll("/", "")}-S-L-T`,
					quantity: sumQuantity,
					material: "Sand",
					status: sandList33.length > 0 ? sandList33[0].jobStatus : "Pouring",
					plant: "Laredo",
					mine: "Texan",
				});
			}

			let detailedRowsGravel = [];
			if (gravelList1.length > 0) {
				let sumQuantity = 0;

				gravelList1.map((item) => {
					sumQuantity += item.quantity;
				});

				detailedRowsGravel.push({
					id: `${formattedDate.replaceAll("/", "")}-G-M-M`,
					quantity: sumQuantity,
					material: "Gravel",
					status: gravelList1.length > 0 ? gravelList1[0].jobStatus : "Pouring",
					plant: "Mission",
					mine: "Mile 4",
				});
			}
			if (gravelList11.length > 0) {
				let sumQuantity = 0;

				gravelList11.map((item) => {
					sumQuantity += item.quantity;
				});

				detailedRowsGravel.push({
					id: `${formattedDate.replaceAll("/", "")}-G-M-T`,
					quantity: sumQuantity,
					material: "Gravel",
					status:
						gravelList11.length > 0 ? gravelList11[0].jobStatus : "Pouring",
					plant: "Mission",
					mine: "Texan",
				});
			}
			if (gravelList2.length > 0) {
				let sumQuantity = 0;

				gravelList2.map((item) => {
					sumQuantity += item.quantity;
				});

				detailedRowsGravel.push({
					id: `${formattedDate.replaceAll("/", "")}-G-SB-M`,
					quantity: sumQuantity,
					material: "Gravel",
					status: gravelList2.length > 0 ? gravelList2[0].jobStatus : "Pouring",
					plant: "San Benito",
					mine: "Mile 4",
				});
			}
			if (gravelList22.length > 0) {
				let sumQuantity = 0;

				gravelList22.map((item) => {
					sumQuantity += item.quantity;
				});

				detailedRowsGravel.push({
					id: `${formattedDate.replaceAll("/", "")}-G-SB-T`,
					quantity: sumQuantity,
					material: "Gravel",
					status:
						gravelList22.length > 0 ? gravelList22[0].jobStatus : "Pouring",
					plant: "San Benito",
					mine: "Texan",
				});
			}
			if (gravelList3.length > 0) {
				let sumQuantity = 0;

				gravelList3.map((item) => {
					sumQuantity += item.quantity;
				});

				detailedRowsGravel.push({
					id: `${formattedDate.replaceAll("/", "")}-G-L-M`,
					quantity: sumQuantity,
					material: "Gravel",
					status: gravelList3.length > 0 ? gravelList3[0].jobStatus : "Pouring",
					plant: "Laredo",
					mine: "Mile 4",
				});
			}
			if (gravelList33.length > 0) {
				let sumQuantity = 0;

				gravelList33.map((item) => {
					sumQuantity += item.quantity;
				});

				detailedRowsGravel.push({
					id: `${formattedDate.replaceAll("/", "")}-G-L-T`,
					quantity: sumQuantity,
					material: "Gravel",
					status:
						gravelList33.length > 0 ? gravelList33[0].jobStatus : "Pouring",
					plant: "Laredo",
					mine: "Texan",
				});
			}

			let detailedRowsPeaGravel = [];
			if (peaGravelList1.length > 0) {
				let sumQuantity = 0;

				peaGravelList1.map((item) => {
					sumQuantity += item.quantity;
				});

				detailedRowsPeaGravel.push({
					id: `${formattedDate.replaceAll("/", "")}-PG-M-M`,
					quantity: sumQuantity,
					material: "Pea Gravel",
					status:
						peaGravelList1.length > 0 ? peaGravelList1[0].jobStatus : "Pouring",
					plant: "Mission",
					mine: "Mile 4",
				});
			}
			if (peaGravelList11.length > 0) {
				let sumQuantity = 0;

				peaGravelList11.map((item) => {
					sumQuantity += item.quantity;
				});

				detailedRowsPeaGravel.push({
					id: `${formattedDate.replaceAll("/", "")}-PG-M-T`,
					quantity: sumQuantity,
					material: "Pea Gravel",
					status:
						peaGravelList11.length > 0
							? peaGravelList11[0].jobStatus
							: "Pouring",
					plant: "Mission",
					mine: "Texan",
				});
			}
			if (peaGravelList2.length > 0) {
				let sumQuantity = 0;

				peaGravelList2.map((item) => {
					sumQuantity += item.quantity;
				});

				detailedRowsPeaGravel.push({
					id: `${formattedDate.replaceAll("/", "")}-PG-SB-M`,
					quantity: sumQuantity,
					material: "Pea Gravel",
					status:
						peaGravelList2.length > 0 ? peaGravelList2[0].jobStatus : "Pouring",
					plant: "San Benito",
					mine: "Mile 4",
				});
			}
			if (peaGravelList22.length > 0) {
				let sumQuantity = 0;

				peaGravelList22.map((item) => {
					sumQuantity += item.quantity;
				});

				detailedRowsPeaGravel.push({
					id: `${formattedDate.replaceAll("/", "")}-PG-SB-T`,
					quantity: sumQuantity,
					material: "Pea Gravel",
					status:
						peaGravelList22.length > 0
							? peaGravelList22[0].jobStatus
							: "Pouring",
					plant: "San Benito",
					mine: "Texan",
				});
			}
			if (peaGravelList3.length > 0) {
				let sumQuantity = 0;

				peaGravelList3.map((item) => {
					sumQuantity += item.quantity;
				});

				detailedRowsPeaGravel.push({
					id: `${formattedDate.replaceAll("/", "")}-PG-L-M`,
					quantity: sumQuantity,
					material: "Pea Gravel",
					status:
						peaGravelList3.length > 0 ? peaGravelList3[0].jobStatus : "Pouring",
					plant: "Laredo",
					mine: "Mile 4",
				});
			}
			if (peaGravelList33.length > 0) {
				let sumQuantity = 0;

				peaGravelList33.map((item) => {
					sumQuantity += item.quantity;
				});

				detailedRowsPeaGravel.push({
					id: `${formattedDate.replaceAll("/", "")}-PG-L-T`,
					quantity: sumQuantity,
					material: "Pea Gravel",
					status:
						peaGravelList33.length > 0
							? peaGravelList33[0].jobStatus
							: "Pouring",
					plant: "Laredo",
					mine: "Texan",
				});
			}

			// console.log(detailedRowsSand);

			// let detailed = [
			// 	{
			// 		id: formattedDate.replaceAll("/", "") + "S",
			// 		quantity: sandList.reduce(
			// 			(accum, current) => accum + current.quantity,
			// 			0
			// 		),
			// 		material: "Sand",
			// 		status: sandList.length > 0 ? sandList[0].jobStatus : "Pouring",
			// 		plant: sandList.length > 0 ? sandList[0].plant : "None",
			// 	},
			// 	{
			// 		id: formattedDate.replaceAll("/", "") + "G",
			// 		quantity: gravelList.reduce(
			// 			(accum, current) => accum + current.quantity,
			// 			0
			// 		),
			// 		material: "Gravel",
			// 		status: gravelList.length > 0 ? gravelList[0].jobStatus : "Pouring",
			// 		plant: gravelList.length > 0 ? gravelList[0].plant : "None",
			// 	},
			// 	{
			// 		id: formattedDate.replaceAll("/", "") + "PG",
			// 		quantity: peaGravelList.reduce(
			// 			(accum, current) => accum + current.quantity,
			// 			0
			// 		),
			// 		material: "Pea Gravel",
			// 		status:
			// 			peaGravelList.length > 0 ? peaGravelList[0].jobStatus : "Pouring",
			// 		plant: peaGravelList.length > 0 ? peaGravelList[0].plant : "None",
			// 	},
			// ];

			// setDetailedData(detailed);
			setDetailedData([
				detailedRowsSand,
				detailedRowsGravel,
				detailedRowsPeaGravel,
			]);

			setDataExport([
				...detailedRowsSand,
				...detailedRowsGravel,
				...detailedRowsPeaGravel,
			]);

			setSandList([
				...sandList1,
				...sandList11,
				...sandList2,
				...sandList22,
				...sandList3,
				...sandList33,
			]);
			setGravelList([
				...gravelList1,
				...gravelList11,
				...gravelList2,
				...gravelList22,
				...gravelList3,
				...gravelList33,
			]);
			setPeaGravelList([
				...peaGravelList1,
				...peaGravelList11,
				...peaGravelList2,
				...peaGravelList22,
				...peaGravelList3,
				...peaGravelList33,
			]);
			setLoading(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const updateRecord = async (id) => {
		setLoadingTable(true);
		setLoadingButton(true);
		const url = api + `/logisticstrip/update/jap/${id}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			let body = {
				received: true,
			};
			const { data } = await axios.patch(url, body);
			let index = -1;
			if (data.material === "Sand") {
				index = sandList.findIndex((item) => item.id === id);
				sandList[index].received = true;
				sandList[index].receivedDate = `${dayjs(data.receivedDate)
					.toDate()
					.toLocaleDateString()} - ${dayjs(data.receivedDate)
					.toDate()
					.toLocaleTimeString([], { timeStyle: "short" })}`;
				setSandList(sandList);
			} else if (data.material === "Gravel") {
				index = gravelList.findIndex((item) => item.id === id);
				gravelList[index].received = true;
				gravelList[index].receivedDate = `${dayjs(data.receivedDate)
					.toDate()
					.toLocaleDateString()} - ${dayjs(data.receivedDate)
					.toDate()
					.toLocaleTimeString([], { timeStyle: "short" })}`;
				setGravelList(gravelList);
			} else if (data.material === "Pea Gravel") {
				index = peaGravelList.findIndex((item) => item.id === id);
				peaGravelList[index].received = true;
				peaGravelList[index].receivedDate = `${dayjs(data.receivedDate)
					.toDate()
					.toLocaleDateString()} - ${dayjs(data.receivedDate)
					.toDate()
					.toLocaleTimeString([], { timeStyle: "short" })}`;
				setPeaGravelList(peaGravelList);
			}
			setLoadingTable(false);
			setLoadingButton(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const updateRecordJobStatus = async (id, status) => {
		const url = api + `/logisticstrip/update/jap/${id}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			let body = {
				jobStatus: status,
			};
			await axios.patch(url, body);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const deleteRecord = async (id) => {
		setLoadingTable(true);
		const url = api + `/logisticstrip/delete/${id}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			await axios.delete(url);
			let dateString = `${date.year()}-${date.month() + 1}-${date.date()}`;
			getTripList(dateString, plant, mine);
			setLoadingTable(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const onChangeJobStatus = async (value, type, plant, mine) => {
		setLoadingTable(true);
		setLoadingButton(true);
		switch (type) {
			case "s":
				if (plant === "Mission" && mine === "Mile 4") {
					let mission = sandList.filter(
						(item) => item.plant === "Mission" && item.mine === "Mile 4"
					);
					await Promise.all(
						mission.map((item) => updateRecordJobStatus(item.id, value))
					).then(() => {
						openNotification("success", "Success", "Sand Status Updated");
						const indexDetailed = detailedData[0].findIndex(
							(item) => item.plant === "Mission" && item.mine === "Mile 4"
						);
						detailedData[0][indexDetailed].status = value;
						setLoadingTable(false);
						setLoadingButton(false);
					});
				}
				if (plant === "Mission" && mine === "Texan") {
					let mission = sandList.filter(
						(item) => item.plant === "Mission" && item.mine === "Texan"
					);
					await Promise.all(
						mission.map((item) => updateRecordJobStatus(item.id, value))
					).then(() => {
						openNotification("success", "Success", "Sand Status Updated");
						const indexDetailed = detailedData[0].findIndex(
							(item) => item.plant === "Mission" && item.mine === "Texan"
						);
						detailedData[0][indexDetailed].status = value;
						setLoadingTable(false);
						setLoadingButton(false);
					});
				}
				if (plant === "San Benito" && mine === "Mile 4") {
					let sanBenito = sandList.filter(
						(item) => item.plant === "San Benito" && item.mine === "Mile 4"
					);
					await Promise.all(
						sanBenito.map((item) => updateRecordJobStatus(item.id, value))
					).then(() => {
						openNotification("success", "Success", "Sand Status Updated");
						const indexDetailed = detailedData[0].findIndex(
							(item) => item.plant === "San Benito" && item.mine === "Mile 4"
						);
						detailedData[0][indexDetailed].status = value;
						setLoadingTable(false);
						setLoadingButton(false);
					});
				}
				if (plant === "San Benito" && mine === "Texan") {
					let sanBenito = sandList.filter(
						(item) => item.plant === "San Benito" && item.mine === "Texan"
					);
					await Promise.all(
						sanBenito.map((item) => updateRecordJobStatus(item.id, value))
					).then(() => {
						openNotification("success", "Success", "Sand Status Updated");
						const indexDetailed = detailedData[0].findIndex(
							(item) => item.plant === "San Benito" && item.mine === "Texan"
						);
						detailedData[0][indexDetailed].status = value;
						setLoadingTable(false);
						setLoadingButton(false);
					});
				}
				break;
			case "g":
				if (plant === "Mission" && mine === "Mile 4") {
					let mission = gravelList.filter(
						(item) => item.plant === "Mission" && item.mine === "Mile 4"
					);
					await Promise.all(
						mission.map((item) => updateRecordJobStatus(item.id, value))
					).then(() => {
						openNotification("success", "Success", "Gravel Status Updated");
						const indexDetailed = detailedData[1].findIndex(
							(item) => item.plant === "Mission" && item.mine === "Mile 4"
						);
						detailedData[1][indexDetailed].status = value;
						setLoadingTable(false);
						setLoadingButton(false);
					});
				}
				if (plant === "Mission" && mine === "Texan") {
					let mission = gravelList.filter(
						(item) => item.plant === "Mission" && item.mine === "Texan"
					);
					await Promise.all(
						mission.map((item) => updateRecordJobStatus(item.id, value))
					).then(() => {
						openNotification("success", "Success", "Gravel Status Updated");
						const indexDetailed = detailedData[1].findIndex(
							(item) => item.plant === "Mission" && item.mine === "Texan"
						);
						detailedData[1][indexDetailed].status = value;
						setLoadingTable(false);
						setLoadingButton(false);
					});
				}
				if (plant === "San Benito" && mine === "Mile 4") {
					let sanBenito = gravelList.filter(
						(item) => item.plant === "San Benito" && item.mine === "Mile 4"
					);
					await Promise.all(
						sanBenito.map((item) => updateRecordJobStatus(item.id, value))
					).then(() => {
						openNotification("success", "Success", "Gravel Status Updated");
						const indexDetailed = detailedData[1].findIndex(
							(item) => item.plant === "San Benito" && item.mine === "Mile 4"
						);
						detailedData[1][indexDetailed].status = value;
						setLoadingTable(false);
						setLoadingButton(false);
					});
				}
				if (plant === "San Benito" && mine === "Texan") {
					let sanBenito = gravelList.filter(
						(item) => item.plant === "San Benito" && item.mine === "Texan"
					);
					await Promise.all(
						sanBenito.map((item) => updateRecordJobStatus(item.id, value))
					).then(() => {
						openNotification("success", "Success", "Gravel Status Updated");
						const indexDetailed = detailedData[1].findIndex(
							(item) => item.plant === "San Benito" && item.mine === "Texan"
						);
						detailedData[1][indexDetailed].status = value;
						setLoadingTable(false);
						setLoadingButton(false);
					});
				}

				// await Promise.all(
				// 	gravelList.map((item) => updateRecordJobStatus(item.id, value))
				// ).then(() => {
				// 	openNotification("success", "Success", "Gravel Status Updated");
				// 	detailedData[1].status = value;
				// 	setLoadingTable(false);
				// 	setLoadingButton(false);
				// });
				break;
			case "pg":
				if (plant === "Mission" && mine === "Mile 4") {
					let mission = peaGravelList.filter(
						(item) => item.plant === "Mission" && item.mine === "Mile 4"
					);
					await Promise.all(
						mission.map((item) => updateRecordJobStatus(item.id, value))
					).then(() => {
						openNotification("success", "Success", "Pea Gravel Status Updated");
						const indexDetailed = detailedData[2].findIndex(
							(item) => item.plant === "Mission" && item.mine === "Mile 4"
						);
						detailedData[2][indexDetailed].status = value;
						setLoadingTable(false);
						setLoadingButton(false);
					});
				}
				if (plant === "Mission" && mine === "Texan") {
					let mission = peaGravelList.filter(
						(item) => item.plant === "Mission" && item.mine === "Texan"
					);
					await Promise.all(
						mission.map((item) => updateRecordJobStatus(item.id, value))
					).then(() => {
						openNotification("success", "Success", "Pea Gravel Status Updated");
						const indexDetailed = detailedData[2].findIndex(
							(item) => item.plant === "Mission" && item.mine === "Texan"
						);
						detailedData[2][indexDetailed].status = value;
						setLoadingTable(false);
						setLoadingButton(false);
					});
				}
				if (plant === "San Benito" && mine === "Mile 4") {
					let sanBenito = peaGravelList.filter(
						(item) => item.plant === "San Benito" && item.mine === "Mile 4"
					);
					await Promise.all(
						sanBenito.map((item) => updateRecordJobStatus(item.id, value))
					).then(() => {
						openNotification("success", "Success", "Pea Gravel Status Updated");
						const indexDetailed = detailedData[2].findIndex(
							(item) => item.plant === "San Benito" && item.mine === "Mile 4"
						);
						detailedData[2][indexDetailed].status = value;
						setLoadingTable(false);
						setLoadingButton(false);
					});
				}
				if (plant === "San Benito" && mine === "Texan") {
					let sanBenito = peaGravelList.filter(
						(item) => item.plant === "San Benito" && item.mine === "Texan"
					);
					await Promise.all(
						sanBenito.map((item) => updateRecordJobStatus(item.id, value))
					).then(() => {
						openNotification("success", "Success", "Pea Gravel Status Updated");
						const indexDetailed = detailedData[2].findIndex(
							(item) => item.plant === "San Benito" && item.mine === "Texan"
						);
						detailedData[2][indexDetailed].status = value;
						setLoadingTable(false);
						setLoadingButton(false);
					});
				}
				// await Promise.all(
				// 	peaGravelList.map((item) => updateRecordJobStatus(item.id, value))
				// ).then(() => {
				// 	openNotification("success", "Success", "Pea Gravel Status Updated");
				// 	detailedData[2].status = value;
				// 	setLoadingTable(false);
				// 	setLoadingButton(false);
				// });
				break;
			default:
				break;
		}
	};

	const copyTickets = (type) => {
		let textContent = "";
		if (type === "sand") {
			sandList.map((i, index) => {
				if (index < sandList.length - 1) {
					textContent += i.ticket + ",";
				} else {
					textContent += i.ticket;
				}
			});
		} else if (type === "gravel") {
			gravelList.map((i, index) => {
				if (index < gravelList.length - 1) {
					textContent += i.ticket + ",";
				} else {
					textContent += i.ticket;
				}
			});
		} else if (type === "peagravel") {
			peaGravelList.map((i, index) => {
				if (index < peaGravelList.length - 1) {
					textContent += i.ticket + ",";
				} else {
					textContent += i.ticket;
				}
			});
		}

		navigator.clipboard.writeText(textContent);
	};

	const RenderTables = () => {
		return (
			<Flex gap="medium" justify="center" vertical>
				<Table
					title={() => (
						<>
							<Title level={2}>Sand Orders</Title>
							<Button
								size="large"
								type="primary"
								danger
								onClick={() => copyTickets("sand")}
							>
								Copy Sand Tickets
							</Button>
							{detailedData[0].map((itemSand, index) => {
								return (
									<Row gutter={16}>
										<Col span={3}>
											<Flex vertical>
												<Text strong>ID</Text>
												<Text>{itemSand.id}</Text>
											</Flex>
										</Col>
										<Col span={3}>
											<Flex vertical>
												<Text strong>Quantity</Text>
												<Statistic
													title=""
													value={itemSand.quantity}
													precision={2}
													valueStyle={{ fontSize: 14 }}
												/>{" "}
											</Flex>
										</Col>
										<Col span={3}>
											<Flex vertical>
												<Text strong>Material</Text>
												<Text>{itemSand.material}</Text>
											</Flex>
										</Col>
										<Col span={3}>
											<Flex vertical>
												<Text strong>Plant</Text>
												<Text>{itemSand.plant}</Text>
											</Flex>
										</Col>
										<Col span={3}>
											<Flex vertical>
												<Text strong>Mine</Text>
												<Text>{itemSand.mine}</Text>
											</Flex>
										</Col>
										<Col span={3}>
											<Flex vertical>
												<Text strong>Status</Text>
												<Select
													options={jobStatusOptions}
													value={itemSand.status}
													onChange={(value) =>
														onChangeJobStatus(
															value,
															"s",
															itemSand.plant,
															itemSand.mine
														)
													}
												/>
											</Flex>
										</Col>
										{index === 0 && (
											<Col span={3}>
												<Flex vertical>
													<Text strong>PDF</Text>
													<Button
														type="primary"
														icon={<FilePdfOutlined twoToneColor={"white"} />}
														onClick={(e) =>
															history.push(
																`/logistics/pdf/${itemSand.material}`,
																{
																	date: date,
																	plant: plant,
																	mine: mine,
																}
															)
														}
													>
														PDF {itemSand.material}
													</Button>
												</Flex>
											</Col>
										)}
									</Row>
								);
							})}
						</>
					)}
					columns={columns}
					dataSource={sandList}
					pagination={{
						defaultPageSize: 10,
						showSizeChanger: true,
						pageSizeOptions: ["10", "30", "50", "100"],
					}}
					loading={loadingTable}
					onRow={(record, index) => ({
						style: {
							background: record.duplicated ? "#b9d3fa" : "white",
						},
					})}
				/>
				<Table
					title={() => (
						<>
							<Title level={2}>Gravel Orders</Title>
							<Button
								size="large"
								type="primary"
								danger
								onClick={() => copyTickets("gravel")}
							>
								Copy Gravel Tickets
							</Button>
							{detailedData[1].map((itemGravel, index) => {
								return (
									<Row gutter={16}>
										<Col span={3}>
											<Flex vertical>
												<Text strong>ID</Text>
												<Text>{itemGravel.id}</Text>
											</Flex>
										</Col>
										<Col span={3}>
											<Flex vertical>
												<Text strong>Quantity</Text>
												<Statistic
													title=""
													value={itemGravel.quantity}
													precision={2}
													valueStyle={{ fontSize: 14 }}
												/>
											</Flex>
										</Col>
										<Col span={3}>
											<Flex vertical>
												<Text strong>Material</Text>
												<Text>{itemGravel.material}</Text>
											</Flex>
										</Col>
										<Col span={3}>
											<Flex vertical>
												<Text strong>Plant</Text>
												<Text>{itemGravel.plant}</Text>
											</Flex>
										</Col>
										<Col span={3}>
											<Flex vertical>
												<Text strong>Mine</Text>
												<Text>{itemGravel.mine}</Text>
											</Flex>
										</Col>
										<Col span={3}>
											<Flex vertical>
												<Text strong>Status</Text>
												<Select
													options={jobStatusOptions}
													value={itemGravel.status}
													onChange={(value) =>
														onChangeJobStatus(
															value,
															"g",
															itemGravel.plant,
															itemGravel.mine
														)
													}
												/>
											</Flex>
										</Col>
										{index === 0 && (
											<Col span={3}>
												<Flex vertical>
													<Text strong>PDF</Text>
													<Button
														type="primary"
														icon={<FilePdfOutlined twoToneColor={"white"} />}
														onClick={(e) =>
															history.push(
																`/logistics/pdf/${itemGravel.material}`,
																{ date: date, plant: plant, mine: mine }
															)
														}
													>
														PDF {itemGravel.material}
													</Button>
												</Flex>
											</Col>
										)}
									</Row>
								);
							})}
						</>
					)}
					columns={columns}
					dataSource={gravelList}
					pagination={{
						defaultPageSize: 10,
						showSizeChanger: true,
						pageSizeOptions: ["10", "30", "50", "100"],
					}}
					loading={loadingTable}
					onRow={(record, index) => ({
						style: {
							background: record.duplicated ? "#b9d3fa" : "white",
						},
					})}
				/>
				<Table
					title={() => (
						<>
							<Title level={2}>Pea Gravel Orders</Title>
							<Button
								size="large"
								type="primary"
								danger
								onClick={() => copyTickets("peagravel")}
							>
								Copy Pea Gravel Tickets
							</Button>
							{detailedData[2].map((itemPeaGravel, index) => {
								return (
									<Row gutter={16}>
										<Col span={3}>
											<Flex vertical>
												<Text strong>ID</Text>
												<Text>{itemPeaGravel.id}</Text>
											</Flex>
										</Col>
										<Col span={3}>
											<Flex vertical>
												<Text strong>Quantity</Text>
												<Statistic
													title=""
													value={itemPeaGravel.quantity}
													precision={2}
													valueStyle={{ fontSize: 14 }}
												/>{" "}
											</Flex>
										</Col>
										<Col span={3}>
											<Flex vertical>
												<Text strong>Material</Text>
												<Text>{itemPeaGravel.material}</Text>
											</Flex>
										</Col>
										<Col span={3}>
											<Flex vertical>
												<Text strong>Plant</Text>
												<Text>{itemPeaGravel.plant}</Text>
											</Flex>
										</Col>
										<Col span={3}>
											<Flex vertical>
												<Text strong>Mine</Text>
												<Text>{itemPeaGravel.mine}</Text>
											</Flex>
										</Col>
										<Col span={3}>
											<Flex vertical>
												<Text strong>Status</Text>
												<Select
													options={jobStatusOptions}
													value={itemPeaGravel.status}
													onChange={(value) =>
														onChangeJobStatus(
															value,
															"pg",
															itemPeaGravel.plant,
															itemPeaGravel.mine
														)
													}
												/>
											</Flex>
										</Col>
										{index === 0 && (
											<Col span={3}>
												<Flex vertical>
													<Text strong>PDF</Text>
													<Button
														type="primary"
														icon={<FilePdfOutlined twoToneColor={"white"} />}
														onClick={(e) =>
															history.push(
																`/logistics/pdf/${itemPeaGravel.material}`,
																{ date: date, plant: plant, mine: mine }
															)
														}
													>
														PDF {itemPeaGravel.material}
													</Button>
												</Flex>
											</Col>
										)}
									</Row>
								);
							})}
						</>
					)}
					columns={columns}
					dataSource={peaGravelList}
					pagination={{
						defaultPageSize: 10,
						showSizeChanger: true,
						pageSizeOptions: ["10", "30", "50", "100"],
					}}
					loading={loadingTable}
					onRow={(record, index) => ({
						style: {
							background: record.duplicated ? "#b9d3fa" : "white",
						},
					})}
				/>
			</Flex>
		);
	};

	const onChangeDate = (date) => {
		let dateString = `${date.year()}-${date.month() + 1}-${date.date()}`;
		getTripList(dateString, plant, mine);
		setDate(date);
	};

	const handleEvidenceCancel = () => {
		setLoadingButton(false);
		setOpenEvidenceModal(false);
	};

	const handleEvidenceOpen = (evidence) => {
		setLoadingButton(true);
		setEvidence(evidence);
		setOpenEvidenceModal(true);
	};

	const RenderEvidence = () => {
		return (
			<Modal
				title="Evidence Image"
				open={openEvidenceModal}
				onCancel={handleEvidenceCancel}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
			>
				<Flex align="center" justify="center">
					<Image id="evidence" width={300} src={evidence} />
				</Flex>
			</Modal>
		);
	};

	const onChangePlant = (value) => {
		if (value) {
			let dateString = `${date.year()}-${date.month() + 1}-${date.date()}`;
			getTripList(dateString, value, mine);
			setPlant(value);
		}
	};

	const onChangeMine = (value) => {
		if (value) {
			let dateString = `${date.year()}-${date.month() + 1}-${date.date()}`;
			getTripList(dateString, plant, value);
			setMine(value);
		}
	};

	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const RenderDate = () => {
		return (
			<Flex gap={"middle"}>
				<DatePicker
					value={date}
					format={"MM/DD/YYYY"}
					onChange={onChangeDate}
					size="large"
					allowClear={false}
					style={{ width: 200 }}
				/>
				<Select
					showSearch
					placeholder={`Select plant`}
					optionFilterProp="children"
					size="large"
					onChange={onChangePlant}
					filterOption={filterOption}
					options={plants}
					disabled={loading}
					value={plant}
					style={{
						width: "200px",
					}}
				/>
				<Select
					showSearch
					placeholder={`Select mine`}
					optionFilterProp="children"
					size="large"
					onChange={onChangeMine}
					filterOption={filterOption}
					options={mineOptions}
					disabled={loading}
					value={mine}
					style={{
						width: "200px",
					}}
				/>
			</Flex>
		);
	};

	const RenderButton = () => {
		return (
			<Row gutter={16}>
				<Col span={24} xs={12} md={6} xl={4}>
					<Button
						size="large"
						type="primary"
						htmlType="button"
						style={{ width: "100%" }}
						loading={loading}
						onClick={() => history.push("/reports/logistics/detailed")}
					>
						Detailed Report
					</Button>
				</Col>
				<Col span={24} xs={12} md={8} xl={6}>
					<Button
						type="primary"
						style={{ width: "100%" }}
						onClick={(e) => refDownload.current.link.click()}
						loading={loading}
						size="large"
					>
						Download Today Detailed Report
					</Button>
					<CSVLink
						data={dataExport}
						headers={columnsCSV}
						filename={"57concrete-logistics-detailed-today.csv"}
						hidden
						ref={refDownload}
					>
						Download report
					</CSVLink>
				</Col>
			</Row>
		);
	};

	return (
		<Layout title="Logistics Dashboard" description="Dashboard">
			<Flex vertical gap={"small"}>
				<RenderDate />
				<RenderButton />
				{loading ? <CustomSpinner /> : <RenderTables />}
			</Flex>
			<RenderEvidence />
			{contextHolder}
		</Layout>
	);
};

export default WebLogisticsDashboard;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { api } from "../utils/Api";
import { Typography, Flex, Button, Statistic } from "antd";
import Layout from "../layout/Layout";
import { CustomSpinner, CustomTable } from "../utils/CustomComponents";
import { EditFilled, PlusCircleFilled } from "@ant-design/icons";
const { Text } = Typography;

const CogList = ({ history }) => {
	const [loading, setLoading] = useState(true);
	const [cogList, setCogList] = useState([]);

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "",
			dataIndex: "edit",
			width: 100,
			render: (_, record) => {
				return localStorage.getItem("email") === "ec@57concrete.com" ||
					localStorage.getItem("email") === "eliud@gmail.com" ||
					localStorage.getItem("email") === "jp@57concrete.com" ||
					localStorage.getItem("email") === "vm@57concrete.com" ||
					localStorage.getItem("email") === "ea@57concrete.com" ||
					localStorage.getItem("email") === "ss@57concrete.com" ||
					localStorage.getItem("email") === "fc@57concrete.com" ? (
					<Button
						type="primary"
						icon={<EditFilled twoToneColor={"white"} />}
						onClick={(e) => history.push(`/cog/${record.id}`)}
					/>
				) : (
					""
				);
			},
		},
		{
			title: "Material Name",
			dataIndex: "material",
			key: "material",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Unit Price",
			dataIndex: "unitPrice",
			key: "unitPrice",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={3}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Unit Of Measurement",
			dataIndex: "unitOfMeasurement",
			key: "unitOfMeasurement",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Unit Price Conversion",
			dataIndex: "unitPriceConversion",
			key: "unitPriceConversion",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={4}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Unit Of Conversion",
			dataIndex: "unitOfConversion",
			key: "unitOfConversion",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
	];

	useEffect(() => {
		getCogList();
	}, []);

	const getCogList = async () => {
		setLoading(true);

		const url = api + "/cog";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			data.map((item, index) => {
				list.push({
					key: index,
					id: item._id,
					material: item.material,
					unitPrice: item.unitPrice,
					unitOfMeasurement: item.unitOfMeasurement,
					unitPriceConversion: item.unitPriceConversion,
					unitOfConversion: item.unitOfConversion || "N/A",
				});
			});
			setCogList(list);
			setLoading(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const addNewCog = () => {
		history.push("/newcog");
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				{(localStorage.getItem("email") === "ec@57concrete.com" ||
					localStorage.getItem("email") === "eliud@gmail.com" ||
					localStorage.getItem("email") === "jp@57concrete.com" ||
					localStorage.getItem("email") === "ea@57concrete.com" ||
					localStorage.getItem("email") === "ss@57concrete.com" ||
					localStorage.getItem("email") === "vm@57concrete.com") && (
					<Button
						onClick={addNewCog}
						type="primary"
						style={{
							width: 150,
						}}
						icon={<PlusCircleFilled />}
					>
						New Cog
					</Button>
				)}
				<CustomTable columns={columns} data={cogList} />
			</Flex>
		);
	};

	return (
		<Layout title="Cog" description="Cog List">
			<Flex vertical gap={"large"}>
				{loading ? <CustomSpinner /> : <RenderTable />}
			</Flex>
		</Layout>
	);
};

export default CogList;

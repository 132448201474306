// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import GoogleMapReact from "google-map-react";
// import Geocode from "react-geocode";
// import Marker from "./Marker";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSearch } from "@fortawesome/free-solid-svg-icons";
// import { api } from "../utils/Api";

// const AddressGeolocation = (props) => {
// 	const [latitude, setLatitude] = useState(26.2787785);
// 	const [longitude, setLongitude] = useState(-98.4021818);
// 	const [locality, setLocality] = useState("");
// 	const [address, setAddress] = useState("");
// 	const [destLat, setDestLat] = useState("");
// 	const [destLng, setDestLng] = useState("");
// 	const [error, setError] = useState(false);
// 	const [farAway, setFarAway] = useState(false);
// 	const [search, setSearch] = useState(props.search);
// 	const [distance, setDistance] = useState(null);
// 	const [duration, setDuration] = useState(null);
// 	const [name, setName] = useState("57concrete");

// 	const googleApi =
// 		process.env.REACT_APP_GOOGLE_MAP_API ||
// 		"AIzaSyClyO950zwD8xsLYO7VHcyrnokEU6njbVs";

// 	useEffect(() => {
// 		if (props.plant) {
// 			getPlantInfo();
// 		} else {
// 			getCompanyInfo();
// 		}
// 	}, []);

// 	const getCompanyInfo = async () => {
// 		const url = api + "/company";
// 		const token = localStorage.getItem("token");
// 		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

// 		try {
// 			const { data } = await axios.get(url);
// 			setLatitude(data.latitude);
// 			setLongitude(data.longitude);
// 		} catch (err) {
// 			console.error(err);
// 			return null;
// 		}
// 	};

// 	const getPlantInfo = async () => {
// 		const url = api + `/plant/${props.plant}`;
// 		const token = localStorage.getItem("token");
// 		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

// 		try {
// 			const { data } = await axios.get(url);
// 			setLatitude(data.latitude);
// 			setLongitude(data.longitude);
// 			setName(data.name);
// 		} catch (err) {
// 			console.error(err);
// 			return null;
// 		}
// 	};

// 	// address geocoding
// 	const getCoordinates = (address) => {
// 		Geocode.setApiKey(googleApi);
// 		Geocode.fromAddress(address).then(
// 			(response) => {
// 				const addressComponentes = response.results[0].address_components;
// 				const { lat, lng } = response.results[0].geometry.location;

// 				let locality = "";
// 				addressComponentes.forEach((item) => {
// 					const { types } = item;
// 					if (types[0] === "locality") {
// 						locality = item.long_name;
// 					}
// 				});
// 				setLocality(locality);
// 				setDestLat(lat);
// 				setDestLng(lng);
// 				calculateDistance(lat, lng);
// 			},
// 			(error) => {
// 				setError(true);
// 				console.error(error);
// 			}
// 		);
// 	};

// 	// Distance to customer
// 	const calculateDistance = async (lat, lng) => {
// 		const ubicacion = {
// 			lat,
// 			lng,
// 		};

// 		let url = "";
// 		if (props.plant) {
// 			url = api + `/distanceplant/${props.plant}`;
// 		} else {
// 			url = api + "/distance";
// 		}

// 		try {
// 			const { data } = await axios.post(url, ubicacion);
// 			const distance = data.distance.text;
// 			const duration = data.duration.text;
// 			const recorrido = distance.split(" ");
// 			if (parseFloat(recorrido[0].trim()) > 100) {
// 				setFarAway(true);
// 			} else {
// 				setDistance(distance);
// 				setDuration(duration);
// 			}
// 		} catch (err) {
// 			console.error(err);
// 			return null;
// 		}
// 	};

// 	const onClick = (event) => {
// 		Geocode.setApiKey(googleApi);
// 		Geocode.fromLatLng(event.lat, event.lng).then(
// 			(response) => {
// 				console.log(response);
// 				const formattedAddress = response.results[0].formatted_address;
// 				const addressComponentes = response.results[0].address_components;
// 				const lat = event.lat;
// 				const lng = event.lng;

// 				let locality = "";
// 				addressComponentes.forEach((item) => {
// 					const { types } = item;
// 					if (types[0] === "locality") {
// 						locality = item.long_name;
// 					}
// 				});
// 				setAddress(formattedAddress);
// 				setLocality(locality);
// 				setDestLat(lat);
// 				setDestLng(lng);
// 				calculateDistance(lat, lng);
// 			},
// 			(error) => {
// 				setError(true);
// 				console.error(error);
// 			}
// 		);
// 	};

// 	const defaultMap = {
// 		center: {
// 			lat: latitude,
// 			lng: longitude,
// 		},
// 		zoom: 8,
// 	};

// 	// Geocode Address
// 	const geoCode = () => {
// 		setError(false);
// 		getCoordinates(address);
// 	};

// 	// pass data to parent
// 	const saveAddress = () => {
// 		const data = {
// 			address,
// 			destLat,
// 			destLng,
// 			distance,
// 			duration,
// 			locality,
// 		};
// 		props.parentCallback(data);
// 	};

// 	// show text in map
// 	const AnyReactComponent = ({ text }) => <div>{text}</div>;

// 	return (
// 		<div>
// 			{error && <div>Address not found</div>}
// 			{farAway && <div>Address out of coverage</div>}
// 			{search && (
// 				<div>
// 					<div className="form-group row">
// 						<div className="col-sm-10">
// 							<input
// 								type="text"
// 								name="address"
// 								id="address"
// 								value={address}
// 								size="45"
// 								maxLength="100"
// 								onChange={(e) => setAddress(e.target.value)}
// 							/>
// 						</div>
// 						<div className="col-sm-1">
// 							<button className="btn btn-primary" onClick={geoCode}>
// 								<FontAwesomeIcon icon={faSearch} />
// 							</button>
// 						</div>
// 					</div>
// 				</div>
// 			)}
// 			<div style={{ height: "60vh", width: "100%" }}>
// 				<GoogleMapReact
// 					bootstrapURLKeys={{
// 						key: googleApi,
// 						version: "weekly",
// 					}}
// 					options={{
// 						fullscreenControl: true,
// 						disableDefaultUI: true,
// 						cameraControl: true,
// 					}}
// 					defaultCenter={defaultMap.center}
// 					defaultZoom={defaultMap.zoom}
// 					onClick={onClick}
// 					yesIWantToUseGoogleMapApiInternals
// 				>
// 					{!props.single && (
// 						<Marker lat={latitude} lng={longitude} color="red" />
// 					)}
// 					{!props.single && (
// 						<AnyReactComponent lat={latitude} lng={longitude} text={name} />
// 					)}
// 					{destLat && <Marker lat={destLat} lng={destLng} color="green" />}
// 				</GoogleMapReact>
// 			</div>
// 			<div className="my-2">
// 				{distance && (
// 					<button className="btn btn-success" onClick={saveAddress}>
// 						Save address
// 					</button>
// 				)}
// 			</div>
// 		</div>
// 	);
// };

// export default AddressGeolocation;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { api } from "../utils/Api";
import { Col, Row, Input, notification, Button } from "antd";
import { Map, TileLayer, Popup, Marker as LeafletMarker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import { CustomSpinner } from "../utils/CustomComponents";

const { Search } = Input;

const AddressGeolocation = (props) => {
	const [latitude, setLatitude] = useState(26.2787785);
	const [longitude, setLongitude] = useState(-98.4021818);
	const [center, setCenter] = useState([26.2787785, -98.4021818]);
	const [locality, setLocality] = useState("");
	const [address, setAddress] = useState("");
	const [destLat, setDestLat] = useState();
	const [destLng, setDestLng] = useState();
	const [search, setSearch] = useState(props.search);
	const [distance, setDistance] = useState(null);
	const [duration, setDuration] = useState(null);
	const [loading, setLoading] = useState(true);
	const [name, setName] = useState("57concrete Mission");
	const [apiNotification, contextHolder] = notification.useNotification();

	useEffect(() => {
		if (props.plant) {
			getPlantInfo();
		} else {
			getCompanyInfo();
		}
	}, []);

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	const getCompanyInfo = async () => {
		const url = api + "/company";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			setLatitude(data.latitude);
			setLongitude(data.longitude);
			setCenter([data.latitude, data.longitude]);
			setLoading(false);
		} catch (err) {
			setLoading(true);

			console.error(err);
			return null;
		}
	};

	const getPlantInfo = async () => {
		const url = api + `/plant/${props.plant}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			setLatitude(data.latitude);
			setLongitude(data.longitude);
			setName(`57concrete ${data.name}`);
			setCenter([data.latitude, data.longitude]);
			setLoading(false);
		} catch (err) {
			setLoading(true);

			console.error(err);
			return null;
		}
	};

	// Distance to customer
	const calculateDistance = async (lat, lng) => {
		const ubicacion = {
			lat,
			lng,
		};

		let url = "";
		if (props.plant) {
			url = api + `/distanceplant/${props.plant}`;
		} else {
			url = api + "/distance";
		}

		try {
			const { data } = await axios.post(url, ubicacion);
			const distance = data.distance.text;
			const duration = data.duration.text;
			const recorrido = distance.split(" ");
			if (parseFloat(recorrido[0].trim()) > 100) {
				setDistance(null);
				openNotification("error", "Error", "Address out of coverage");
			} else {
				setDistance(distance);
				setDuration(duration);
			}
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	// pass data to parent
	const saveAddress = () => {
		const data = {
			address,
			destLat,
			destLng,
			distance,
			duration,
			locality,
		};
		props.parentCallback(data);
	};

	const customIcon = new L.Icon({
		iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
		iconSize: [25, 41], // size of the icon
		iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
		popupAnchor: [1, -34], // point from which the popup should open
		shadowUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png",
		shadowSize: [41, 41], // size of the shadow
	});

	const calculateGeocodeCoords = (lat, lng, type) => {
		const apiKey = "5b3ce3597851110001cf6248083de09da919490b904e5198f0c4279a";

		fetch(
			`https://api.openrouteservice.org/geocode/reverse?api_key=${apiKey}&point.lon=${lng}&point.lat=${lat}&size=${1}&boundary.country=${"US"}&lang=${"en"}`
		)
			.then((response) => response.json())
			.then((data) => {
				if (data && data.features.length > 0) {
					const firstFeature = data.features[0];

					// if (firstFeature.properties.locality === undefined) {
					// 	setDestLat(undefined);
					// 	setDestLng(undefined);
					// 	setAddress("");
					// 	setDistance(null);
					// 	openNotification("warning", "Warning", "Locality do not exist");
					// } else {
					const midLat = (lat + latitude) / 2;
					const midLng = (lng + longitude) / 2;
					setCenter([midLat, midLng]);
					if (type === "coords") {
						setAddress(firstFeature.properties.label);
					}
					setLocality(firstFeature.properties.locality);
					setDestLat(lat);
					setDestLng(lng);
					calculateDistance(lat, lng);
					// }
				} else {
					setDestLat(undefined);
					setDestLng(undefined);
					setAddress("");
					setDistance(null);
					openNotification("error", "Error", "Address do not found");
				}
			})
			.catch((error) => {
				openNotification("error", "Error", error);
			});
	};

	const calculateGeocodeAddress = () => {
		const apiKey = "5b3ce3597851110001cf6248083de09da919490b904e5198f0c4279a";

		const coords = address.replaceAll(" ", "").split(",");
		if (coords.length === 2 && !isNaN(coords[0]) && !isNaN(coords[1])) {
			calculateGeocodeCoords(Number(coords[0]), Number(coords[1]), "address");
		} else {
			fetch(
				`https://api.openrouteservice.org/geocode/search?api_key=${apiKey}&text=${address}&boundary.country=${"US"}&lang=${"en"}`
			)
				.then((response) => response.json())
				.then((data) => {
					if (data && data.features.length > 0) {
						const firstFeature = data.features[0];
						const { geometry, properties } = firstFeature;

						// if (properties.locality === undefined) {
						// 	setDestLat(undefined);
						// 	setDestLng(undefined);
						// 	setAddress("");
						// 	setDistance(null);
						// 	openNotification("warning", "Warning", "Locality do not exist");
						// } else {
						const lat = geometry.coordinates[1];
						const lng = geometry.coordinates[0];
						const midLat = (lat + latitude) / 2;
						const midLng = (lng + longitude) / 2;
						setCenter([midLat, midLng]);
						setLocality(properties.locality);
						setDestLat(lat);
						setDestLng(lng);
						calculateDistance(lat, lng);
						// }
					} else {
						setDestLat(undefined);
						setDestLng(undefined);
						setAddress("");
						setDistance(null);
						openNotification("error", "Error", "Address do not found");
					}
				})
				.catch((error) => {
					openNotification("error", "Error", error);
				});
		}
	};

	const handleClick = (e) => {
		const latlng = e.latlng;
		calculateGeocodeCoords(latlng.lat, latlng.lng, "coords");
	};

	return (
		<div>
			{loading ? (
				<CustomSpinner />
			) : (
				<Row gutter={[16, 8]}>
					{search && (
						<Col span={24}>
							<Search
								name="search"
								placeholder="Search"
								enterButton
								onSearch={calculateGeocodeAddress}
								value={address}
								onChange={(e) => setAddress(e.target.value)}
								size="large"
							/>
						</Col>
					)}
					<Col span={24} style={{ height: "60vh", width: "100%" }}>
						<Map
							center={center}
							zoom={11}
							style={{ width: "100%", height: "500px" }}
							onClick={handleClick}
						>
							<TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
							{!props.single && (
								<LeafletMarker
									position={[latitude, longitude]}
									icon={customIcon}
								>
									<Popup>{name}</Popup>
								</LeafletMarker>
							)}
							{destLat && destLng && (
								<LeafletMarker position={[destLat, destLng]} icon={customIcon}>
									<Popup>Destination: {props.address || address}</Popup>
								</LeafletMarker>
							)}
						</Map>
					</Col>
					<Col>
						{distance && (
							<Button
								size="large"
								type="primary"
								htmlType="button"
								onClick={saveAddress}
							>
								Save address
							</Button>
						)}
					</Col>
					{contextHolder}
				</Row>
			)}
		</div>
	);
};

export default AddressGeolocation;

import React, { Component } from "react";
import Select from "react-select";

export default class JobStatusPickerSeller extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedOption: "",
			isClearable: false,
			isSearchable: true,
			statusList: [
				{ _id: "New Order", status: "New Order" },
				{ _id: "Trouble", status: "Trouble" },
				{ _id: "ok", status: "Poured" },
				{ _id: "Confirm", status: "A/R Confirmed" },
				{ _id: "Operations done (AR)", status: "Operations done (AR)" },
				{ _id: "Scheduled", status: "Scheduled" },
				{ _id: "Inactive", status: "Inactive" },
				{ _id: "Company cancellation", status: "Company cancellation" },
				{ _id: "Customer cancellation", status: "Customer cancellation" },
				{ _id: "Date moved", status: "Date moved" },
				{ _id: "Active", status: "Pouring" },
				{ _id: "Ready to Load", status: "Ready to be scheduled" },
				{ _id: "Quote", status: "Quote" },
				{ _id: "Waiting Inspection", status: "Waiting Inspection" },
			],
			isDisabled: localStorage.getItem("role") === "Seller",
		};
	}

	render() {
		const options = this.state.statusList.map((estatus) => {
			if (
				estatus.status !== "New Order" &&
				estatus.status !== "Waiting Inspection"
			) {
				return {
					value: estatus._id,
					label: estatus.status,
					isdisabled: this.state.isDisabled,
				};
			}
			return {
				value: estatus._id,
				label: estatus.status,
			};
		});

		return (
			<div style={{ fontSize: "10px" }}>
				<Select
					name="form-field-name"
					placeholder="Job Status..."
					value={this.state.value}
					onChange={this.props.action}
					isClearable={this.state.isClearable}
					isSearchable={this.state.isSearchable}
					options={options}
					isOptionDisabled={(option) => option.isdisabled}
				/>
			</div>
		);
	}
}

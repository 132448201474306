import {
	Button,
	Col,
	Divider,
	Form,
	InputNumber,
	Row,
	Statistic,
	Table,
	Typography,
	notification,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";

const { Text } = Typography;
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
	const [form] = Form.useForm();
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	);
};

const columnsNewQuantity = [
	{
		title: "Material",
		dataIndex: "material",
		key: "material",
	},
	{
		title: "Quantity",
		dataIndex: "quantity",
		key: "quantity",
		editable: true,
	},
];

const quantityRowsData = [
	{
		key: "0",
		material: "AD Nr",
		quantity: 0,
	},
	{
		key: "1",
		material: "AD SP",
		quantity: 0,
	},
	{
		key: "2",
		material: "AD X15",
		quantity: 0,
	},
	{
		key: "3",
		material: "AD SE",
		quantity: 0,
	},
	{
		key: "4",
		material: "AREA 92",
		quantity: 0,
	},
	{
		key: "5",
		material: "Monofilament Fiber",
		quantity: 0,
	},
	{
		key: "6",
		material: "Sika Plast 500",
		quantity: 0,
	},
	{
		key: "7",
		material: "Plastiment XR",
		quantity: 0,
	},
	{
		key: "8",
		material: "Cem 1",
		quantity: 0,
	},
	{
		key: "9",
		material: "Cement Loads",
		quantity: 0,
	},
	{
		key: "10",
		material: "Gravel 57",
		quantity: 0,
	},
	{
		key: "11",
		material: "Limestone Gravel",
		quantity: 0,
	},
	{
		key: "12",
		material: "Pea Gravel",
		quantity: 0,
	},
	{
		key: "13",
		material: "Sand",
		quantity: 0,
	},
	{
		key: "14",
		material: "Aggregate Loads",
		quantity: 0,
	},
	{
		key: "15",
		material: "Wat 1",
		quantity: 0,
	},
];

const AddCostMixDesign = ({ pcb }) => {
	const [form] = Form.useForm();
	const [isSave, setIsSave] = useState(true);
	const [loading, setLoading] = useState(false);
	const [apiNotification, contextHolder] = notification.useNotification();
	const [dataSource, setDataSource] = useState([
		...quantityRowsData.map((item) => {
			return { ...item, key: "quantity" + item.key };
		}),
	]);

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	const EditableCell = ({
		title,
		editable,
		children,
		dataIndex,
		record,
		handleSave,
		mainData2,
		...restProps
	}) => {
		const [editing, setEditing] = useState(false);
		const inputRef = useRef(null);
		const form = useContext(EditableContext);

		useEffect(() => {
			if (editing) {
				inputRef.current?.focus();
			}
		}, [editing]);

		const toggleEdit = () => {
			setEditing(!editing);
			form.setFieldsValue({
				[dataIndex]: record[dataIndex],
			});
		};

		const save = async () => {
			try {
				const values = await form.validateFields();
				toggleEdit();
				handleSave({
					...record,
					...values,
				});
				setIsSave(true);
			} catch (errInfo) {
				setIsSave(false);
				openNotification("error", "Error", "You must fill quantity");
			}
		};

		const onChangeValue = (value) => {
			if (record.material === "Sand") {
				let currentRecordPeaGravel = mainData2.find(
					(item) => item.material === "Pea Gravel"
				);
				let currentRecordGravel57 = mainData2.find(
					(item) => item.material === "Gravel 57"
				);

				let currentRecordAggregateLoads = mainData2.find(
					(item) => item.material === "Aggregate Loads"
				);

				currentRecordAggregateLoads.quantity =
					value +
					currentRecordPeaGravel.quantity +
					currentRecordGravel57.quantity;
			} else if (record.material === "Pea Gravel") {
				let currentRecordSand = mainData2.find(
					(item) => item.material === "Sand"
				);
				let currentRecordGravel57 = mainData2.find(
					(item) => item.material === "Gravel 57"
				);

				let currentRecordAggregateLoads = mainData2.find(
					(item) => item.material === "Aggregate Loads"
				);

				currentRecordAggregateLoads.quantity =
					value + currentRecordSand.quantity + currentRecordGravel57.quantity;
			} else if (record.material === "Gravel 57") {
				let currentRecordSand = mainData2.find(
					(item) => item.material === "Sand"
				);
				let currentRecordPeaGravel = mainData2.find(
					(item) => item.material === "Pea Gravel"
				);

				let currentRecordAggregateLoads = mainData2.find(
					(item) => item.material === "Aggregate Loads"
				);

				currentRecordAggregateLoads.quantity =
					value + currentRecordSand.quantity + currentRecordPeaGravel.quantity;
			}
		};

		let childNode = children;
		if (editable) {
			childNode =
				editing && record.material !== "Aggregate Loads" ? (
					<Form.Item
						style={{
							margin: 0,
						}}
						name={dataIndex}
						rules={[
							{
								required: true,
								message: `Quantity is required.`,
							},
						]}
					>
						<InputNumber
							ref={inputRef}
							onPressEnter={save}
							onBlur={save}
							style={{ width: "100%" }}
							formatter={(value) =>
								`${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
							}
							parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
							onChange={onChangeValue}
						/>
					</Form.Item>
				) : dataIndex === "quantity" &&
				  record.material !== "Aggregate Loads" ? (
					<div
						onClick={toggleEdit}
						style={{
							cursor: "pointer",
							display: "inline-block",
							width: "100%",
						}}
					>
						<Statistic
							valueStyle={{ fontSize: 14 }}
							value={children[1]}
							precision={4}
						/>
					</div>
				) : (
					<Statistic
						valueStyle={{ fontSize: 14 }}
						value={children[1]}
						precision={4}
					/>
				);
		}
		return <td {...restProps}>{childNode}</td>;
	};

	const handleSaveMissionTC2 = (row) => {
		const newData = [...dataSource];
		const index = newData.findIndex((item) => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, {
			...item,
			...row,
		});
		setDataSource(newData);
	};

	const columns = columnsNewQuantity.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave: handleSaveMissionTC2,
				mainData2: dataSource,
			}),
		};
	});

	const onSaveQuantity = async () => {
		setLoading(true);
		pcb(dataSource);
		setLoading(false);
	};

	const onFinish = () => {
		if (isSave) {
			onSaveQuantity();
		} else {
			openNotification("error", "Error", "You must fill all values");
		}
	};

	const CustomForm = () => {
		return (
			<Form
				form={form}
				layout="vertical"
				onFinish={onFinish}
				style={{ marginTop: "20px" }}
				initialValues={{
					number: undefined,
					psi: undefined,
				}}
			>
				<Row gutter={16}>
					<Col span={24}>
						<Text type="secondary">
							Please fill the material quantity (Quantity Field)
						</Text>
						<Table
							columns={columns}
							bordered
							pagination={false}
							components={{
								body: {
									cell: EditableCell,
									row: EditableRow,
								},
							}}
							dataSource={dataSource}
							rowClassName={() => "editable-row"}
						/>
					</Col>
				</Row>

				<Divider />

				<Row gutter={16}>
					<Col span={24} sm={12} md={4}>
						<Button
							type="primary"
							htmlType="submit"
							size="large"
							disabled={loading}
							loading={loading}
							style={{
								width: "100%",
							}}
						>
							Save
						</Button>
					</Col>
				</Row>
			</Form>
		);
	};

	return (
		<>
			{contextHolder}
			<CustomForm />
		</>
	);
};

export default AddCostMixDesign;

import {
	Button,
	Checkbox,
	Col,
	Form,
	Input,
	Popconfirm,
	Row,
	Select,
	notification,
	Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { api } from "../utils/Api";
import axios from "axios";
import { CustomSpinner } from "../utils/CustomComponents";
import { rolePouredOptions2 } from "../../constants/Poured";
const { Option } = Select;
const { Text } = Typography;

const Seller = (props) => {
	const [form] = Form.useForm();
	const [initialValues, setInitialValues] = useState({});
	const [loading, setLoading] = useState(false);
	const [enabledSelectSellers, setEnabledSelectSellers] = useState(false);
	const [loadingForm, setLoadingForm] = useState(true);
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [pouredSellers, setPouredSellers] = useState([]);
	const [apiNotification, contextHolder] = notification.useNotification();
	const [disabled, setDisabled] = useState(
		!(
			localStorage.getItem("email") === "ec@57concrete.com" ||
			localStorage.getItem("email") === "eliud@gmail.com" ||
			localStorage.getItem("email") === "jp@57concrete.com" ||
			localStorage.getItem("email") === "bp@57concrete.com" ||
			localStorage.getItem("email") === "bpa@57concrete.com" ||
			localStorage.getItem("email") === "vm@57concrete.com" ||
			localStorage.getItem("email") === "mgr@57concrete.com" ||
			localStorage.getItem("email") === "jv@57concrete.com" ||
			localStorage.getItem("email") === "ji@57concrete.com"
		)
	);

	useEffect(() => {
		getPouredSeller();
		getSeller();
	}, []);

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	const duplicatedUserEmail = async (email) => {
		const url = api + "/useremail/" + email;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			return data.found;
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const duplicatedUserPhone = async (phone) => {
		const url = api + `/userphone/+1${phone}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			return data.found;
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const getSeller = async () => {
		try {
			let url = api + `/users/${props.match.params.id}`;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

			const { data } = await axios.get(url);

			setInitialValues({
				...initialValues,
				name: data.name,
				phone: data.phone ? data.phone.substring(2) : "",
				email: data.email,
				corporateSales: data.corporateSales,
				isPouredUser: data.isPouredUser ? data.isPouredUser : false,
				isActivePouredUser: data.isActivePouredUser
					? data.isActivePouredUser
					: false,
				isCommercialTeam: data.isCommercialTeam ? data.isCommercialTeam : false,
				isResidentialTeam: data.isResidentialTeam
					? data.isResidentialTeam
					: false,
				pouredRole: data.pouredRole,
				pouredSellers: data.pouredSellers,
			});
			if (data.pouredRole === "Admin") {
				setEnabledSelectSellers(true);
			}
			setEmail(data.email);
			setPhone(data.phone);
			setLoading(false);
			setLoadingForm(false);
		} catch (err) {
			setLoading(false);
			openNotification("error", "Error", "Error while trying to get user");
			console.error(err.message);
			return null;
		}
	};

	const getPouredSeller = async () => {
		try {
			let url = api + `/user/sellers/poured/get`;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

			const { data } = await axios.get(url);

			if (data.success) {
				let list = [];
				data.payload.map((item) => {
					list.push({
						value: item._id,
						label: item.name,
					});
				});
				setPouredSellers(list);
			} else {
				setLoading(false);
				openNotification("error", "Error", data.message);
			}
		} catch (err) {
			setLoading(false);
			openNotification("error", "Error", "Error while trying to get sellers");
			console.error(err.message);
			return null;
		}
	};

	const updateSeller = async (data) => {
		let user = {
			name: data.name,
			email: data.email,
			phone: `+1${data.phone}`,
			corporateSales: data.corporateSales,
			isPouredUser: data.isPouredUser,
			isActivePouredUser: data.isActivePouredUser,
			isCommercialTeam: data.isCommercialTeam,
			isResidentialTeam: data.isResidentialTeam,
			pouredSellers: data.pouredSellers,
		};

		if (data.pouredRole !== "") {
			user.pouredRole = data.pouredRole;
		}

		if (
			data.password !== undefined &&
			data.password !== null &&
			data.password !== ""
		) {
			user.password = data.password;
		}

		try {
			let url = api + `/users/${props.match.params.id}`;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

			await axios.patch(url, user);
			openNotification("success", "Success", "Seller updated");
			setLoading(false);
			props.history.push("/sellerlist");
		} catch (err) {
			setLoading(false);
			openNotification("error", "Error", "Error while trying to update user");
			console.error(err.message);
			return null;
		}
	};

	const deleteSeller = async () => {
		setLoading(true);

		const url = api + "/sellerinfo/" + props.match.params.id;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			await axios.delete(url);
			openNotification("info", "Info", "Seller Deleted");
			setLoading(false);
			props.history.push("/sellerlist");
		} catch (err) {
			setLoading(false);
			console.error(err.message);
			return null;
		}
	};

	const validateUser = async (values) => {
		if ("+1" + values.phone === phone && values.email === email) {
			updateSeller(values);
		} else {
			const duplicadoEmail = await duplicatedUserEmail(values.email);
			const duplicadoPhone = await duplicatedUserPhone(values.phone);

			if (duplicadoEmail) {
				openNotification(
					"warning",
					"Duplicated user",
					"User already exist with Email"
				);
				setLoading(false);
			} else if (duplicadoPhone) {
				openNotification(
					"warning",
					"Duplicated user",
					"User already exist with Phone"
				);
				setLoading(false);
			} else {
				updateSeller(values);
			}
		}
	};

	const onChangeRole = (value) => {
		if (value === "Admin") {
			setEnabledSelectSellers(true);
		} else {
			setEnabledSelectSellers(false);
		}
	};

	const onFinish = (values) => {
		setLoading(true);
		validateUser(values);
	};

	const prefixSelector = (
		<Form.Item name="prefix" noStyle>
			<Select
				style={{
					width: 70,
				}}
				defaultValue={"+1"}
			>
				<Option value="+1">+1</Option>
			</Select>
		</Form.Item>
	);

	const CustomForm = () => {
		return (
			<Form
				form={form}
				layout="vertical"
				initialValues={initialValues}
				onFinish={onFinish}
			>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							label="Name"
							name="name"
							rules={[
								{
									required: true,
									message: "Please fill name",
								},
							]}
						>
							<Input size="large" disabled={disabled} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							label="Phone"
							name="phone"
							rules={[
								{
									required: true,
									message: "Please fill phone",
								},
								{
									len: 10,
									max: 10,
									message: "Phone number must have 10 numbers",
								},
							]}
						>
							<Input
								size="large"
								addonBefore={prefixSelector}
								disabled={disabled}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Email"
							name="email"
							rules={[
								{
									type: "email",
									message: "The input is not valid E-mail",
								},
								{
									required: true,
									message: "Please fill email",
								},
							]}
						>
							<Input size="large" disabled={disabled} />
						</Form.Item>
					</Col>
				</Row>
				{(localStorage.getItem("email") === "eliud@gmail.com" ||
					localStorage.getItem("email") === "ec@57concrete.com") && (
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								name="password"
								label="Change Password"
								rules={[
									{
										min: 5,
										message: "Password must have at least 5 characters",
									},
								]}
								hasFeedback
							>
								<Input.Password size="large" />
							</Form.Item>
						</Col>
					</Row>
				)}
				<Row gutter={16}>
					<Col span={8}>
						<Form.Item name="corporateSales" valuePropName="checked">
							<Checkbox disabled={disabled}>Corporate Sales</Checkbox>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item name="isCommercialTeam" valuePropName="checked">
							<Checkbox disabled={disabled}>Is Commercial Team?</Checkbox>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Form.Item name="isResidentialTeam" valuePropName="checked">
							<Checkbox disabled={disabled}>Is Residential Team?</Checkbox>
						</Form.Item>
					</Col>
				</Row>
				{(localStorage.getItem("email") === "ec@57concrete.com" ||
					localStorage.getItem("email") === "eliud@gmail.com" ||
					localStorage.getItem("email") === "jp@57concrete.com" ||
					localStorage.getItem("email") === "bp@57concrete.com" ||
					localStorage.getItem("email") === "bpa@57concrete.com" ||
					localStorage.getItem("email") === "vm@57concrete.com" ||
					localStorage.getItem("email") === "mgr@57concrete.com" ||
					localStorage.getItem("email") === "jh@57concrete.com" ||
					localStorage.getItem("email") === "jv@57concrete.com" ||
					localStorage.getItem("email") === "ji@57concrete.com") && (
					<Row gutter={16}>
						<Col span={24}>
							<Text strong>Poured Details</Text>
						</Col>
						<Col span={8}>
							<Form.Item name="isPouredUser" valuePropName="checked">
								<Checkbox>Is Poured User? (App)</Checkbox>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item name="isActivePouredUser" valuePropName="checked">
								<Checkbox>Is Active Poured User? (Poured Web)</Checkbox>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="pouredRole" label="Poured Role">
								<Select
									options={rolePouredOptions2}
									style={{ width: "100%" }}
									allowClear={false}
									onChange={onChangeRole}
								/>
							</Form.Item>
						</Col>
						{enabledSelectSellers && (
							<Col span={12}>
								<Form.Item name="pouredSellers" label="Poured Sellers">
									<Select
										mode="multiple"
										options={pouredSellers}
										style={{ width: "100%" }}
										allowClear={false}
									/>
								</Form.Item>
							</Col>
						)}
					</Row>
				)}
				<Row gutter={16}>
					<Col>
						<Button
							type="primary"
							htmlType="submit"
							size="large"
							loading={loading}
						>
							Save
						</Button>
					</Col>
					{/* <Col>
                    <Popconfirm
                        title="Delete the user"
                        description="Are you sure to delete this user?"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={deleteSeller}
                    >
                        <Button danger type='primary' htmlType='button' loading={loading}>Delete</Button>
                    </Popconfirm>
                </Col> */}
				</Row>
			</Form>
		);
	};

	return (
		<Layout title="Seller" description="Edit Seller" type="medium">
			{contextHolder}
			{loadingForm ? <CustomSpinner /> : <CustomForm />}
		</Layout>
	);
};

export default Seller;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { api } from "../utils/Api";
import { Typography, Flex, Button, Row, Statistic, Col } from "antd";
import Layout from "../layout/Layout";
import { CustomSpinner, CustomTable } from "../utils/CustomComponents";
import { EditFilled, PlusCircleFilled } from "@ant-design/icons";
const { Text, Title } = Typography;

const AggregateList = ({ history }) => {
	const [loading, setLoading] = useState(true);
	const [aggregateList, setAggregateList] = useState([]);

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "",
			dataIndex: "edit",
			width: 100,
			render: (_, record) => {
				return localStorage.getItem("email") === "ec@57concrete.com" ||
					localStorage.getItem("email") === "eliud@gmail.com" ||
					localStorage.getItem("email") === "jp@57concrete.com" ||
					localStorage.getItem("email") === "vm@57concrete.com" ||
					localStorage.getItem("email") === "ea@57concrete.com" ||
					localStorage.getItem("email") === "ss@57concrete.com" ||
					localStorage.getItem("email") === "fc@57concrete.com" ? (
					<Button
						type="primary"
						icon={<EditFilled twoToneColor={"white"} />}
						onClick={(e) => history.push(`/aggregate/${record.id}`)}
						size="large"
					/>
				) : (
					""
				);
			},
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Psi",
			dataIndex: "psi",
			key: "psi",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Cogs",
			dataIndex: "cogs",
			key: "cogs",
			render: (value) => {
				return value.map((cog, index) => {
					return (
						<Row gutter={16} key={index}>
							<Col span={24}>
								<Title level={5}>{cog.cog.material}</Title>
							</Col>
							<Col>
								<Text>
									{" "}
									Quantity{" "}
									<Statistic
										title=""
										value={cog.quantity}
										valueStyle={{ fontSize: 14 }}
									/>
								</Text>
							</Col>
							<Col>
								<Text>
									{" "}
									Subtotal{" "}
									<Statistic
										title=""
										value={cog.quantity * cog.cog.unitPriceConversion}
										prefix={"$"}
										precision={6}
										valueStyle={{ fontSize: 14 }}
									/>
								</Text>
							</Col>
						</Row>
					);
				});
			},
		},
		{
			title: "Total",
			dataIndex: "total",
			key: "total",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={6}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
	];

	useEffect(() => {
		getAggregateList();
	}, []);

	const calculateTotal = (cogs) => {
		let accum = 0;
		cogs.map((item) => {
			if (item.cog) {
				accum += item.quantity * item.cog.unitPriceConversion;
			}
		});
		return accum;
	};

	const getAggregateList = async () => {
		setLoading(true);

		const url = api + "/aggregate";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			data.map((item, index) => {
				list.push({
					key: index,
					id: item._id,
					name: item.name,
					psi: item.psi ? item.psi.psi : "N/A",
					cogs: item.cogs,
					total:
						item.cogs.length > 0
							? `${calculateTotal(item.cogs).toFixed(6)}`
							: 0,
				});
			});
			setAggregateList(list);
			setLoading(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const addNewAggregate = () => {
		history.push("/newaggregate");
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				{(localStorage.getItem("email") === "ec@57concrete.com" ||
					localStorage.getItem("email") === "eliud@gmail.com" ||
					localStorage.getItem("email") === "jp@57concrete.com" ||
					localStorage.getItem("email") === "ea@57concrete.com" ||
					localStorage.getItem("email") === "ss@57concrete.com" ||
					localStorage.getItem("email") === "vm@57concrete.com") && (
					<Button
						onClick={addNewAggregate}
						type="primary"
						style={{
							width: 170,
						}}
						icon={<PlusCircleFilled />}
						size="large"
					>
						New Aggregate
					</Button>
				)}
				<CustomTable columns={columns} data={aggregateList} />
			</Flex>
		);
	};

	return (
		<Layout title="Aggregate" description="Aggregate List">
			<Flex vertical gap={"large"}>
				{loading ? <CustomSpinner /> : <RenderTable />}
			</Flex>
		</Layout>
	);
};

export default AggregateList;

import {
	Button,
	Col,
	Divider,
	Form,
	Input,
	InputNumber,
	Modal,
	Popconfirm,
	Row,
	Select,
	Statistic,
	Table,
	Tag,
	Typography,
	notification,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import Layout from "../layout/Layout";
import { api } from "../utils/Api";
import axios from "axios";
import ModalCreateNewPsi from "../psi/ModalCreateNewPsi";
import {
	PlusOutlined,
	RollbackOutlined,
	CalculatorOutlined,
} from "@ant-design/icons";
import AddCostMixDesign from "./AddCostMixDesign";

const { Title, Text } = Typography;
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
	const [form] = Form.useForm();
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	);
};

/**
 ** Options header (columns)
 */
const columnsNewCost = [
	{
		title: "Material",
		dataIndex: "material",
		key: "material",
	},
	{
		title: "Invoice U/M Price",
		dataIndex: "invoicePrice",
		key: "invoicePrice",
		editable: true,
	},
	{
		title: "U/M",
		dataIndex: "um",
		key: "um",
	},
	{
		title: "Unit Price",
		dataIndex: "unitPrice",
		key: "unitPrice",
		render: (value) => {
			const isNumeric = typeof value === "number" && !isNaN(value);
			return (
				<Statistic
					valueStyle={{
						fontSize: 14,
						backgroundColor: isNumeric ? undefined : "red",
					}}
					value={value}
					precision={4}
					prefix={"$"}
				/>
			);
		},
	},
	{
		title: "Unit of Measurement",
		dataIndex: "unitOfMeasurement",
		key: "unitOfMeasurement",
	},
];

const columnsNewQuantity = [
	{
		title: "Material",
		dataIndex: "material",
		key: "material",
	},
	{
		title: "Quantity",
		dataIndex: "quantity",
		key: "quantity",
		render: (value) => {
			return (
				<Statistic valueStyle={{ fontSize: 14 }} value={value} precision={4} />
			);
		},
	},
	{
		title: "Total",
		dataIndex: "total",
		key: "total",
		render: (value) => {
			return (
				<Statistic
					valueStyle={{ fontSize: 14 }}
					value={value}
					precision={4}
					prefix={"$"}
				/>
			);
		},
	},
];

const costRowsData = [
	{
		key: "0",
		material: "AD Nr",
		invoicePrice: 0,
		um: "gl",
		unitPrice: 0,
		unitOfMeasurement: "oz",
	},
	{
		key: "1",
		material: "AD SP",
		invoicePrice: 0,
		um: "gl",
		unitPrice: 0,
		unitOfMeasurement: "oz",
	},
	{
		key: "2",
		material: "AD X15",
		invoicePrice: 0,
		um: "gl",
		unitPrice: 0,
		unitOfMeasurement: "oz",
	},
	{
		key: "3",
		material: "AD SE",
		invoicePrice: 0,
		um: "gl",
		unitPrice: 0,
		unitOfMeasurement: "oz",
	},
	{
		key: "4",
		material: "AREA 92",
		invoicePrice: 0,
		um: "gl",
		unitPrice: 0,
		unitOfMeasurement: "oz",
	},
	{
		key: "5",
		material: "Monofilament Fiber",
		invoicePrice: 0,
		um: "lb",
		unitPrice: 0,
		unitOfMeasurement: "lb",
	},
	{
		key: "6",
		material: "Sika Plast 500",
		invoicePrice: 0,
		um: "gl",
		unitPrice: 0,
		unitOfMeasurement: "oz",
	},
	{
		key: "7",
		material: "Plastiment XR",
		invoicePrice: 0,
		um: "gl",
		unitPrice: 0,
		unitOfMeasurement: "oz",
	},
	{
		key: "8",
		material: "Cem 1",
		invoicePrice: 0,
		um: "ton",
		unitPrice: 0,
		unitOfMeasurement: "lb",
	},
	{
		key: "9",
		material: "Cement Loads",
		invoicePrice: 0,
		um: "ton",
		unitPrice: 0,
		unitOfMeasurement: "lb",
	},
	{
		key: "10",
		material: "Gravel 57",
		invoicePrice: 0,
		um: "ton",
		unitPrice: 0,
		unitOfMeasurement: "lb",
	},
	{
		key: "11",
		material: "Limestone Gravel",
		invoicePrice: 0,
		um: "ton",
		unitPrice: 0,
		unitOfMeasurement: "lb",
	},
	{
		key: "12",
		material: "Pea Gravel",
		invoicePrice: 0,
		um: "ton",
		unitPrice: 0,
		unitOfMeasurement: "lb",
	},
	{
		key: "13",
		material: "Sand",
		invoicePrice: 0,
		um: "ton",
		unitPrice: 0,
		unitOfMeasurement: "lb",
	},
	{
		key: "14",
		material: "Aggregate Loads",
		invoicePrice: 0,
		um: "ton",
		unitPrice: 0,
		unitOfMeasurement: "lb",
	},
	{
		key: "15",
		material: "Wat 1",
		invoicePrice: 0,
		um: "gl",
		unitPrice: 0,
		unitOfMeasurement: "gl",
	},
];
const quantityRowsData = [
	{
		key: "0",
		material: "AD Nr",
		quantity: 0,
		total: 0,
	},
	{
		key: "1",
		material: "AD SP",
		quantity: 0,
		total: 0,
	},
	{
		key: "2",
		material: "AD X15",
		quantity: 0,
		total: 0,
	},
	{
		key: "3",
		material: "AD SE",
		quantity: 0,
		total: 0,
	},
	{
		key: "4",
		material: "AREA 92",
		quantity: 0,
		total: 0,
	},
	{
		key: "5",
		material: "Monofilament Fiber",
		quantity: 0,
		total: 0,
	},
	{
		key: "6",
		material: "Sika Plast 500",
		quantity: 0,
		total: 0,
	},
	{
		key: "7",
		material: "Plastiment XR",
		quantity: 0,
		total: 0,
	},
	{
		key: "8",
		material: "Cem 1",
		quantity: 0,
		total: 0,
	},
	{
		key: "9",
		material: "Cement Loads",
		quantity: 0,
		total: 0,
	},
	{
		key: "10",
		material: "Gravel 57",
		quantity: 0,
		total: 0,
	},
	{
		key: "11",
		material: "Limestone Gravel",
		quantity: 0,
		total: 0,
	},
	{
		key: "12",
		material: "Pea Gravel",
		quantity: 0,
		total: 0,
	},
	{
		key: "13",
		material: "Sand",
		quantity: 0,
		total: 0,
	},
	{
		key: "14",
		material: "Aggregate Loads",
		quantity: 0,
		total: 0,
	},
	{
		key: "15",
		material: "Wat 1",
		quantity: 0,
		total: 0,
	},
];

const columnsHistory = [
	{
		title: "Date",
		dataIndex: "date",
		key: "date",
		render: (value) => {
			return <Text>{value}</Text>;
		},
	},
	{
		title: "User",
		dataIndex: "user",
		key: "user",
		render: (value) => {
			return <Text>{value}</Text>;
		},
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
		render: (value) => {
			return <Tag color={value === "Creation" ? "green" : "blue"}>{value}</Tag>;
		},
	},
];

const MixDesignCost = ({ history, match }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [loadingPsi, setLoadingPsi] = useState(false);
	const [openNewPsiModal, setOpenNewPsiModal] = useState(false);
	const [openCalculateModal, setOpenCalculateModal] = useState(false);

	const [isSave, setIsSave] = useState(true);
	const [optionsPsi, setOptionsPsi] = useState([]);
	const [newHistory, setHistory] = useState([]);
	const [initialValues, setInitialValues] = useState({});
	const [apiNotification, contextHolder] = notification.useNotification();
	const [missionTC, setMissionTC] = useState([
		...costRowsData.map((item) => {
			return { ...item, key: "missionTC" + item.key };
		}),
	]);
	const [missionTC2, setMissionTC2] = useState([
		...quantityRowsData.map((item) => {
			return { ...item, key: "missionTC2" + item.key };
		}),
	]);
	const [missionSTC, setMissionSTC] = useState([
		...costRowsData.map((item) => {
			return { ...item, key: "missionSTC" + item.key };
		}),
	]);
	const [missionSTC2, setMissionSTC2] = useState([
		...quantityRowsData.map((item) => {
			return { ...item, key: "missionSTC2" + item.key };
		}),
	]);
	const [sanbenitoTC, setSanBenitoTC] = useState([
		...costRowsData.map((item) => {
			return { ...item, key: "sanbenitoTC" + item.key };
		}),
	]);
	const [sanbenitoTC2, setSanBenitoTC2] = useState([
		...quantityRowsData.map((item) => {
			return { ...item, key: "sanbenitoTC2" + item.key };
		}),
	]);
	const [sanbenitoSTC, setSanBenitoSTC] = useState([
		...costRowsData.map((item) => {
			return { ...item, key: "sanbenitoSTC" + item.key };
		}),
	]);
	const [sanbenitoSTC2, setSanBenitoSTC2] = useState([
		...quantityRowsData.map((item) => {
			return { ...item, key: "sanbenitoSTC2" + item.key };
		}),
	]);

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	useEffect(() => {
		getValues();
	}, []);

	const getValues = async () => {
		setLoading(true);
		await Promise.all([getPsi(), getMixDesign()]).then(() => {
			setLoading(false);
		});
	};

	const getPsi = async (type) => {
		const url = api + "/psi";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			data.map((item) => {
				list.push({
					value: item._id,
					label: item.psi,
				});
			});
			setOptionsPsi(list);
			if (type === "load") setLoadingPsi(false);
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", "Error while trying to get psi list");
			return null;
		}
	};

	const getMixDesign = async () => {
		try {
			const url = api + `/mixdesigncost/get/${match.params.id}`;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

			const { data } = await axios.get(url);
			if (data.success) {
				const payload = data.payload;
				setInitialValues({
					number: payload.number,
					psi: payload.psi,
				});
				setHistory(
					payload.history.map((item, index) => {
						return {
							user: item.user.name,
							date: Intl.DateTimeFormat("en-US").format(
								new Date(item.createdAt)
							),
							status: item.status,
							key: index,
						};
					})
				);

				let mission1 = missionTC.map((item) => {
					return {
						...item,
						invoicePrice: payload.missionTCCost.find(
							(i) => i.material === item.material
						).invoicePrice,
						unitPrice: payload.missionTCCost.find(
							(i) => i.material === item.material
						).unitPrice,
					};
				});
				setMissionTC(mission1);
				let mission2 = missionTC2.map((item) => {
					return {
						...item,
						quantity: payload.missionTCQuantity.find(
							(i) => i.material === item.material
						).quantity,
						total: payload.missionTCQuantity.find(
							(i) => i.material === item.material
						).total,
					};
				});
				setMissionTC2(mission2);

				let mission3 = missionSTC.map((item) => {
					return {
						...item,
						invoicePrice: payload.missionSTCCost.find(
							(i) => i.material === item.material
						).invoicePrice,
						unitPrice: payload.missionSTCCost.find(
							(i) => i.material === item.material
						).unitPrice,
					};
				});
				setMissionSTC(mission3);
				let mission4 = missionSTC2.map((item) => {
					return {
						...item,
						quantity: payload.missionSTCQuantity.find(
							(i) => i.material === item.material
						).quantity,
						total: payload.missionSTCQuantity.find(
							(i) => i.material === item.material
						).total,
					};
				});
				setMissionSTC2(mission4);

				let sanbenito1 = sanbenitoTC.map((item) => {
					return {
						...item,
						invoicePrice: payload.sanBenitoTCCost.find(
							(i) => i.material === item.material
						).invoicePrice,
						unitPrice: payload.sanBenitoTCCost.find(
							(i) => i.material === item.material
						).unitPrice,
					};
				});
				setSanBenitoTC(sanbenito1);
				let sanbenito2 = sanbenitoTC2.map((item) => {
					return {
						...item,
						quantity: payload.sanBenitoTCQuantity.find(
							(i) => i.material === item.material
						).quantity,
						total: payload.sanBenitoTCQuantity.find(
							(i) => i.material === item.material
						).total,
					};
				});
				setSanBenitoTC2(sanbenito2);

				let sanbenito3 = sanbenitoSTC.map((item) => {
					return {
						...item,
						invoicePrice: payload.sanBenitoSTCCost.find(
							(i) => i.material === item.material
						).invoicePrice,
						unitPrice: payload.sanBenitoSTCCost.find(
							(i) => i.material === item.material
						).unitPrice,
					};
				});
				setSanBenitoSTC(sanbenito3);
				let sanbenito4 = sanbenitoSTC2.map((item) => {
					return {
						...item,
						quantity: payload.sanBenitoSTCQuantity.find(
							(i) => i.material === item.material
						).quantity,
						total: payload.sanBenitoSTCQuantity.find(
							(i) => i.material === item.material
						).total,
					};
				});
				setSanBenitoSTC2(sanbenito4);
			} else {
				openNotification("error", "Error", data.message);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			openNotification(
				"error",
				"Error",
				"Error while trying to get last mix design cost"
			);
			console.error(err.message);
			return null;
		}
	};

	const EditableCell = ({
		title,
		editable,
		children,
		dataIndex,
		record,
		handleSave,
		...restProps
	}) => {
		const [editing, setEditing] = useState(false);
		const inputRef = useRef(null);
		const form = useContext(EditableContext);

		useEffect(() => {
			if (editing) {
				inputRef.current?.focus();
			}
		}, [editing]);

		const toggleEdit = () => {
			setEditing(!editing);
			form.setFieldsValue({
				[dataIndex]: record[dataIndex],
			});
		};

		const save = async () => {
			try {
				const values = await form.validateFields();
				toggleEdit();
				handleSave({
					...record,
					...values,
				});
				setIsSave(true);
			} catch (errInfo) {
				setIsSave(false);

				openNotification("error", "Error", "You must fill the input.");
			}
		};

		const onChangeValue = (value) => {
			let unitPrice = 0;
			if (record.um === "gl" && record.unitOfMeasurement === "oz") {
				unitPrice = value / 128;
			} else if (record.um === "ton" && record.unitOfMeasurement === "lb") {
				unitPrice = value / 2000;
			} else {
				unitPrice = value;
			}
			record.unitPrice = unitPrice;
		};

		let childNode = children;
		if (editable) {
			childNode = editing ? (
				<Form.Item
					style={{
						margin: 0,
					}}
					name={dataIndex}
					rules={[
						{
							required: true,
							message: `Invoice U/M Price is required.`,
						},
					]}
				>
					<InputNumber
						ref={inputRef}
						onPressEnter={save}
						onBlur={save}
						style={{ width: "100%" }}
						formatter={(value) =>
							`$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						}
						parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
						onChange={onChangeValue}
					/>
				</Form.Item>
			) : dataIndex === "invoicePrice" ? (
				<div
					onClick={toggleEdit}
					style={{ cursor: "pointer", display: "inline-block", width: "100%" }}
				>
					<Statistic
						valueStyle={{ fontSize: 14 }}
						value={children[1]}
						precision={4}
						prefix={"$"}
					/>
				</div>
			) : (
				<div
					className="editable-cell-value-wrap"
					style={{
						paddingInlineEnd: 24,
					}}
					onClick={toggleEdit}
				>
					{children}
				</div>
			);
		}
		return <td {...restProps}>{childNode}</td>;
	};

	const handleSaveMissionTC1 = (row) => {
		const newData = [...missionTC];
		const index = newData.findIndex((item) => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, {
			...item,
			...row,
		});
		setMissionTC(newData);
	};

	const handleSaveMissionSTC1 = (row) => {
		const newData = [...missionSTC];
		const index = newData.findIndex((item) => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, {
			...item,
			...row,
		});
		setMissionSTC(newData);
	};

	const handleSaveSanBenitoTC1 = (row) => {
		const newData = [...sanbenitoTC];
		const index = newData.findIndex((item) => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, {
			...item,
			...row,
		});
		setSanBenitoTC(newData);
	};

	const handleSaveSanBenitoSTC1 = (row) => {
		const newData = [...sanbenitoSTC];
		const index = newData.findIndex((item) => row.key === item.key);
		const item = newData[index];
		newData.splice(index, 1, {
			...item,
			...row,
		});
		setSanBenitoSTC(newData);
	};

	const columnsMissionTC = columnsNewCost.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave: handleSaveMissionTC1,
			}),
		};
	});

	const columnsMissionSTC = columnsNewCost.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave: handleSaveMissionSTC1,
			}),
		};
	});

	const columnsSanBenitoTC = columnsNewCost.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave: handleSaveSanBenitoTC1,
			}),
		};
	});

	const columnsSanBenitoSTC = columnsNewCost.map((col) => {
		if (!col.editable) {
			return col;
		}
		return {
			...col,
			onCell: (record) => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave: handleSaveSanBenitoSTC1,
			}),
		};
	});

	const updateNewMixDesign = async (values) => {
		setLoading(true);
		const body = {
			missionTCCost: missionTC,
			missionTCQuantity: missionTC2,
			missionSTCCost: missionSTC,
			missionSTCQuantity: missionSTC2,
			sanBenitoTCCost: sanbenitoTC,
			sanBenitoTCQuantity: sanbenitoTC2,
			sanBenitoSTCCost: sanbenitoSTC,
			sanBenitoSTCQuantity: sanbenitoSTC2,
			psi: values.psi,
		};

		try {
			const url = api + `/mixdesigncost/update/${match.params.id}`;
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

			const { data } = await axios.patch(url, body);
			if (data.success) {
				openNotification("success", "Success", data.message);

				setTimeout(() => {
					history.push("/mixdesign/cost/list");
				}, 2000);
			} else {
				openNotification("error", "Error", data.message);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			openNotification(
				"error",
				"Error",
				"Error while trying to create mix design cost"
			);
			console.error(err.message);
			return null;
		}
	};

	const onFinish = (values) => {
		if (isSave) {
			updateNewMixDesign(values);
		} else {
			openNotification("error", "Error", "You must fill all values");
		}
	};

	const summary = (pageData) => {
		let currentTotal = 0;

		pageData.forEach(({ total }) => {
			currentTotal += total;
		});

		return (
			<>
				<Table.Summary.Row>
					<Table.Summary.Cell>
						<Text style={{ fontSize: 14 }} strong>
							{"Total"}
						</Text>
					</Table.Summary.Cell>
					<Table.Summary.Cell></Table.Summary.Cell>
					<Table.Summary.Cell>
						<Statistic
							title=""
							value={currentTotal}
							style={{ fontWeight: "bold" }}
							precision={4}
							prefix={"$"}
							valueStyle={{ fontSize: 14 }}
						/>
					</Table.Summary.Cell>
				</Table.Summary.Row>
			</>
		);
	};

	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const handleNewPsiOpen = () => {
		setOpenNewPsiModal(true);
	};

	const handleNewPsiCancel = () => {
		setOpenNewPsiModal(false);
	};

	const onNewPsiNew = async (newPsi) => {
		handleNewPsiCancel();
		setLoadingPsi(true);
		await getPsi("load");
		form.setFieldValue("psi", newPsi);
	};

	const RenderNewPsiModal = () => {
		return (
			<Modal
				title="New Psi"
				open={openNewPsiModal}
				onCancel={handleNewPsiCancel}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				width={600}
			>
				<ModalCreateNewPsi pcb={onNewPsiNew} />
			</Modal>
		);
	};

	const deleteMixDesign = async () => {
		setLoading(true);

		const url = api + `/mixdesigncost/delete/${match.params.id}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.delete(url);
			if (data.success) {
				openNotification("info", "Info", data.message);

				setTimeout(() => {
					history.push("/mixdesign/cost/list");
				}, 2000);
			} else {
				openNotification("error", "Error", data.message);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			console.error(err.message);
			return null;
		}
	};

	const handleCalculateOpen = () => {
		setOpenCalculateModal(true);
	};

	const handleCalculateCancel = () => {
		setOpenCalculateModal(false);
	};

	const onSaveCalculate = async (dataSource) => {
		handleCalculateCancel();

		const newData1 = [...missionTC2];
		const newData2 = [...missionSTC2];
		const newData3 = [...sanbenitoTC2];
		const newData4 = [...sanbenitoSTC2];

		dataSource.map((item) => {
			const index1 = newData1.findIndex(
				(item2) => item2.material === item.material
			);
			newData1[index1].quantity = item.quantity;
			let crm1 = missionTC.find((i) => i.material === item.material);
			newData1[index1].total = item.quantity * crm1.unitPrice;

			const index2 = newData2.findIndex(
				(item2) => item2.material === item.material
			);
			newData2[index2].quantity = item.quantity;
			let crm2 = missionSTC.find((i) => i.material === item.material);
			newData2[index2].total = item.quantity * crm2.unitPrice;

			const index3 = newData3.findIndex(
				(item2) => item2.material === item.material
			);
			newData3[index3].quantity = item.quantity;
			let crm3 = sanbenitoTC.find((i) => i.material === item.material);
			newData3[index3].total = item.quantity * crm3.unitPrice;

			const index4 = newData4.findIndex(
				(item2) => item2.material === item.material
			);
			newData4[index4].quantity = item.quantity;
			let crm4 = sanbenitoSTC.find((i) => i.material === item.material);
			newData4[index4].total = item.quantity * crm4.unitPrice;
		});
		setMissionTC2(newData1);
		setMissionSTC2(newData2);
		setSanBenitoTC2(newData3);
		setSanBenitoSTC2(newData4);

		openNotification(
			"info",
			"Info",
			"The New Mix is not saved yet. Please press the button 'Save' to save changes."
		);
	};

	const RenderCalculateModal = () => {
		return (
			<Modal
				title="Calculate"
				open={openCalculateModal}
				onCancel={handleCalculateCancel}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				width={600}
			>
				<AddCostMixDesign pcb={onSaveCalculate} />
			</Modal>
		);
	};

	const backToList = () => {
		history.push("/mixdesign/cost/list");
	};

	const CustomForm = () => {
		return (
			<Form
				form={form}
				layout="vertical"
				onFinish={onFinish}
				style={{ marginTop: "20px" }}
				initialValues={initialValues}
			>
				<Row gutter={16}>
					<Col span={24} sm={12} md={4}>
						<Button
							type="default"
							htmlType="button"
							size="large"
							style={{
								width: "100%",
							}}
							icon={<RollbackOutlined />}
							onClick={backToList}
						>
							Go Back
						</Button>
					</Col>
					<Col span={24} sm={12} md={4}>
						<Button
							type="primary"
							htmlType="submit"
							disabled={loading}
							loading={loading}
							size="large"
							style={{
								width: "100%",
							}}
						>
							Save
						</Button>
					</Col>
				</Row>
				<Row gutter={16} align={"middle"}>
					<Col span={24} md={4}>
						<Form.Item label="Number ID" name="number">
							<Input size="large" disabled />
						</Form.Item>
					</Col>
					<Col span={24} md={12}>
						<Form.Item
							label="Psi"
							name="psi"
							rules={[
								{
									required: true,
									message: "Please select psi",
								},
							]}
						>
							<Select
								size="large"
								options={optionsPsi}
								placeholder="Please select psi"
								filterOption={filterOption}
								optionFilterProp="children"
								showSearch
								disabled={loading || loadingPsi}
								loading={loading || loadingPsi}
							/>
						</Form.Item>
					</Col>
					<Col span={24} md={6} lg={4}>
						<Button
							size="large"
							type="primary"
							htmlType="button"
							style={{ width: "100%" }}
							icon={<PlusOutlined />}
							loading={loading || loadingPsi}
							disabled={loading || loadingPsi}
							onClick={handleNewPsiOpen}
						>
							New Psi
						</Button>
					</Col>
					<Col span={24} md={6} lg={4}>
						<Button
							size="large"
							type="primary"
							htmlType="button"
							style={{ width: "100%" }}
							icon={<CalculatorOutlined />}
							disabled={loading}
							onClick={handleCalculateOpen}
							danger
						>
							Calculate
						</Button>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24}>
						<Title level={3}>Mission TC</Title>
					</Col>
					<Col span={24} lg={12}>
						<Text type="secondary">
							Please fill material cost values (Invoice U/M Price Field)
						</Text>
						<Table
							columns={columnsMissionTC}
							bordered
							pagination={false}
							components={{
								body: {
									cell: EditableCell,
									row: EditableRow,
								},
							}}
							dataSource={missionTC}
							rowClassName={() => "editable-row"}
						/>
					</Col>
					<Col span={24} lg={12}>
						<Text type="secondary">Material Quantity/Cost</Text>
						<Table
							columns={columnsNewQuantity}
							bordered
							pagination={false}
							dataSource={missionTC2}
							summary={summary}
						/>
					</Col>
				</Row>
				<Divider />
				<Row gutter={16}>
					<Col span={24}>
						<Title level={3}>Mission STC</Title>
					</Col>
					<Col span={24} lg={12}>
						<Text type="secondary">
							Please fill material cost values (Invoice U/M Price Field)
						</Text>
						<Table
							columns={columnsMissionSTC}
							bordered
							pagination={false}
							components={{
								body: {
									cell: EditableCell,
									row: EditableRow,
								},
							}}
							dataSource={missionSTC}
							rowClassName={() => "editable-row"}
						/>
					</Col>
					<Col span={24} lg={12}>
						<Text type="secondary">Material Quantity/Cost</Text>
						<Table
							columns={columnsNewQuantity}
							bordered
							pagination={false}
							dataSource={missionSTC2}
							summary={summary}
						/>
					</Col>
				</Row>
				<Divider />
				<Row gutter={16}>
					<Col span={24}>
						<Title level={3}>San Benito TC</Title>
					</Col>
					<Col span={24} lg={12}>
						<Text type="secondary">
							Please fill material cost values (Invoice U/M Price Field)
						</Text>
						<Table
							columns={columnsSanBenitoTC}
							bordered
							pagination={false}
							components={{
								body: {
									cell: EditableCell,
									row: EditableRow,
								},
							}}
							dataSource={sanbenitoTC}
							rowClassName={() => "editable-row"}
						/>
					</Col>
					<Col span={24} lg={12}>
						<Text type="secondary">Material Quantity/Cost</Text>
						<Table
							columns={columnsNewQuantity}
							bordered
							pagination={false}
							dataSource={sanbenitoTC2}
							summary={summary}
						/>
					</Col>
				</Row>
				<Divider />
				<Row gutter={16}>
					<Col span={24}>
						<Title level={3}>San Benito STC</Title>
					</Col>
					<Col span={24} lg={12}>
						<Text type="secondary">
							Please fill material cost values (Invoice U/M Price Field)
						</Text>
						<Table
							columns={columnsSanBenitoSTC}
							bordered
							pagination={false}
							components={{
								body: {
									cell: EditableCell,
									row: EditableRow,
								},
							}}
							dataSource={sanbenitoSTC}
							rowClassName={() => "editable-row"}
						/>
					</Col>
					<Col span={24} lg={12}>
						<Text type="secondary">Material Quantity/Cost</Text>
						<Table
							columns={columnsNewQuantity}
							bordered
							pagination={false}
							dataSource={sanbenitoSTC2}
							summary={summary}
						/>
					</Col>
				</Row>
				<Divider />
				<Row gutter={16}>
					<Col span={24}>
						<Title level={3}>History</Title>
					</Col>
					<Col span={24}>
						<Table
							columns={columnsHistory}
							bordered
							pagination={false}
							dataSource={newHistory}
						/>
					</Col>
				</Row>
				&nbsp;
				<Row gutter={16}>
					<Col span={24} sm={12} md={4}>
						<Button
							type="primary"
							htmlType="submit"
							loading={loading}
							disabled={loading}
							size="large"
							style={{
								width: "100%",
							}}
						>
							Save
						</Button>
					</Col>
					<Col span={24} sm={12} md={4}>
						<Popconfirm
							title="Delete the mix design"
							description="Are you sure to delete this mix design?"
							okText="Yes"
							cancelText="No"
							onConfirm={deleteMixDesign}
						>
							<Button
								type="primary"
								htmlType="button"
								loading={loading}
								disabled={loading}
								size="large"
								style={{
									width: "100%",
								}}
								danger
							>
								Delete
							</Button>
						</Popconfirm>
					</Col>
				</Row>
			</Form>
		);
	};

	return (
		<Layout
			title="Mix Design Cost"
			description="Update mix design attributes"
			type="large"
		>
			{contextHolder}
			<CustomForm />
			<RenderNewPsiModal />
			<RenderCalculateModal />
		</Layout>
	);
};

export default MixDesignCost;

import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import { api } from "../utils/Api";

const ClientPaymentPicker = ({ selectedOption, action, required }) => {
	const [selOption, setSelOption] = useState(selectedOption);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		getClients();
	}, []);

	const getClients = async () => {
		const url = api + "/allcustomers";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);

			const optionsAux = [];

			data.forEach((customer) => {
				let value = `${customer._id},${customer.paymentType},${customer.paymentTerm}`; // CustomerID,PaymentType, payment term
				let aux = `${customer.customerNumber} ${customer.name}`;
				let label = aux.toUpperCase();

				let obj = {
					value: value,
					label: label,
				};

				optionsAux.push(obj);
			});

			setOptions(optionsAux);
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	return (
		<div>
			{options.length > 0 ? (
				<Select
					name="form-field-name"
					placeholder="Customer..."
					value={options.filter((option) => {
						const aux = option.value.split(",");
						return aux[0] === selectedOption;
					})}
					onChange={action}
					isClearable={true}
					isSearchable={true}
					options={options}
				/>
			) : (
				<Select name="form-field-name" placeholder="Loading..." />
			)}
			{required && (
				<input
					tabIndex={-1}
					autoComplete="off"
					style={{ opacity: 0, height: 0 }}
					value={selectedOption}
					required={true}
				/>
			)}
		</div>
	);
};

export default ClientPaymentPicker;

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { api } from "../utils/Api";
import { Typography, Flex, Statistic, Row, Col, Card, Button } from "antd";
import Layout from "../layout/Layout";
import {
	CustomSearch,
	CustomSpinner,
	CustomTable,
} from "../utils/CustomComponents";
import dayjs from "dayjs";
import { TruckFilled, ArrowUpOutlined } from "@ant-design/icons";
import CountUp from "react-countup";
import { CSVLink } from "react-csv";
const { Text, Title } = Typography;

const columnsCSV = [
	{ key: "mine", label: "Mine" },
	{ key: "truck", label: "Truck" },
	{ key: "driver", label: "Driver" },
	{ key: "quantity", label: "Quantity" },
	{ key: "ticket", label: "Ticket" },
	{ key: "material", label: "Material" },
	{ key: "plant", label: "Plant" },
	{ key: "startedDate", label: "Start Date" },
	{ key: "receivedDate", label: "Received Date" },
	{ key: "duration", label: "Duration" },
];

const LogisticsReportTotalGeneralTrips = () => {
	const [loading, setLoading] = useState(true);
	const [startDate, setStartDate] = useState(dayjs());
	const [endDate, setEndDate] = useState(dayjs());
	const [list, setList] = useState([]);
	const [quantitySanBenito, setQuantitySanBenito] = useState(0);
	const [quantityMission, setQuantityMission] = useState(0);
	const [quantityLaredo, setQuantityLaredo] = useState(0);
	const [tripsSanBenito, setTripsSanBenito] = useState(0);
	const [tripsMission, setTripsMission] = useState(0);
	const [tripsLaredo, setTripsLaredo] = useState(0);
	const refDownload = useRef();

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "Mine",
			dataIndex: "mine",
			key: "mine",
			render: (value) => {
				return <Text strong>{value}</Text>;
			},
		},
		{
			title: "Truck",
			dataIndex: "truck",
			key: "truck",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Driver",
			dataIndex: "driver",
			key: "driver",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Quantity (ton)",
			dataIndex: "quantity",
			key: "quantity",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Ticket",
			dataIndex: "ticket",
			key: "ticket",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Material",
			dataIndex: "material",
			key: "material",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Plant",
			dataIndex: "plant",
			key: "plant",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Started Date",
			dataIndex: "startedDate",
			key: "startedDate",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Received Date",
			dataIndex: "receivedDate",
			key: "receivedDate",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Duration",
			dataIndex: "duration",
			key: "duration",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
	];

	useEffect(() => {
		getLogisticsList();
	}, [startDate, endDate]);

	const getLogisticsList = async () => {
		setLoading(true);
		setQuantityMission(0);
		setTripsMission(0);
		setQuantitySanBenito(0);
		setTripsSanBenito(0);

		const start = `${startDate.year()}-${
			startDate.month() + 1
		}-${startDate.date()}`;
		const end = `${endDate.year()}-${endDate.month() + 1}-${endDate.date()}`;

		const url =
			api + `/logisticstrip/list/dates?startDate=${start}&endDate=${end}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);

			let list = [];
			data.map((item, index) => {
				let duration = "0";
				if (item.startedDate && item.receivedDate) {
					var diffMs = new Date(item.receivedDate) - new Date(item.startedDate);
					var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
					var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
					duration = `${diffHrs} hours, ${diffMins} minutes`;
				}

				if (item.plant.name === "San Benito") {
					setQuantitySanBenito((prev) => prev + item.quantity);
					setTripsSanBenito((prev) => prev + 1);
				}

				if (item.plant.name === "Mission") {
					setQuantityMission((prev) => prev + item.quantity);
					setTripsMission((prev) => prev + 1);
				}

				if (item.plant.name === "Laredo") {
					setQuantityLaredo((prev) => prev + item.quantity);
					setTripsLaredo((prev) => prev + 1);
				}

				list.push({
					key: index,
					id: item._id,
					mine: item.mine,
					material: item.material,
					quantity: item.quantity,
					ticket: item.ticket,
					plant: item.plant.name,
					driver: item.driver.name,
					truck: item.truck.number,
					startedDate: item.startedDate
						? `${dayjs(item.startedDate)
								.toDate()
								.toLocaleDateString()} - ${dayjs(item.startedDate)
								.toDate()
								.toLocaleTimeString([], { timeStyle: "short" })}`
						: "-",
					receivedDate: item.receivedDate
						? `${dayjs(item.receivedDate)
								.toDate()
								.toLocaleDateString()} - ${dayjs(item.receivedDate)
								.toDate()
								.toLocaleTimeString([], { timeStyle: "short" })}`
						: "-",
					duration: duration,
				});
			});
			setList(list);
			setLoading(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				<CustomTable columns={columns} data={list} isScroll={false} />
			</Flex>
		);
	};

	const onRangeChange = (dates) => {
		if (dates) {
			setStartDate(dates[0]);
			setEndDate(dates[1]);
		}
	};

	const RenderDates = () => {
		return (
			<CustomSearch
				loading={loading}
				startDate={startDate}
				endDate={endDate}
				dateFormat={"MM/DD/YYYY"}
				onRangeChange={onRangeChange}
			/>
		);
	};

	const formatter = (value) => (
		<CountUp end={value} separator="," duration={2} decimals={2} />
	);
	const formatter2 = (value) => (
		<CountUp end={value} separator="," duration={2} />
	);

	const RenderCards = () => {
		return (
			<Row gutter={16}>
				<Col span={12}>
					<Card bordered={false}>
						<Title level={2}>Total Quantity</Title>
						<Row gutter={16}>
							<Col span={8}>
								<Statistic
									title="Quantity (Destination Mission)"
									value={quantityMission}
									valueStyle={{
										color: "#3f8600",
									}}
									prefix={<ArrowUpOutlined />}
									formatter={formatter}
								/>
							</Col>
							<Col span={8}>
								<Statistic
									title="Quantity (Destination San Benito)"
									value={quantitySanBenito}
									valueStyle={{
										color: "#3f8600",
									}}
									prefix={<ArrowUpOutlined />}
									formatter={formatter}
								/>
							</Col>
							<Col span={8}>
								<Statistic
									title="Quantity (Destination Laredo)"
									value={quantityLaredo}
									valueStyle={{
										color: "#3f8600",
									}}
									prefix={<ArrowUpOutlined />}
									formatter={formatter}
								/>
							</Col>
						</Row>
					</Card>
				</Col>
				<Col span={12}>
					<Card bordered={false}>
						<Title level={2}>Total Trips</Title>
						<Row gutter={16}>
							<Col span={8}>
								<Statistic
									title="Trips (Destination Mission)"
									value={tripsMission}
									precision={0}
									valueStyle={{
										color: "#3f8600",
									}}
									prefix={<TruckFilled />}
									formatter={formatter2}
								/>
							</Col>
							<Col span={8}>
								<Statistic
									title="Trips (Destination San Benito)"
									value={tripsSanBenito}
									precision={0}
									valueStyle={{
										color: "#3f8600",
									}}
									prefix={<TruckFilled />}
									formatter={formatter2}
								/>
							</Col>
							<Col span={8}>
								<Statistic
									title="Trips (Destination Laredo)"
									value={tripsLaredo}
									precision={0}
									valueStyle={{
										color: "#3f8600",
									}}
									prefix={<TruckFilled />}
									formatter={formatter2}
								/>
							</Col>
						</Row>
					</Card>
				</Col>
			</Row>
		);
	};

	const RenderDownloadReport = () => {
		return (
			<div>
				<CSVLink
					data={list}
					headers={columnsCSV}
					filename={"57concrete-logistics-total-general-trips.csv"}
					hidden
					ref={refDownload}
				>
					Download report
				</CSVLink>
				<Row gutter={16}>
					<Col span={24}>
						<Button
							type="primary"
							style={{ width: "100%" }}
							onClick={(e) => refDownload.current.link.click()}
							loading={loading}
						>
							Download Report
						</Button>
					</Col>
				</Row>
			</div>
		);
	};

	return (
		<Layout
			title="Total General Trips"
			description="Logistics Report"
			type="large"
		>
			<Flex vertical gap={"large"}>
				<RenderDates />
				<RenderCards />
				<RenderDownloadReport />
				{loading ? <CustomSpinner /> : <RenderTable />}
			</Flex>
		</Layout>
	);
};

export default LogisticsReportTotalGeneralTrips;

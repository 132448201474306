import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import AddressJob from "../map/AddressJob";
import { DateTime } from "luxon";
import { api } from "../utils/Api";
import CommentsModal from "./CommentsModal";

const ProposalItem = ({ proposal }) => {
	const [proposalStatus, setProposalStatus] = useState(proposal.proposalStatus);
	const [proposalComments, setProposalComments] = useState(proposal.comments);
	const [proposalTime, setProposalTime] = useState();
	const [showMap, setShowMap] = useState(false);
	const [showComments, setShowComments] = useState(false);

	const showModal = (e) => {
		if (e !== undefined) e.preventDefault();
		setShowMap(!showMap);
	};

	const showCommentsModal = (e) => {
		setShowComments(!showComments);
	};

	// update job attributes
	const saveProposal = async () => {
		const newProposal = {
			updatedBy: localStorage.getItem("userId"),
			comments: proposalComments,
		};

		if (proposalTime) {
			newProposal.scheduledDate = proposalTime;
		}

		const url = api + "/proposal/" + proposal._id;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.patch(url, newProposal);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const changeTime = (e) => {
		const fecha = DateTime.fromISO(proposal.scheduledDate);
		const anio = fecha.year;
		const mes = fecha.month;
		const dia = fecha.day;
		const [hora, minuto] = e.target.value.split(":");
		const newDate = new Date(anio, mes - 1, dia, hora, minuto, 0, 0);
		setProposalTime(newDate);
	};

	return (
		<tr className="small">
			<td>
				<Link to={"/prospection/" + proposal.prospection._id}>
					{proposal.prospection
						? proposal.prospection.prospectionNumber +
						  " " +
						  proposal.prospection.name +
						  " ph: " +
						  proposal.foremanPhone
						: "No prospection assigned!"}
				</Link>
			</td>
			<td>
				<Link to={"/proposal/" + proposal._id}>{proposal.number}</Link>
			</td>
			<td style={{ fontSize: "15px" }}>
				{new Date(proposal.scheduledDate).toLocaleTimeString([], {
					timeStyle: "short",
				})}
				<input type="time" id="time" name="time" onChange={changeTime} />
			</td>
			<td>
				<a href="#" onClick={showModal}>
					{proposal.shipAddress}
				</a>
				<Modal show={showMap} animation={false} onHide={showModal}>
					<Modal.Header closeButton>
						<Modal.Title>{proposal.shipAddress}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<AddressJob
							lat={proposal.latitude}
							lng={proposal.longitude}
							address={proposal.shipAddress}
						/>
					</Modal.Body>
				</Modal>
			</td>
			<td>
				{(typeof proposal.targetYD3 === "string"
					? proposal.targetYD3
					: proposal.targetYD3.toString()) + " + 0"}
			</td>
			<td>
				{proposal.destinationTime
					? proposal.destinationTime
					: proposal.destinationMinutes}
			</td>
			<td>{proposal.psi}</td>
			<td>{proposal.slump}</td>
			<td>{proposal.proposalPoor + " / " + proposal.proposalType}</td>
			<td>
				{proposalStatus ? proposalStatus : proposal.proposalStatus}
				<br />
			</td>
			<td>{proposal.seller ? proposal.seller.name : "seller unasigned"}</td>
			<td>
				{`${new Date(proposal.createdAt).toLocaleDateString()} - ${new Date(
					proposal.createdAt
				).toLocaleTimeString([], { timeStyle: "short" })}`}
			</td>
			<td>
				{proposal.jobCreation
					? `${new Date(
							proposal.jobCreation
					  ).toLocaleDateString()} - ${new Date(
							proposal.jobCreation
					  ).toLocaleTimeString([], { timeStyle: "short" })}`
					: "-"}
			</td>
			<td>
				{proposal.proposalStatus !== "Accepted" &&
				proposal.proposalStatus !== "Denied" ? (
					<button className="btn btn-primary" onClick={showCommentsModal}>
						+
					</button>
				) : (
					"-"
				)}
				<Modal show={showComments} animation={false} onHide={showCommentsModal}>
					<Modal.Header closeButton>
						<Modal.Title>{`Comments of proposal ${proposal.number}`}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<CommentsModal
							comments={proposal.comments}
							pcb={setProposalComments}
						/>
					</Modal.Body>
				</Modal>
			</td>
			<td>
				{(localStorage.getItem("email") === "mv@57concrete.com" ||
					localStorage.getItem("email") === "al@57concrete.com" ||
					localStorage.getItem("email") === "ec@57concrete.com" ||
					localStorage.getItem("email") === "eliud@gmail.com") &&
				proposal.proposalStatus !== "Accepted" &&
				proposal.proposalStatus !== "Denied" ? (
					<button className="btn btn-success" onClick={saveProposal}>
						Save
					</button>
				) : (
					"-"
				)}
			</td>
		</tr>
	);
};

export default ProposalItem;

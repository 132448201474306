import { Button, Col, Form, Input, Row, Select, notification } from "antd";
import React, { useEffect, useState } from "react";
import { CustomSpinner } from "../utils/CustomComponents";
import {
	customerTypeOptions,
	paymentTermOptions,
	paymentTypeOptions,
	quadrantTypeOptions,
} from "../../constants/Customer";
import { api } from "../utils/Api";
import axios from "axios";
const { Option } = Select;

const ClientModal2 = ({ pcb }) => {
	const [loading, setLoading] = useState(true);
	const [initialValues, setInitialValues] = useState(true);
	const [form] = Form.useForm();
	const [apiNotification, contextHolder] = notification.useNotification();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	useEffect(() => {
		getCustomerNumber("initial");
	}, []);

	const getCustomerNumber = async (type) => {
		const url = api + "/customernumber";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			if (type === "initial") {
				setInitialValues({
					prefix: "+1",
					paymentType: "PrePay",
					customerNumber: "A" + data.customerNumber.toString(),
				});
				setLoading(false);
			} else {
				form.setFieldValue(
					"customerNumber",
					"A" + data.customerNumber.toString()
				);
				openNotification(
					"warning",
					"Customer Number Duplicated",
					"Customer Number Changed"
				);
				setLoading(false);
			}
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const getCustomerByNumber = async (customerNumber) => {
		const url = api + `/clientnumber/${customerNumber}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			await axios.get(url);
			return true;
		} catch (err) {
			console.error(err.message);
			return false;
		}
	};

	const createNewClient = async (values) => {
		const url = api + "/customer";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const formData = {
				name: values.name,
				address: values.address,
				customerType: values.customerType,
				contactName: values.contactName,
				contactPhone: values.contactPhone,
				accountsPayableEmail: values.accountsPayableEmail,
				paymentType: values.paymentType,
				paymentTerm: values.paymentTerm,
				quadrantType: values.quadrantType,
				customerNumber: values.customerNumber,
			};
			const { data } = await axios.post(url, formData);
			// const callbackForm = {
			//     name: values.name,
			//     customerNumber: values.customerNumber,
			//     _id: data._id
			// }
			setLoading(false);
			pcb(`${data._id},${data.paymentType},${data.paymentTerm}`);
			// pcb(callbackForm)
		} catch (err) {
			openNotification("error", "Error", "Error while creating new client");
			setLoading(false);
			console.error(err.message);
			return null;
		}
	};

	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const prefixSelector = (
		<Form.Item name="prefix" noStyle>
			<Select
				style={{
					width: 70,
				}}
			>
				<Option value="+1">+1</Option>
			</Select>
		</Form.Item>
	);

	const validateCustomerNumber = async (values) => {
		setLoading(true);
		const replicated = await getCustomerByNumber();
		if (replicated) {
			getCustomerNumber("change");
		} else {
			createNewClient(values);
		}
	};

	const onFinish = (values) => {
		validateCustomerNumber(values);
	};

	const RenderForm = () => {
		return (
			<Form
				form={form}
				onFinish={onFinish}
				layout="vertical"
				initialValues={initialValues}
			>
				<Row gutter={16}>
					<Col span={6}>
						<Form.Item label="Customer Number" name="customerNumber">
							<Input size="large" disabled />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							label="Name"
							name="name"
							rules={[
								{
									required: true,
									message: "Fill name",
								},
							]}
						>
							<Input size="large" disabled={loading} />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Contact Name"
							name="contactName"
							rules={[
								{
									required: true,
									message: "Fill contact name",
								},
							]}
						>
							<Input size="large" disabled={loading} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							label="Customer Type"
							name="customerType"
							rules={[
								{
									required: true,
									message: "Please select customer type",
								},
							]}
						>
							<Select
								showSearch
								placeholder={`Select customer type`}
								optionFilterProp="children"
								size="large"
								filterOption={filterOption}
								options={customerTypeOptions}
								style={{
									width: "100%",
								}}
								disabled={loading}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Quadrant Type"
							name="quadrantType"
							rules={[
								{
									required: true,
									message: "Please select quadrant type",
								},
							]}
						>
							<Select
								showSearch
								placeholder={`Select quadrant type`}
								optionFilterProp="children"
								size="large"
								filterOption={filterOption}
								options={quadrantTypeOptions}
								style={{
									width: "100%",
								}}
								disabled={loading}
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							label="Contact Phone"
							name="contactPhone"
							rules={[
								{
									required: true,
									message: "Please fill phone",
								},
								{
									len: 10,
									max: 10,
									message: "Phone number must have 10 numbers",
								},
							]}
						>
							<Input
								size="large"
								addonBefore={prefixSelector}
								disabled={loading}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Customer Address"
							name="address"
							rules={[
								{
									required: true,
									message: "Fill address",
								},
							]}
						>
							<Input size="large" disabled={loading} />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={12}>
						<Form.Item
							label="Email"
							name="accountsPayableEmail"
							rules={[
								{
									type: "email",
									message: "The input is not valid E-mail",
								},
								{
									required: true,
									message: "Please fill email",
								},
							]}
						>
							<Input size="large" disabled={loading} />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Payment Type"
							name="paymentType"
							rules={[
								{
									required: true,
									message: "Please select payment type",
								},
							]}
						>
							<Select
								showSearch
								placeholder={`Select payment type`}
								optionFilterProp="children"
								size="large"
								filterOption={filterOption}
								options={paymentTypeOptions}
								style={{
									width: "100%",
								}}
								allowClear={false}
								disabled={loading}
							/>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							label="Payment Term"
							name="paymentTerm"
							rules={[
								{
									required: true,
									message: "Please select payment type",
								},
							]}
						>
							<Select
								showSearch
								placeholder={`Select payment term`}
								optionFilterProp="children"
								size="large"
								filterOption={filterOption}
								options={paymentTermOptions}
								style={{
									width: "100%",
								}}
								allowClear={false}
								disabled={loading}
							/>
						</Form.Item>
					</Col>
					<Button htmlType="submit" size="large">
						Save
					</Button>
				</Row>
			</Form>
		);
	};

	return (
		<div>
			{loading ? <CustomSpinner /> : <RenderForm />}
			{contextHolder}
		</div>
	);
};

export default ClientModal2;

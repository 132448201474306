import axios from "axios";
import React, { useEffect, useState } from "react";
import { api } from "../utils/Api";
import { Typography, Flex, Button, Checkbox } from "antd";
import Layout from "../layout/Layout";
import { CustomSpinner, CustomTable } from "../utils/CustomComponents";
import { EditFilled, PlusCircleFilled } from "@ant-design/icons";
const { Text } = Typography;

const QCList = ({ history }) => {
	const [loading, setLoading] = useState(true);
	const [qcList, setQClist] = useState([]);

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "",
			dataIndex: "edit",
			width: 50,
			render: (_, record) => {
				return (
					<Flex justify="space-between">
						{(localStorage.getItem("email") === "ec@57concrete.com" ||
							localStorage.getItem("email") === "eliud@gmail.com" ||
							localStorage.getItem("email") === "jp@57concrete.com" ||
							localStorage.getItem("email") === "bp@57concrete.com" ||
							localStorage.getItem("email") === "bpa@57concrete.com" ||
							localStorage.getItem("email") === "vm@57concrete.com" ||
							localStorage.getItem("email") === "mgr@57concrete.com" ||
							localStorage.getItem("email") === "jh@57concrete.com" ||
							localStorage.getItem("email") === "jv@57concrete.com" ||
							localStorage.getItem("email") === "ji@57concrete.com") && (
							<Button
								type="primary"
								icon={<EditFilled twoToneColor={"white"} />}
								onClick={(e) => history.push(`/qc/${record.id}`)}
							/>
						)}
					</Flex>
				);
			},
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Phone",
			dataIndex: "phone",
			key: "phone",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Poured Role",
			dataIndex: "pouredRole",
			key: "pouredRole",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Is Active? (App)",
			dataIndex: "isPouredUser",
			key: "isPouredUser",
			render: (value) => {
				return <Checkbox checked={value} />;
			},
		},
		{
			title: "Is Active? (Web)",
			dataIndex: "isActivePouredUser",
			key: "isActivePouredUser",
			render: (value) => {
				return <Checkbox checked={value} />;
			},
		},
	];

	useEffect(() => {
		getQCList();
	}, []);

	const getQCList = async () => {
		setLoading(true);

		const url = api + "/userlist/QC";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			data.map((item, index) => {
				list.push({
					key: index,
					id: item._id,
					name: item.name,
					email: item.email,
					phone: item.phone,
					isPouredUser: item.isPouredUser,
					isActivePouredUser: item.isActivePouredUser,
					pouredRole: item.pouredRole,
				});
			});
			setQClist(list);
			setLoading(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const addNewQC = () => {
		history.push("/newqc");
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				{(localStorage.getItem("email") === "ec@57concrete.com" ||
					localStorage.getItem("email") === "eliud@gmail.com" ||
					localStorage.getItem("email") === "jp@57concrete.com" ||
					localStorage.getItem("email") === "bp@57concrete.com" ||
					localStorage.getItem("email") === "bpa@57concrete.com" ||
					localStorage.getItem("email") === "vm@57concrete.com" ||
					localStorage.getItem("email") === "mgr@57concrete.com" ||
					localStorage.getItem("email") === "jh@57concrete.com" ||
					localStorage.getItem("email") === "jv@57concrete.com" ||
					localStorage.getItem("email") === "ji@57concrete.com") && (
					<Button
						onClick={addNewQC}
						type="primary"
						style={{
							width: 150,
						}}
						icon={<PlusCircleFilled />}
					>
						New QC
					</Button>
				)}
				<CustomTable columns={columns} data={qcList} />
			</Flex>
		);
	};

	return (
		<Layout title="QC" description="QC List">
			<Flex vertical gap={"large"}>
				{loading ? <CustomSpinner /> : <RenderTable />}
			</Flex>
		</Layout>
	);
};

export default QCList;

import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { api } from "../utils/Api";
import {
	Typography,
	Flex,
	Button,
	Row,
	Col,
	DatePicker,
	Select,
	Checkbox,
	TimePicker,
	Modal,
	Card,
	Statistic,
	Form,
	Input,
	Table,
	Skeleton,
	notification,
} from "antd";
import Layout from "../layout/Layout";
import { CustomSpinner } from "../utils/CustomComponents";
import {
	EditFilled,
	PlusCircleFilled,
	ArrowUpOutlined,
	QuestionCircleOutlined,
	GlobalOutlined,
	ExclamationCircleOutlined,
	SaveOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import AddressJob from "../map/AddressJob";
import CountUp from "react-countup";
import { jobStatusOptions } from "../../constants/Job";
import JobToInvoiceModal from "../invoice/JobToInvoiceModal";
import JobsSellerStatus from "./JobsSellerStatus";
import LoadJob2 from "../load/LoadJob2";

const { Text, Link, Title } = Typography;

const DailyAppointment2 = ({ history }) => {
	const [loading, setLoading] = useState(true);
	const [loadingTable, setLoadingTable] = useState(false);
	const [date, setDate] = useState(dayjs());
	const [plant, setPlant] = useState(
		localStorage.getItem("plant") || "61985b6a86825f39cc4aba38"
	);
	const [subplant, setSubplant] = useState("All");
	const [jobList, setJobList] = useState([]);
	const [jobListScheduled, setJobListScheduled] = useState([]);
	const [optionsPlant, setOptionsPlant] = useState([]);
	const [optionsSubplant, setOptionsSubplant] = useState([]);
	const [disabled, setDisabled] = useState(true);
	const [openAddressModal, setOpenAddressModal] = useState(false);
	const [openLoadsModal, setOpenLoadsModal] = useState(false);
	const [openLogisticModal, setOpenLogisticModal] = useState(false);
	const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
	const [openYardsModal, setOpenYardsModal] = useState(false);
	const [openJobBalanceModal, setOpenJobBalanceModal] = useState(false);
	const [jobHoldAll, setJobHoldAll] = useState(
		localStorage.getItem("jobHold") === "true" ? true : false
	);
	const [optionsJobStatus, setOptionsJobStatus] = useState(jobStatusOptions);
	const [jobBalance, setJobBalance] = useState([]);
	const [jobWaitingInspection, setJobWaitingInspection] = useState([]);

	const latitude = useRef();
	const longitude = useRef();
	const shipAddress = useRef();
	const jobNumber = useRef();
	const job = useRef();
	const jobTable = useRef({});
	const logisticConfirmation = useRef();

	const [apiNotification, contextHolder] = notification.useNotification();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	// link job status with color
	const selectColor = (estatus) => {
		switch (estatus) {
			case "Trouble":
				return "#7a7977";
			case "Inactive":
				return "#ffffff";
			case "ok":
				return "#7ad765";
			case "Ready to Load":
				return "#208928";
			case "Ready to be scheduled":
				return "#208928";
			case "Active":
				return "#fff569";
			case "Date moved":
				return "#d9a809";
			case "Operations done (AR)":
				return "#fce0a2";
			case "Company cancellation":
				return "#e087fa";
			case "Customer cancellation":
				return "#ad87fa";
			case "Quote":
				return "#c3c2c0";
			case "Confirm":
				return "#31b9f7";
			case "Scheduled":
				return "#e3495d";
			default:
				return "#fff569";
		}
	};

	const getInvoiceExistance = async (job) => {
		const url = api + "/jobinvoice/" + job.id;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			await axios.get(url);
			return true;
		} catch (err) {
			return false;
		}
	};

	const onChangeValues = async (type, value, record) => {
		const newData = [...jobList];
		const index = newData.findIndex((item) => record.key === item.key);

		if (index === -1) {
			const newData2 = [...jobListScheduled];
			const index2 = newData2.findIndex((item) => record.key === item.key);
			if (type === "jobStatus") {
				newData2[index2]["approved"] = true;
				newData2[index2]["confirm"] = true;
				newData2[index2][type] = value;
				setJobListScheduled(newData2);

				if (value === "Operations done (AR)") {
					const exist = await getInvoiceExistance(newData2[index2]);
					if (!exist) {
						jobTable.current = newData2[index2];
						setOpenInvoiceModal(true);
					}
				}
			} else if (type === "creditHold") {
				newData2[index2][type] = value;
				newData2[index2]["confirm"] = !newData2[index2]["confirm"];
				newData2[index2]["jobStatus"] = "Confirm";
				setJobListScheduled(newData2);
			} else {
				newData2[index2][type] = value;
				setJobListScheduled(newData2);
			}
		} else {
			if (type === "jobStatus") {
				newData[index]["approved"] = true;
				newData[index]["confirm"] = true;
				newData[index][type] = value;
				setJobList(newData);

				if (value === "Ready to Load") {
					newData[index]["readyToBeScheduledDate"] = dayjs();
				}

				if (value === "Operations done (AR)") {
					const exist = await getInvoiceExistance(newData[index]);
					if (!exist) {
						jobTable.current = newData[index];
						setOpenInvoiceModal(true);
					}
				}
			} else if (type === "creditHold") {
				newData[index][type] = value;
				newData[index]["confirm"] = !newData[index]["confirm"];
				newData[index]["jobStatus"] = "Confirm";
				setJobList(newData);
			} else {
				newData[index][type] = value;
				setJobList(newData);
			}
		}
	};

	const onSave = (record) => {
		setLoadingTable(true);
		saveJob("normal", record);
	};

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "",
			dataIndex: "edit",
			width: 100,
			render: (_, record) => {
				return (
					<Flex justify="space-between">
						<Button
							type="primary"
							icon={<EditFilled twoToneColor={"white"} />}
							onClick={(e) => history.push(`/job/${record.id}`)}
						/>
						&nbsp;
						{(localStorage.getItem("email") === "mv@57concrete.com" ||
							localStorage.getItem("email") === "al@57concrete.com" ||
							localStorage.getItem("email") === "ec@57concrete.com" ||
							localStorage.getItem("email") === "eliud@gmail.com" ||
							record.confirm) && (
							<Button
								type="primary"
								icon={<SaveOutlined twoToneColor={"white"} />}
								onClick={(e) => onSave(record)}
							/>
						)}
					</Flex>
				);
			},
		},
		{
			title: "Confirm",
			dataIndex: "confirm",
			key: "confirm",
			width: 80,
			render: (value, record) => {
				if (record.creditHold) {
					return (
						<Checkbox
							checked={value}
							style={{ fontSize: 10 }}
							onChange={(e) =>
								onChangeValues("confirm", e.target.checked, record)
							}
						>
							Credit on Hold
						</Checkbox>
					);
				} else {
					return (
						<Checkbox
							checked={value}
							onChange={(e) =>
								onChangeValues("confirm", e.target.checked, record)
							}
						/>
					);
				}
			},
		},
		{
			title: "Customer",
			dataIndex: "customer",
			key: "customer",
			width: 100,
			render: (value) => {
				return <Text style={{ fontSize: 10 }}>{value}</Text>;
			},
		},
		{
			title: "JobID",
			dataIndex: "jobId",
			key: "jobId",
			width: 70,
			render: (value) => {
				return <Text style={{ fontSize: 10 }}>{value}</Text>;
			},
		},
		{
			title: "Subplant",
			dataIndex: "subplant",
			key: "subplant",
			width: 80,
			render: (value, record) => {
				return (
					<Select
						options={record.optionsSubplant}
						value={record.optionsSubplant.filter((o) => {
							return o.value === value;
						})}
						style={{ width: "100%" }}
						allowClear={false}
						onChange={(e) => onChangeValues("subplant", e, record)}
					/>
				);
			},
		},
		{
			title: "Time",
			dataIndex: "time",
			key: "time",
			width: 80,
			render: (value, record) => {
				return (
					<Row style={{ fontSize: 10 }}>
						<TimePicker
							format={"HH:mm"}
							value={dayjs(value)}
							allowClear={false}
							style={{ width: 80 }}
							onChange={(e) => onChangeValues("time", e, record)}
						/>
					</Row>
				);
			},
		},
		{
			title: "Job Address",
			dataIndex: "jobAddress",
			key: "jobAddress",
			width: 100,
			render: (value, record) => {
				return (
					<Link
						onClick={(e) => setAddressValues(record)}
						style={{ fontSize: 10 }}
					>
						{value}
					</Link>
				);
			},
		},
		{
			title: "Load YD3",
			dataIndex: "loadYD3",
			key: "loadYD3",
			width: 60,
			render: (value, record) => {
				return (
					<Row gutter={16}>
						<Col span={24}>
							<Text strong style={{ fontSize: 10 }}>
								{value}
							</Text>
						</Col>
						<Col span={24}>
							{(record.jobStatus === "Inactive" ||
								record.jobStatus === "Confirm" ||
								record.jobStatus === "Active" ||
								record.jobStatus === "ok") &&
								record.confirm && (
									<Button
										type="primary"
										icon={<PlusCircleFilled />}
										onClick={(e) => setLoadsValues(record)}
									/>
								)}
						</Col>
					</Row>
				);
			},
		},
		{
			title: "Target YD3",
			dataIndex: "targetYD3",
			key: "targetYD3",
			width: 60,
			render: (value, record) => {
				if (record.balance) {
					return (
						<Text strong style={{ fontSize: 10 }}>
							{(typeof value === "string" ? value : value.toString()) +
								" + " +
								record.balance}
						</Text>
					);
				} else {
					return (
						<Text strong style={{ fontSize: 10 }}>
							{(typeof value === "string" ? value : value.toString()) + " + 0"}
						</Text>
					);
				}
			},
		},
		{
			title: "Dest. Time",
			dataIndex: "destTime",
			key: "destTime",
			width: 60,
			render: (value) => {
				return <Text style={{ fontSize: 10 }}>{value}</Text>;
			},
		},
		{
			title: "Mix Design",
			dataIndex: "psi",
			key: "psi",
			width: 100,
			render: (value) => {
				return <Text style={{ fontSize: 10 }}>{value}</Text>;
			},
		},
		{
			title: "Slump",
			dataIndex: "slump",
			key: "slump",
			width: 60,
			render: (value) => {
				return <Text style={{ fontSize: 10 }}>{value}</Text>;
			},
		},
		{
			title: "Fiber YD3",
			dataIndex: "fiber",
			key: "fiber",
			width: 60,
			render: (value, record) => {
				if (value) {
					return (
						<Checkbox checked={value} style={{ fontSize: 10 }}>
							{record.fiberQuantity}
						</Checkbox>
					);
				} else {
					return <Checkbox checked={value} />;
				}
			},
		},
		{
			title: "Pour/Type",
			dataIndex: "pourtype",
			key: "pourtype",
			width: 80,
			render: (value) => {
				return <Text style={{ fontSize: 10 }}>{value}</Text>;
			},
		},
		{
			title: "Status",
			dataIndex: "jobStatus",
			key: "jobStatus",
			width: 80,
			render: (value, record) => {
				return (
					<Select
						options={optionsJobStatus}
						value={optionsJobStatus.filter((o) => {
							return o.value === value;
						})}
						style={{ width: "100%" }}
						allowClear={false}
						disabled={!(!record.restrict && record.confirm)}
						onChange={(e) => onChangeValues("jobStatus", e, record)}
					/>
				);
			},
			onCell: (record) => {
				return {
					style: {
						background: selectColor(record.jobStatus),
					},
				};
			},
		},
		{
			title: "Seller",
			dataIndex: "seller",
			key: "seller",
			width: 100,
			render: (value) => {
				return <Text style={{ fontSize: 10 }}>{value}</Text>;
			},
		},
		{
			title: "Scheduled Date",
			dataIndex: "readyToBeScheduledDate",
			key: "readyToBeScheduledDate",
			width: 80,
			render: (value) => {
				if (value === "-") {
					return <Text style={{ fontSize: 10 }}>-</Text>;
				} else {
					return (
						<Text style={{ fontSize: 10 }}>{`${dayjs(value)
							.toDate()
							.toLocaleDateString()} - ${dayjs(value)
							.toDate()
							.toLocaleTimeString([], { timeStyle: "short" })}`}</Text>
					);
				}
			},
		},
		{
			title: "Hold",
			dataIndex: "creditHold",
			key: "creditHold",
			hidden: !jobHoldAll,
			width: 60,
			render: (value, record) => {
				if (record.jobHold) {
					if (value) {
						return (
							<Checkbox
								checked={value}
								style={{ fontSize: 10 }}
								onChange={(e) =>
									onChangeValues("creditHold", e.target.checked, record)
								}
							>
								Job Hold
							</Checkbox>
						);
					} else {
						return (
							<Checkbox
								checked={value}
								onChange={(e) =>
									onChangeValues("creditHold", e.target.checked, record)
								}
							/>
						);
					}
				}
			},
		},
		{
			title: "Comments",
			dataIndex: "comments",
			key: "comments",
			width: 100,
			render: (value) => {
				return <Text style={{ fontSize: 10 }}>{value}</Text>;
			},
		},
	];

	useEffect(() => {
		getJobs(date, plant, subplant);
	}, [date, plant, subplant]);

	useEffect(() => {
		configStatusOptions();
		getPlants();
	}, []);

	const configStatusOptions = () => {
		if (
			localStorage.getItem("email") === "dm@57concrete.com" ||
			localStorage.getItem("email") === "rs@57concrete.com" ||
			localStorage.getItem("email") === "jp@57concrete.com" ||
			localStorage.getItem("email") === "vm@57concrete.com" ||
			localStorage.getItem("email") === "ec@57concrete.com" ||
			localStorage.getItem("email") === "eliud@gmail.com"
		) {
			let index = optionsJobStatus.findIndex(
				(status) => status.value === "Scheduled"
			);
			optionsJobStatus[index].disabled = false;
		} else {
			let index = optionsJobStatus.findIndex(
				(status) => status.value === "Scheduled"
			);
			optionsJobStatus[index].disabled = true;
		}
	};

	const getPlants = async () => {
		const url = api + "/plant";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			data.map((item) => {
				list.push({
					value: item._id,
					label: item.name,
				});
			});
			setOptionsPlant(list);
			getSubplants(plant);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const getSubplants = async (plant) => {
		const url = api + `/plant/${plant}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [{ value: "All", label: "All" }];
			data.subplants.map((item) => {
				if (item.active) {
					list.push({
						value: item.name,
						label: item.name,
					});
				}
			});
			setOptionsSubplant(list);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const getSubplantsEdit = async (plant) => {
		const url = api + `/plant/${plant}`;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			data.subplants.map((item) => {
				if (item.active) {
					list.push({
						value: item.name,
						label: item.name,
					});
				}
			});
			return list;
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const getJobs = async (date, plant, subplant) => {
		try {
			setLoading(true);

			let url = "";
			let formattedDate = dayjs(date);
			let today = `${formattedDate.year()}-${
				formattedDate.month() + 1
			}-${formattedDate.date()}`;
			if (subplant === "All") {
				url = api + `/jobdateplant?date=${today}&plant=${plant}`;
			} else {
				url =
					api +
					`/jobdateplantsub?date=${today}&plant=${plant}&subplant=${subplant}`;
			}
			const token = localStorage.getItem("token");
			axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

			let { data } = await axios.get(url);

			//filter new data
			let dataDone = data.filter(
				(jb) => jb.jobStatus === "Operations done (AR)"
			);
			let dataOk = data.filter((jb) => jb.jobStatus === "ok");
			let dataActive = data.filter((jb) => jb.jobStatus === "Active");
			let dataReadyToLoad = data.filter(
				(jb) => jb.jobStatus === "Ready to Load"
			);
			let dataConfirm = data.filter((jb) => jb.jobStatus === "Confirm");
			let dataWaitingInspection = data.filter(
				(jb) => jb.jobStatus === "Waiting Inspection"
			);
			let dataNewOrder = data.filter((jb) => jb.jobStatus === "New Order");
			let dataWithDisabledButton = data.filter(
				(jb) =>
					jb.jobStatus !== "Operations done (AR)" &&
					jb.jobStatus !== "Active" &&
					jb.jobStatus !== "ok" &&
					jb.jobStatus !== "Ready to Load" &&
					jb.jobStatus !== "Waiting Inspection" &&
					jb.jobStatus !== "New Order" &&
					jb.jobStatus !== "Confirm"
			);

			data = [
				...dataDone,
				...dataOk,
				...dataActive,
				...dataReadyToLoad,
				...dataConfirm,
				...dataWaitingInspection,
				...dataNewOrder,
				...dataWithDisabledButton,
			];

			if (localStorage.getItem("role") === "Batch") {
				data = data.filter(
					(job) => job.subplant === localStorage.getItem("subplant")
				);
			}

			// suma de importe ($)
			const result = data.reduce((acc, elJob) => acc + elJob.loadYD3, 0);
			const totalLoad = Math.round(result * 100) / 100;

			// suma de target
			var suma = 0;
			var sumBalance = 0;
			var sumWait = 0;
			data.forEach((element) => {
				let operandos =
					typeof element.targetYD3 === "string"
						? element.targetYD3.split("+")
						: element.targetYD3;
				if (
					element.jobStatus !== "Quote" &&
					element.jobStatus !== "Waiting Inspection" &&
					element.jobStatus !== "Wait Inspection"
				) {
					suma =
						suma +
						(typeof element.targetYD3 === "string"
							? parseFloat(operandos[0])
							: operandos);
				}

				if (
					element.jobStatus === "Waiting Inspection" ||
					element.jobStatus === "Wait Inspection"
				) {
					sumWait =
						sumWait +
						(typeof element.targetYD3 === "string"
							? parseFloat(operandos[0])
							: operandos);
				}

				var balance = element.balance ? element.balance : 0;
				sumBalance = sumBalance + parseFloat(balance);
			});
			localStorage.setItem("targetSum", suma);
			localStorage.setItem("sumBalance", sumBalance);
			localStorage.setItem("waitingInspection", sumWait);
			localStorage.setItem("loadSum", totalLoad);

			let optionsSubplantEdit = await getSubplantsEdit(plant);
			let list = [];

			await Promise.all(
				data.map(async (item, index) => {
					let { approved, confirm, restrict } = calculateIsApproved(item);
					let loadSum = await getLoadData(item);

					list.push({
						key: index,
						id: item._id,
						job: item,
						logisticConfirmation: item.logisticConfirmation || false,
						logisticComment: item.logisticComment || "",
						confirm: confirm,
						approved: approved,
						restrict: restrict,
						creditHold: item.hold,
						customer: item.customer
							? item.customer.customerNumber +
							  " " +
							  item.customer.name +
							  " ph: " +
							  item.foremanPhone
							: "No customer assigned!",
						jobId: item.number,
						subplant: item.subplant,
						optionsSubplant: optionsSubplantEdit,
						time: item.scheduledDate,
						jobAddress: item.shipAddress,
						latitude: item.latitude,
						longitude: item.longitude,
						shipAddress: item.shipAddress,
						loadYD3: loadSum,
						balance: item.balance,
						targetYD3: item.targetYD3,
						destTime: item.destinationTime || item.destinationMinutes,
						psi: item.psi,
						slump: item.slump,
						pourtype: `${item.jobPoor} / ${item.jobType}`,
						jobStatus: item.jobStatus,
						jobStatusPrev: item.jobStatus,
						seller: item.seller ? item.seller.name : item.associateName,
						readyToBeScheduledDate: item.readyToBeScheduledDate
							? dayjs(item.readyToBeScheduledDate)
							: "-",
						jobHold: jobHoldAll,
						comments: item.comments,
						logisticDate: item.logisticDate || null,
						fiber: item.fiberQuantity && item.fiberQuantity > 0,
						fiberQuantity: item.fiberQuantity || 0,
					});
				})
			);

			list.sort((a, b) => {
				return a.key - b.key;
			});

			setJobList(list.filter((job) => job.jobStatus !== "Scheduled"));
			setJobListScheduled(list.filter((job) => job.jobStatus === "Scheduled"));
			setJobBalance(
				list.filter((job) => job.balance !== null && job.balance !== 0)
			);
			setJobWaitingInspection(
				list.filter(
					(job) =>
						job.jobStatus === "Waiting Inspection" ||
						job.jobStatus === "Wait Inspection"
				)
			);
			setLoading(false);
			setDisabled(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	// job list for selected date
	const updateLoad = async (jobId, status) => {
		const url = api + "/load/" + jobId;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			const body = {
				status: status,
			};
			if (data.length !== 0) {
				data.forEach(async (load) => {
					const urlUpdate = api + "/load/" + load._id;
					await axios.patch(urlUpdate, body);
				});
			}
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	// update job attributes
	const saveJob = async (type, record) => {
		const exist = await getInvoiceExistance(record);

		if (!exist && record.jobStatus === "Operations done (AR)") {
			warningInvoice();
			setLoadingTable(false);
			return;
		}

		if (record.jobStatus === "Confirm" || record.jobStatus === "Active") {
			updateLoad(record.id, "waiting");
		}
		if (
			record.jobStatus === "Operations done (AR)" ||
			record.jobStatus === "ok"
		) {
			updateLoad(record.id, "done");
		}

		let newJob = {
			jobStatus: record.jobStatus,
			loadYD3: record.loadYD3,
			updatedBy: localStorage.getItem("userId"),
			hold: record.creditHold,
			approved: record.confirm,
			subplant: record.subplant,
			scheduledDate: record.time,
		};

		if (type === "logistic") {
			newJob.logisticConfirmation = record.logisticConfirmation;
			newJob.logisticComment = record.logisticComment;
		}

		if (record.jobStatus !== record.jobStatusPrev) {
			newJob.jobStatusHistory = {
				user: localStorage.getItem("userId"),
				jobStatus: record.jobStatus,
			};
		}

		if (record.jobStatus === "Ready to Load") {
			if (record.jobStatus !== record.jobStatusPrev) {
				newJob.readyToBeScheduledDate = dayjs(
					record.readyToBeScheduledDate
				).toDate();
			}
		}

		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const url = api + "/job/" + record.id;
		const urlLog = api + "/jobhistory";

		const antiguo = {
			funding: record.job.funding,
			taxExemptJob: record.job.taxExemptJob,
			contractorType: record.job.contractorType,
			ownerState: record.job.ownerState,
			suretyState: record.job.suretyState,
			balance: record.job.balance,
			propsStatus: record.job.jobStatus,
			slump: record.job.slupm,
			paid: record.job.paid,
			isPhoneQuote: record.job.isPhoneQuote,
			hasInvoice: record.job.hasInvoice,
			approved: record.job.approved,
			customer: record.job.customer,
			name: record.job.name,
			loadYD3: record.job.loadYD3,
			targetYD3: record.job.targetYD3,
			shipAddress: record.job.shipAddress,
			propsForeman: record.job.jobForeman,
			foremanPhone: record.job.foremanPhone,
			apContact: record.job.apContact,
			apPhone: record.job.apPhone,
			scheduledDate: record.job.scheduledDate,
			destinationMinutes: record.job.destinationMinutes,
			comments: record.job.comments,
			po: record.job.po,
			jobDuration: record.job.jobDuration,
			unitPrice: record.job.unitPrice,
			jobPoor: record.job.jobPoor,
			jobType: record.job.jobType,
			number: record.job.number,
			seller: record.job.seller,
			psi: record.job.psi,
			destinationTime: record.job.destinationTime,
			distance: record.job.distance,
			latitude: record.job.latitude,
			longitude: record.job.longitude,
			paymentType: record.job.paymentType,
			firstShipementDate: record.job.firstShipementDate,
			scheduledTime: record.job.scheduledTime,
			jobStatus: record.job.jobStatus,
			createdBy: localStorage.getItem("userId"),
			updatedBy: localStorage.getItem("userId"),
		};

		try {
			await axios.patch(url, newJob);
			await axios.post(urlLog, antiguo);
			setLoadingTable(false);
			if (type === "normal")
				openNotification("success", "Success", "Job Updated");

			if (newJob.jobStatus === "Scheduled") {
				const newData = [...jobList];
				const index = newData.findIndex((item) => record.key === item.key);
				if (index !== -1) {
					let newList = jobListScheduled;
					newList.push(newData[index]);
					setJobListScheduled(newList);
					delete newData[index];
					newData.filter((item) => item);
					let filtered = newData.filter(function (x) {
						return x !== undefined;
					});
					setJobList(filtered);
				}
			} else {
				const newDataScheduled = [...jobListScheduled];
				const index = newDataScheduled.findIndex(
					(item) => record.key === item.key
				);
				if (index !== -1) {
					let newList = jobList;
					newList.push(newDataScheduled[index]);
					setJobList(newList);
					delete newDataScheduled[index];
					let filtered = newDataScheduled.filter(function (x) {
						return x !== undefined;
					});
					setJobListScheduled(filtered);
				}
			}
		} catch (err) {
			openNotification(
				"error",
				"Error",
				"Something went wrong while updating job"
			);
			console.error(err.message);
			return null;
		}
	};

	const calculateIsApproved = (job) => {
		const paymentType = job.customer.paymentType;
		const approved = job.approved;
		const currentRole = localStorage.getItem("role");
		let restrict = false;
		let confirm = false;

		if (currentRole === "Finance" || paymentType !== "PrePay" || approved) {
			restrict = false;
		} else {
			restrict = true;
		}

		/*! Rule to habilitate confirm checkbox */
		if (approved) {
			confirm = true;
		} else {
			if (currentRole === "Finance" || paymentType !== "Credit") {
				confirm = true;
			} else {
				confirm = false;
			}
		}

		if (job.hold) {
			confirm = false;
		}

		return { restrict, confirm, approved };
	};

	// job list for selected date
	const getLoadData = async (job) => {
		const url = api + "/load/" + job._id;
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const urlJob = api + "/job/" + job._id;

		try {
			const { data } = await axios.get(url);
			const suma = data
				.map((viaje) => viaje.yards)
				.reduce((acc, acum) => acc + acum, 0);
			let loadSum = Math.round(suma * 100) / 100;

			const updateJob = {
				loadYD3: Math.round(suma * 100) / 100,
			};

			await axios.patch(urlJob, updateJob);

			return loadSum;
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const onChangeDate = (value) => {
		setDisabled(true);
		setDate(value);
	};

	const onChangePlant = (value) => {
		setDisabled(true);
		setPlant(value);
		setSubplant("All");
		getSubplants(value);
	};

	const onChangeSubplant = (value) => {
		setSubplant(value);
	};

	const RenderHeader = () => {
		return (
			<Row gutter={16}>
				<Col span={6}>
					<DatePicker
						size="large"
						onChange={onChangeDate}
						value={date}
						allowClear={false}
						format={"MM/DD/YYYY"}
						style={{ width: "100%" }}
						disabled={disabled}
					/>
				</Col>
				{localStorage.getItem("role") !== "Batch" && (
					<Col span={6}>
						<Select
							size="large"
							options={optionsPlant}
							value={optionsPlant.filter((o) => {
								return o.value === plant;
							})}
							onChange={onChangePlant}
							style={{ width: "100%" }}
							loading={disabled}
							disabled={disabled}
						/>
					</Col>
				)}
				{localStorage.getItem("role") !== "Batch" && (
					<Col span={6}>
						<Select
							size="large"
							options={optionsSubplant}
							value={optionsSubplant.filter((o) => {
								return o.value === subplant;
							})}
							onChange={onChangeSubplant}
							style={{ width: "100%" }}
							loading={disabled}
							disabled={disabled}
						/>
					</Col>
				)}
			</Row>
		);
	};

	const RenderTables = () => {
		return (
			<Flex gap={"large"} vertical>
				<RenderTableScheduled />
				<RenderTable />
			</Flex>
		);
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				<Title level={3}>Regular Jobs</Title>
				<Table
					columns={columns}
					dataSource={jobList}
					loading={loadingTable}
					scroll={{
						x: 2000,
					}}
					pagination={false}
					onRow={(record, index) => ({
						style: {
							background: record.logisticDate ? "#b9d3fa" : "white",
						},
					})}
				/>
			</Flex>
		);
	};

	const RenderTableScheduled = () => {
		return (
			<Flex gap={"middle"} vertical>
				<Title level={3}>Scheduled Jobs</Title>
				<Table
					columns={columns}
					dataSource={jobListScheduled}
					loading={loadingTable}
					scroll={{
						x: 2000,
					}}
					pagination={false}
					onRow={(record, index) => ({
						style: {
							background: record.logisticDate ? "#b9d3fa" : "white",
						},
					})}
				/>
			</Flex>
		);
	};

	const formatter = (value) => <CountUp end={value} separator="," />;

	const RenderTotals = () => {
		return (
			<Row gutter={16}>
				<Col span={4}>
					<Card bordered={true} style={{ backgroundColor: "#f5f5f5" }}>
						{loading ? (
							<Skeleton active />
						) : (
							<Statistic
								title="Total YD3"
								value={localStorage.getItem("loadSum")}
								precision={2}
								formatter={formatter}
								prefix={<GlobalOutlined />}
							/>
						)}
					</Card>
				</Col>
				<Col span={4}>
					<Card bordered={true} style={{ backgroundColor: "#f5f5f5" }}>
						{loading ? (
							<Skeleton active />
						) : (
							<Statistic
								title="Total Target"
								value={localStorage.getItem("targetSum")}
								precision={2}
								valueStyle={{
									color: "#3f8600",
								}}
								formatter={formatter}
								prefix={<ArrowUpOutlined />}
							/>
						)}
					</Card>
				</Col>
				<Col span={4}>
					<Card
						bordered={true}
						style={{
							backgroundColor: "#f5f5f5",
							cursor: jobBalance.length === 0 ? null : "pointer",
						}}
						onClick={jobBalance.length === 0 ? null : handleJobBalanceOpen}
					>
						{loading ? (
							<Skeleton active />
						) : (
							<Statistic
								title="Total Balance"
								value={localStorage.getItem("sumBalance")}
								precision={2}
								valueStyle={{
									color: "#2246bd",
								}}
								formatter={formatter}
								prefix={<ExclamationCircleOutlined />}
							/>
						)}
					</Card>
				</Col>
				<Col span={4}>
					<Card bordered={true} style={{ backgroundColor: "#f5f5f5" }}>
						{loading ? (
							<Skeleton active />
						) : (
							<Statistic
								title="Waiting Inspection"
								value={localStorage.getItem("waitingInspection")}
								precision={2}
								valueStyle={{
									color: "#cf1322",
								}}
								formatter={formatter}
								prefix={<QuestionCircleOutlined />}
							/>
						)}
					</Card>
				</Col>
			</Row>
		);
	};

	const RenderButtons = () => {
		return (
			<Row gutter={16}>
				<Col>
					<Button
						size="large"
						type="primary"
						danger
						htmlType="button"
						loading={loading}
						onClick={(e) => history.push("/heatmap")}
					>
						Driver's Heatmap
					</Button>
				</Col>
				<Col>
					<Button
						size="large"
						type="primary"
						danger
						htmlType="button"
						loading={loading}
						onClick={handleYardsOpen}
					>
						Yards Per Seller
					</Button>
				</Col>
			</Row>
		);
	};

	const setAddressValues = (job) => {
		latitude.current = job.latitude;
		longitude.current = job.longitude;
		shipAddress.current = job.shipAddress;
		setOpenAddressModal(true);
	};

	const handleAddressCancel = () => {
		setOpenAddressModal(false);
	};

	const RenderAddressModal = () => {
		return (
			<Modal
				title={shipAddress.current}
				open={openAddressModal}
				onCancel={handleAddressCancel}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				width={800}
			>
				<AddressJob
					lat={latitude.current}
					lng={longitude.current}
					address={shipAddress.current}
					plant={plant}
				/>
			</Modal>
		);
	};

	const setLoadsValues = (item) => {
		job.current = item.job;
		jobTable.current = item;
		jobNumber.current = item.jobId;
		logisticConfirmation.current = item.logisticConfirmation;
		setOpenLoadsModal(true);
	};

	const handleLoadsCancel = async () => {
		setOpenLoadsModal(false);
		let sumLoad = await getLoadData(job.current);
		onChangeValues("loadYD3", sumLoad, jobTable.current);
	};

	const RenderLoadsModal = () => {
		return (
			<Modal
				title={`Load YD3. JobID = ${jobNumber.current}`}
				open={openLoadsModal}
				onCancel={handleLoadsCancel}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				width={800}
			>
				{(localStorage.getItem("email") === "mv@57concrete.com" ||
					localStorage.getItem("email") === "al@57concrete.com" ||
					localStorage.getItem("email") === "ec@57concrete.com" ||
					localStorage.getItem("email") === "fc@57concrete.com" ||
					localStorage.getItem("email") === "bp@57concrete.com" ||
					localStorage.getItem("email") === "bpa@57concrete.com" ||
					localStorage.getItem("email") === "vm@57concrete.com" ||
					localStorage.getItem("email") === "jp@57concrete.com" ||
					localStorage.getItem("email") === "eliud@gmail.com") && (
					<Button
						type="primary"
						danger={!logisticConfirmation.current}
						onClick={handleLogisticOpen}
					>
						Logistics Confirmation
					</Button>
				)}
				<LoadJob2 job={job.current} />
			</Modal>
		);
	};

	const handleLogisticCancel = () => {
		setOpenLogisticModal(false);
	};

	const handleLogisticOpen = () => {
		setOpenLogisticModal(true);
	};

	const manageLogisticConfirmation = (values) => {
		onChangeValues(
			"logisticConfirmation",
			!jobTable.current.logisticConfirmation,
			jobTable.current
		);
		onChangeValues("logisticComment", values.comment, jobTable.current);
		logisticConfirmation.current = !logisticConfirmation.current;
		saveJob("logistic", jobTable.current);
		handleLogisticCancel();
	};

	const RenderLogisticModal = () => {
		return (
			<Modal
				title="Add comment to logistic confirmation"
				open={openLogisticModal}
				onCancel={handleLogisticCancel}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
			>
				<Form onFinish={manageLogisticConfirmation}>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								name={"comment"}
								label={"Comment"}
								rules={[
									{
										required: true,
										message: "Comment required",
									},
								]}
							>
								<Input size="large" />
							</Form.Item>
						</Col>
					</Row>
					<Button htmlType="submit" size="large" type="primary">
						Save
					</Button>
				</Form>
			</Modal>
		);
	};

	const handleInvoiceCancel = () => {
		setOpenInvoiceModal(false);
	};

	const RenderInvoiceModal = () => {
		return (
			<Modal
				title="Invoice Generator"
				open={openInvoiceModal}
				onCancel={handleInvoiceCancel}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
			>
				<JobToInvoiceModal
					jobId={jobTable.current["id"]}
					pcb={handleInvoiceCancel}
				/>
			</Modal>
		);
	};

	const handleYardsCancel = () => {
		setOpenYardsModal(false);
	};

	const handleYardsOpen = () => {
		setOpenYardsModal(true);
	};

	const RenderYardsModal = () => {
		return (
			<Modal
				title="Yards Per Seller"
				open={openYardsModal}
				onCancel={handleYardsCancel}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				style={{ top: 20, left: 600 }}
			>
				<JobsSellerStatus
					today={`${date.year()}-${date.month() + 1}-${date.date()}`}
					plant={plant}
				/>
			</Modal>
		);
	};

	const warningInvoice = () => {
		Modal.warning({
			title: "Save Warning",
			content: "You can't save changes until invoice is generated",
		});
	};

	const handleJobBalanceOpen = () => {
		setOpenJobBalanceModal(true);
	};

	const handleJobBalanceCancel = () => {
		setOpenJobBalanceModal(false);
	};

	const RenderBalanceModal = () => {
		const columnsBalance = [
			{
				title: "Customer",
				dataIndex: "customer",
				key: "customer",
				width: 100,
				render: (value) => {
					return <Text style={{ fontSize: 10 }}>{value}</Text>;
				},
			},
			{
				title: "JobID",
				dataIndex: "jobId",
				key: "jobId",
				width: 70,
				render: (value) => {
					return <Text style={{ fontSize: 10 }}>{value}</Text>;
				},
			},
			{
				title: "Mix Design",
				dataIndex: "psi",
				key: "psi",
				width: 100,
				render: (value) => {
					return <Text style={{ fontSize: 10 }}>{value}</Text>;
				},
			},
			{
				title: "Balance",
				dataIndex: "balance",
				key: "balance",
				width: 60,
				render: (value) => {
					return (
						<Text strong style={{ fontSize: 10 }}>
							{value}
						</Text>
					);
				},
			},
			{
				title: "Pour/Type",
				dataIndex: "pourtype",
				key: "pourtype",
				width: 80,
				render: (value) => {
					return <Text style={{ fontSize: 10 }}>{value}</Text>;
				},
			},
			{
				title: "Seller",
				dataIndex: "seller",
				key: "seller",
				width: 100,
				render: (value) => {
					return <Text style={{ fontSize: 10 }}>{value}</Text>;
				},
			},
		];

		return (
			<Modal
				title="Jobs with Balance"
				open={openJobBalanceModal}
				onCancel={handleJobBalanceCancel}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				width={800}
			>
				<Table
					columns={columnsBalance}
					dataSource={jobBalance}
					pagination={false}
				/>
			</Modal>
		);
	};

	return (
		<Layout title="Daily Appointment" description="Job List">
			<Flex vertical gap={"large"}>
				{contextHolder}
				<RenderHeader />
				<RenderTotals />
				<RenderButtons />
				{loading ? <CustomSpinner /> : <RenderTables />}
				<RenderAddressModal />
				<RenderLoadsModal />
				<RenderLogisticModal />
				<RenderInvoiceModal />
				<RenderYardsModal />
				<RenderBalanceModal />
			</Flex>
		</Layout>
	);
};

export default DailyAppointment2;

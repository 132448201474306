import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { api } from "../utils/Api";
import {
	Typography,
	Flex,
	Button,
	Tag,
	notification,
	Row,
	Statistic,
	Modal,
	Col,
} from "antd";
import Layout from "../layout/Layout";
import {
	CustomSearch,
	CustomSpinner,
	CustomTable,
} from "../utils/CustomComponents";
import { OrderedListOutlined, EditFilled } from "@ant-design/icons";
import LoanPayments from "./LoanPayments";
import { CSVLink } from "react-csv";

const { Text } = Typography;

const columnsCSV = [
	{ key: "owner", label: "Owner/Lessor" },
	{ key: "type", label: "Type of Loan" },
	{ key: "project", label: "Project" },
	{ key: "lender", label: "Lender" },
	{ key: "collateral", label: "Collateral" },
	{ key: "cost", label: "Cost" },
	{ key: "salesTax", label: "Sales Tax" },
	{ key: "fees", label: "Fees" },
	{ key: "downPayment", label: "Down Payment" },
	{ key: "advancedPayment", label: "Advanced Payment" },
	{ key: "totalAmountFinanced", label: "Total Amount Financed" },
	{ key: "acceleratedPayments", label: "Accelerated Payments" },
	{ key: "regularPayment", label: "Regular Payment" },
	{ key: "financeCharge", label: "Finance Charge" },
	{ key: "rate", label: "Rate (%)" },
	{ key: "period", label: "Period" },
	{ key: "totalCostLoan", label: "Total Cost of Loan" },
];

const LoanDashboard = ({ history }) => {
	const [loading, setLoading] = useState(false);
	const [opportunityList, setOpportunityList] = useState([]);
	const [apiNotification, contextHolder] = notification.useNotification();
	const [openPaymentsModal, setOpenPaymentsModal] = useState(false);
	const [listCSV, setListCSV] = useState([]);
	const refDownload = useRef();
	const loanRef = useRef();

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	const handlePaymentsCancel = () => {
		setOpenPaymentsModal(false);
	};

	const handlePaymentsOpen = (id) => {
		loanRef.current = id;
		setOpenPaymentsModal(true);
	};

	/**
	 ** Options header (columns)
	 */
	const columns = [
		{
			title: "Owner/Lessor",
			dataIndex: "owner",
			key: "owner",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Type of Loan",
			dataIndex: "type",
			key: "type",
			render: (value) => {
				let color = "default";
				switch (value) {
					case "RPO":
						color = "blue";
						break;
					case "EFA":
						color = "gold";
						break;
					case "REFI":
						color = "cyan";
						break;
					case "LOC":
						color = "magenta";
						break;
					default:
						break;
				}
				return <Tag color={color}>{value}</Tag>;
			},
		},
		{
			title: "Project",
			dataIndex: "project",
			key: "project",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Lender",
			dataIndex: "lender",
			key: "lender",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Collateral",
			dataIndex: "collateral",
			key: "collateral",
			render: (value) => {
				return <Text>{value}</Text>;
			},
		},
		{
			title: "Cost",
			dataIndex: "cost",
			key: "cost",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Sales Tax",
			dataIndex: "salesTax",
			key: "salesTax",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Fees",
			dataIndex: "fees",
			key: "fees",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Down Payment",
			dataIndex: "downPayment",
			key: "downPayment",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Advanced Payment",
			dataIndex: "advancedPayment",
			key: "advancedPayment",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Total Amount Financed",
			dataIndex: "totalAmountFinanced",
			key: "totalAmountFinanced",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Accelerated Payments",
			dataIndex: "acceleratedPayments",
			key: "acceleratedPayments",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Regular Payment",
			dataIndex: "regularPayment",
			key: "regularPayment",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Finance Charge",
			dataIndex: "financeCharge",
			key: "financeCharge",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Rate (%)",
			dataIndex: "rate",
			key: "rate",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						suffix={"%"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "Period (Months)",
			dataIndex: "period",
			key: "period",
			render: (value) => {
				return (
					<Statistic title="" value={value} valueStyle={{ fontSize: 14 }} />
				);
			},
		},
		{
			title: "Total Cost of Loan",
			dataIndex: "totalCostLoan",
			key: "totalCostLoan",
			render: (value) => {
				return (
					<Statistic
						title=""
						value={value}
						prefix={"$"}
						precision={2}
						valueStyle={{ fontSize: 14 }}
					/>
				);
			},
		},
		{
			title: "",
			dataIndex: "edit",
			width: 100,
			render: (_, record) => {
				return (
					<Row gutter={16}>
						<Button
							type="primary"
							icon={<OrderedListOutlined twoToneColor={"white"} />}
							onClick={(e) => handlePaymentsOpen(record.id)}
						/>
						&nbsp;
						<Button
							type="primary"
							icon={<EditFilled twoToneColor={"white"} />}
							onClick={(e) => history.push(`/loan/update/${record.id}`)}
						/>
						&nbsp;
					</Row>
				);
			},
		},
	];

	useEffect(() => {
		getLoanList("");
	}, []);

	const getLoanList = async (term) => {
		setLoading(true);
		setListCSV([]);

		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
		const url = api + `/loan/list?term=${term}`;

		try {
			const { data } = await axios.get(url);
			let list = [];

			if (data.success) {
				data.payload.map((item, index) => {
					list.push({
						key: index,
						id: item._id,
						owner: item.owner,
						type: item.type,
						project: item.project,
						lender: item.lender,
						collateral: item.collateral,
						cost: item.cost,
						salesTax: item.salesTax,
						fees: item.fees,
						downPayment: item.downPayment,
						advancedPayment: item.advancedPayment,
						totalAmountFinanced: item.totalAmountFinanced,
						acceleratedPayments: item.acceleratedPayments,
						regularPayment: item.regularPayment,
						financeCharge: item.financeCharge,
						rate: item.rate,
						period: item.period,
						totalCostLoan: item.totalCostLoan,
					});
				});
				setOpportunityList(list);
				setListCSV(list);
			} else {
				openNotification("error", "Error", data.message);
			}

			setLoading(false);
		} catch (err) {
			console.error(err.message);
			openNotification("error", "Error", err.message);
			return null;
		}
	};

	const onClickNewOpportunity = () => {
		history.push("/loan/new");
	};

	const onClickOpenReport = () => {
		history.push("/reports/loan/payments");
	};

	const onClickOpenReportDetailed = () => {
		history.push("/reports/loan/payments/detailed");
	};

	const onClickOpenEndingBalanceReport = () => {
		history.push("/reports/loan/endingbalance");
	};

	const RenderTable = () => {
		return (
			<Flex gap={"middle"} vertical>
				<Row gap={[16, 16]}>
					<Col>
						<Button type="primary" onClick={onClickNewOpportunity} size="large">
							Add new loan
						</Button>
					</Col>
					&nbsp;
					<Col>
						<Button type="primary" onClick={onClickOpenReport} size="large">
							Payments Report
						</Button>
					</Col>
					&nbsp;
					<Col>
						<Button
							type="primary"
							onClick={onClickOpenReportDetailed}
							size="large"
						>
							Payments Detailed Report
						</Button>
					</Col>
					&nbsp;
					<Col>
						<Button
							type="primary"
							onClick={onClickOpenEndingBalanceReport}
							size="large"
						>
							Ending Balance Report
						</Button>
					</Col>
				</Row>

				<CustomTable columns={columns} data={opportunityList} isScroll={true} />
			</Flex>
		);
	};

	const RenderPaymentsModal = () => {
		return (
			<Modal
				title="Payments"
				open={openPaymentsModal}
				onCancel={handlePaymentsCancel}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				width={1200}
			>
				<LoanPayments id={loanRef.current} />
			</Modal>
		);
	};

	const onSearch = (value, _e, info) => {
		if (info?.source === "input") {
			getLoanList(value);
		}
	};

	const RenderSearch = () => {
		return <CustomSearch onSearch={onSearch} loading={loading} />;
	};

	const RenderDownloadReport = () => {
		return (
			<div>
				<CSVLink
					data={listCSV}
					headers={columnsCSV}
					filename={"57concrete-loan-summary.csv"}
					hidden
					ref={refDownload}
				>
					Download report
				</CSVLink>
				<Row gutter={16}>
					<Col span={24}>
						<Button
							size="large"
							type="primary"
							style={{ width: "100%" }}
							onClick={(e) => refDownload.current.link.click()}
							loading={loading}
						>
							Download Report
						</Button>
					</Col>
				</Row>
			</div>
		);
	};

	return (
		<Layout title="Loan Dashboard" description="Dashboard">
			{contextHolder}
			<Flex vertical gap={"large"}>
				<RenderSearch />
				{loading ? <CustomSpinner /> : <RenderTable />}
				<RenderDownloadReport />
				<RenderPaymentsModal />
			</Flex>
		</Layout>
	);
};

export default LoanDashboard;

import {
	Button,
	Col,
	Divider,
	Form,
	Input,
	Modal,
	Row,
	Select,
	notification,
} from "antd";
import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { api } from "../utils/Api";
import axios from "axios";
import { SearchOutlined } from "@ant-design/icons";
import AddressGeolocation2 from "../map/AddressGeolocation2";
const { Option } = Select;

const isAdminUser =
	localStorage.getItem("email") === "eliud@gmail.com" ||
	localStorage.getItem("email") === "ec@57concrete.com" ||
	localStorage.getItem("email") === "bp@57concrete.com" ||
	localStorage.getItem("email") === "bpa@57concrete.com" ||
	localStorage.getItem("email") === "mgr@57concrete.com" ||
	localStorage.getItem("email") === "jh@57concrete.com" ||
	localStorage.getItem("email") === "rl@57concrete.com";

const opportuniyTypeOptions = [
	{
		value: "Residential",
		label: "Residential",
	},
	{
		value: "Commercial",
		label: "Commercial",
	},
];

const NewOpportunity = ({ history }) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [apiNotification, contextHolder] = notification.useNotification();
	const [openSearchAddressModal, setOpenSearchAddressModal] = useState(false);
	const [optionsSellers, setOptionsSellers] = useState([]);
	const [loadingSeller, setLoadingSeller] = useState(true);

	const openNotification = (type, message, description) => {
		apiNotification[type]({
			message: message,
			description: description,
		});
	};

	useEffect(() => {
		if (isAdminUser) {
			getSellers();
		}
	}, []);

	const getSellers = async () => {
		const url = api + "/userlist/Seller";
		const token = localStorage.getItem("token");
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };

		try {
			const { data } = await axios.get(url);
			let list = [];
			data.map((item) => {
				list.push({
					value: item._id,
					label: item.name,
				});
			});
			setOptionsSellers(list);
			setLoadingSeller(false);
		} catch (err) {
			console.error(err.message);
			return null;
		}
	};

	const createOpportunity = async (data) => {
		const opportunity = {
			...data,
		};

		if (data.contactPhone) opportunity.contactPhone = "+1" + data.contactPhone;
		delete opportunity.prefix;

		if (
			localStorage.getItem("userId") &&
			localStorage.getItem("role") === "Seller" &&
			opportunity.seller !== undefined
		) {
			opportunity.seller = localStorage.getItem("userId");
		}

		try {
			let url = api + "/opportunity/create";

			const { data } = await axios.post(url, opportunity);
			if (data.success) {
				const payload = data.payload;
				openNotification("success", "Success", data.message);

				setTimeout(() => {
					if (payload.seller) {
						history.push("/opportunity/dashboard");
					} else {
						history.push("/");
					}
				}, 2000);
			} else {
				openNotification("error", "Error", data.message);
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			openNotification(
				"error",
				"Error",
				"Error while trying to create opportunity"
			);
			console.error(err.message);
			return null;
		}
	};

	const prefixSelector = (
		<Form.Item name="prefix" noStyle>
			<Select
				style={{
					width: 70,
				}}
			>
				<Option value="+1">+1</Option>
			</Select>
		</Form.Item>
	);

	const onFinish = () => {
		setLoading(true);
		const values = form.getFieldsValue(true);
		if (
			!values.address &&
			!values.contactEmail &&
			!values.contactName &&
			!values.contactPhone
		) {
			setLoading(false);
			openNotification(
				"warning",
				"Warning",
				"At least you must fill one value"
			);
		} else {
			createOpportunity(values);
		}
	};

	const handleSearchAddressCancel = () => {
		setOpenSearchAddressModal(false);
	};

	const handleSearchAddressOpen = () => {
		setOpenSearchAddressModal(true);
	};

	const handleCallback = (childData) => {
		form.setFieldsValue({
			address: childData.address,
			latitude: childData.destLat,
			longitude: childData.destLng,
		});
		handleSearchAddressCancel();
	};

	const RenderSearchAddressModal = () => {
		return (
			<Modal
				title="Address"
				open={openSearchAddressModal}
				onCancel={handleSearchAddressCancel}
				cancelButtonProps={{ style: { display: "none" } }}
				okButtonProps={{ style: { display: "none" } }}
				width={600}
			>
				<AddressGeolocation2
					search={true}
					parentCallback={handleCallback}
					single={false}
					currentLocation={true}
				/>
			</Modal>
		);
	};

	const filterOption = (input, option) =>
		(option?.label ?? "").toLowerCase().includes(input.toLowerCase());

	const CustomForm = () => {
		return (
			<Form
				form={form}
				layout="vertical"
				onFinish={onFinish}
				style={{ marginTop: "20px" }}
				initialValues={{
					prefix: "+1",
					latitude: undefined,
					longitude: undefined,
					address: undefined,
					contactEmail: undefined,
					contactPhone: undefined,
					contactName: undefined,
					locatorPerson: undefined,
					seller: undefined,
				}}
			>
				<Row gutter={16}>
					<Col span={24} lg={16} xl={18}>
						<Form.Item label="Address" name="address">
							<Input size="large" disabled />
						</Form.Item>
					</Col>
					<Col span={24} lg={8} xl={6} style={{ alignSelf: "center" }}>
						<Button
							size="large"
							type="primary"
							htmlType="button"
							icon={<SearchOutlined />}
							onClick={handleSearchAddressOpen}
							style={{
								width: "100%",
							}}
						>
							Search Address
						</Button>
					</Col>
				</Row>
				<Divider />
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item label="Contact Name" name="contactName">
							<Input size="large" />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Contact Email"
							name="contactEmail"
							rules={[
								{
									type: "email",
									message: "The input is not valid E-mail",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12}>
						<Form.Item
							label="Contact Phone"
							name="contactPhone"
							rules={[
								{
									len: 10,
									max: 10,
									message: "Phone number must have 10 numbers",
								},
							]}
						>
							<Input size="large" addonBefore={prefixSelector} />
						</Form.Item>
					</Col>
					<Col span={24} sm={12}>
						<Form.Item
							label="Locator Person"
							name="locatorPerson"
							rules={[
								{
									required: true,
									message: "Locator name is required",
								},
							]}
						>
							<Input size="large" />
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					{isAdminUser && (
						<Col span={24} sm={12}>
							<Form.Item label="Seller" name="seller">
								<Select
									style={{
										width: "100%",
									}}
									size="large"
									placeholder="Please select seller"
									options={optionsSellers}
									loading={loadingSeller}
									optionFilterProp="children"
									filterOption={filterOption}
									showSearch
								/>
							</Form.Item>
						</Col>
					)}
					<Col span={24} sm={12}>
						<Form.Item
							label="Type of opportunity"
							name="opportunityType"
							// rules={[
							// 	{
							// 		required: true,
							// 		message: "Type is required",
							// 	},
							// ]}
						>
							<Select
								style={{
									width: "100%",
								}}
								size="large"
								placeholder="Please select type"
								options={opportuniyTypeOptions}
								allowClear
							/>
						</Form.Item>
					</Col>
				</Row>
				<Row gutter={16}>
					<Col span={24} sm={12} md={4}>
						<Button
							type="primary"
							htmlType="submit"
							loading={loading}
							size="large"
							style={{
								width: "100%",
							}}
						>
							Create
						</Button>
					</Col>
				</Row>
			</Form>
		);
	};

	return (
		<Layout
			title="New Opportunity"
			description="Create new opportunity"
			type="medium"
		>
			{contextHolder}
			<CustomForm />
			<RenderSearchAddressModal />
		</Layout>
	);
};

export default NewOpportunity;
